import { useQuery } from "@tanstack/react-query";

const fetchIp = async () => {
  const responseIp = await fetch("https://api.ipify.org?format=json");
  const dataIp = await responseIp.json();
  return dataIp;
};

export const IP_KEY = "IP";

export const useGetIP = (): string | undefined => {
  const { data } = useQuery({ queryKey: [IP_KEY], queryFn: fetchIp });
  return data?.ip;
};
