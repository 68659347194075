import React from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { CustomReportsParameters } from "../../../../types/api/custom-reports";
import { CustomReportsCheckbox } from "./custom-reports-checkbox";
import { CustomReportsUsers } from "./custom-reports-users";
import { CustomReportsSingleSelection } from "./custom-reports-single-selection";
import { CustomReportsTextField } from "./custom-reports-textfield";
import { CustomReportsIntegerField } from "./custom-reports-integerfield";
import { CustomReportsDate } from "./custom-reports-date";
import {
  DatePredefinedEndDatePicker,
  DatePredefinedPeriodPicker,
  PeriodType,
} from "../../../../components/ui/date-predefined-period-picker";
import {
  EndDatePeriodType,
  getEndDatePeriodDate,
  getPredefinedDates,
} from "../../../../utils/format-date";

interface CustomReportsFiltersPanelProps {
  state: any;
  isFieldValid: any;
  handleOnChange: any;
  parameters: CustomReportsParameters[];
  onApplyFilters: () => void;
  addDateSelector: "P" | "E" | undefined;
}

export const CustomReportsFiltersPanel = ({
  state,
  isFieldValid,
  handleOnChange,
  parameters,
  onApplyFilters,
  addDateSelector,
}: CustomReportsFiltersPanelProps) => {
  const isMobile = useMediaQuery("(max-width: 580px)");

  const startDateLabel =
    addDateSelector === "P" ? parameters[0].Label : undefined;
  const endDateLabel =
    addDateSelector === "P"
      ? parameters[1].Label
      : addDateSelector === "E"
        ? parameters[0].Label
        : undefined;

  const handlePredefinedPeriodChange = (periodType: PeriodType) => {
    handleOnChange("predefinedPeriod", periodType);
    const predefinedDates = getPredefinedDates(periodType);
    if (predefinedDates) {
      handleOnChange(startDateLabel, predefinedDates[0]);
      handleOnChange(endDateLabel, predefinedDates[1]);
    }
  };

  const handlePredefinedEndDateChange = (endDatePeriod: EndDatePeriodType) => {
    handleOnChange("predefinedPeriod", endDatePeriod);
    const predefinedEndDate = getEndDatePeriodDate(endDatePeriod);
    if (predefinedEndDate) {
      handleOnChange(endDateLabel, predefinedEndDate);
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      marginBottom={1}
      padding={3}
      // border={isDarkMode ? `1px solid #272E3D` : "1px solid #ddd"}
      // boxShadow={"0 0 30px 0 rgba(82, 63, 105, 0.05)"}
      boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
      borderRadius={1}
    >
      <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "20px",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {addDateSelector === "E" && (
          <Box
            sx={{ width: "100%", maxWidth: "260px" }}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <DatePredefinedEndDatePicker
              value={state?.predefinedPeriod?.value}
              onChange={handlePredefinedEndDateChange}
              sx={{
                width: "100%",
                // height: "40px",
                "& .MuiInputBase-input": {
                  fontSize: "16px",
                  lineHeight: "22px",
                },
              }}
            />
            <Box>Date</Box>
          </Box>
        )}
        {addDateSelector === "P" && (
          <Box
            sx={{ width: "100%", maxWidth: "260px" }}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <DatePredefinedPeriodPicker
              value={state?.predefinedPeriod?.value}
              onChange={handlePredefinedPeriodChange}
              sx={{
                width: "100%",
                // height: "40px",
                "& .MuiInputBase-input": {
                  fontSize: "16px",
                  lineHeight: "22px",
                },
              }}
            />
            <Box>OR</Box>
          </Box>
        )}
        {parameters.map((parameter) => {
          if (parameter.ControlType === "checkbox") {
            return (
              <CustomReportsCheckbox
                value={state?.[parameter.Label]?.value}
                handleOnChange={handleOnChange}
                key={parameter.Label}
                parameter={parameter}
              />
            );
          } else if (parameter.ControlType === "users") {
            return (
              <CustomReportsUsers
                value={state?.[parameter.Label]?.value || []}
                handleOnChange={handleOnChange}
                key={parameter.Label}
                parameter={parameter}
              />
            );
          } else if (parameter.ControlType === "single-selection") {
            return (
              <CustomReportsSingleSelection
                value={state?.[parameter.Label]?.value || []}
                handleOnChange={handleOnChange}
                key={parameter.Label}
                parameter={parameter}
                isFieldValid={isFieldValid[parameter.Label]?.isValid}
                errorMessage={isFieldValid[parameter.Label]?.errorMessage}
              />
            );
          } else if (parameter.ControlType === "text") {
            return (
              <CustomReportsTextField
                value={state?.[parameter.Label]?.value}
                handleOnChange={handleOnChange}
                key={parameter.Label}
                parameter={parameter}
                isFieldValid={isFieldValid[parameter.Label]?.isValid}
                errorMessage={isFieldValid[parameter.Label]?.errorMessage}
              />
            );
          } else if (parameter.ControlType === "integer") {
            return (
              <CustomReportsIntegerField
                value={state?.[parameter.Label]?.value}
                handleOnChange={handleOnChange}
                key={parameter.Label}
                parameter={parameter}
                isFieldValid={isFieldValid[parameter.Label]?.isValid}
                errorMessage={isFieldValid[parameter.Label]?.errorMessage}
              />
            );
          } else if (parameter.ControlType === "date") {
            return (
              <CustomReportsDate
                value={state?.[parameter.Label]?.value}
                handleOnChange={handleOnChange}
                key={parameter.Label}
                parameter={parameter}
                isFieldValid={isFieldValid[parameter.Label]?.isValid}
                errorMessage={isFieldValid[parameter.Label]?.errorMessage}
              />
            );
          } else {
            return <>"{parameter.ControlType}" control not imlemented</>;
          }
        })}
        <Box
          sx={{
            width: isMobile ? "100%" : "unset",
            textAlign: "center",
          }}
        >
          <Button
            onClick={onApplyFilters}
            sx={{
              background: "none",
              textTransform: "none",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            // @ts-ignore
            variant="secondary"
            disableRipple
            // disabled={loading}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
