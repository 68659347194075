import { Box } from "@mui/material";
import React from "react";
import { DoubleArrow } from "./double-arrow";
import { SingleArrow } from "./arrow";

interface ArrowsProps {
  disabledAll?: boolean;
  disabledLeftArrow?: boolean;
  disabledRightArrow?: boolean;
  disabledDoubleLeftArrow?: boolean;
  disabledDoubleRightArrow?: boolean;
  onLeftArrowClick: () => void;
  onDoubleLeftArrowClick: () => void;
  onRightArrowClick: () => void;
  onDoubleRightArrowClick: () => void;
}

export const Arrows = ({
  disabledAll = false,
  disabledLeftArrow = false,
  disabledRightArrow = false,
  disabledDoubleLeftArrow = false,
  disabledDoubleRightArrow = false,
  onLeftArrowClick,
  onDoubleLeftArrowClick,
  onRightArrowClick,
  onDoubleRightArrowClick,
}: ArrowsProps) => {
  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <DoubleArrow
          disabled={disabledAll || disabledDoubleLeftArrow}
          onClick={onDoubleLeftArrowClick}
        />
        <SingleArrow
          disabled={disabledAll || disabledLeftArrow}
          onClick={onLeftArrowClick}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <SingleArrow
          disabled={disabledAll || disabledRightArrow}
          isRight
          onClick={onRightArrowClick}
        />
        <DoubleArrow
          disabled={disabledAll || disabledDoubleRightArrow}
          isRight
          onClick={onDoubleRightArrowClick}
        />
      </Box>
    </Box>
  );
};
