import envConfig from "../../../config";
import { getHeaders } from "../../../utils/api";
import useApiCall from "../../../hooks/ApiCall";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { EXAMPLE_GET_SITES_RESPONSE } from "../../../data/example-get-sites-response";
import { useParams } from "react-router-dom";

const sitesUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-selection-list?activeYN=Y&BaseHostURL=${envConfig.mainServiceUrl}`;

// TODO: Move it somewhere else - probably types
export type ResponseBoolean = "Y" | "N";

// TODO: Move it somewhere else - probably types
export interface Site {
  dpsID: number;
  dpsName: string;
  dpsCode: string;
  dpsActiveYN: ResponseBoolean | string;
}

export interface SitesResponse {
  ListOfSites: Site[];
  errorMessage: ResponseBoolean;
  successYN: ResponseBoolean;
}

export const useGetSites = () => {
  const { dbId } = useParams();

  const headers = getHeaders(dbId);
  const { handleOpen } = useLoginPopup();

  // TODO: IMPORTANT: Remove this line in final version
  if (process.env.NODE_ENV === "development") {
    return EXAMPLE_GET_SITES_RESPONSE;
  }

  // TODO: IMPORTANT: Remove this line in final version
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useApiCall({
    url: sitesUrl,
    method: "GET",
    headers: headers,
    handleOpen,
  }) as { data: SitesResponse | null; error: any; loading: boolean };
};
