import React from "react";
import { Box, Typography } from "@mui/material";

interface PageTitleProps {
  title: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return (
    <Box>
      <Typography variant="h3" component={"h3"}>
        {title}
      </Typography>
    </Box>
  );
};
