import * as React from "react";
import { IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContextMenu, {
  IContextMenuOptions,
} from "devextreme-react/cjs/context-menu";

interface ActionMenuProps extends IContextMenuOptions {}

export const ActionMenu = ({
  dataSource,
  onItemClick,
  ...restProps
}: ActionMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [target, setTarget] = React.useState<HTMLElement | undefined>(
    undefined,
  );

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(true);
    setTarget(event.currentTarget);
  };

  const handleOnClose = () => {
    setIsOpen(false);
    setTarget(undefined);
  };

  return (
    <>
      <IconButton sx={{ padding: 0 }} onClick={handleOnClick}>
        <MoreHorizIcon />
      </IconButton>
      <ContextMenu
        dataSource={dataSource}
        width={200}
        target={target}
        onItemClick={onItemClick}
        visible={isOpen}
        onHiding={handleOnClose}
        showEvent={undefined}
        {...restProps}
      />
    </>
  );
};
