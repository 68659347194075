import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import BankJournalHeader from "./BankJournalHeader";
import BankJournalGrid from "./BankJournalGrid";
import { useParams, useLocation, useNavigate } from "react-router-dom"; // useLocation to access URL params
import { getHeaders } from "../../utils/api";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import envConfig from "../../config";
import PageTitle from "../PageTitle/PageTitle";
import useApi from "../../hooks/useApi";
import { enqueueSnackbar } from "notistack";

const BankJournalPage = () => {
  const { dbId, lang, journalId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const headers = getHeaders(dbId);
  const { apiCall } = useApi();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageKey, setPageKey] = useState(1);

  // Form state with defaults for the URL parameters
  const [formState, setFormState] = useState({
    jnlDate: null,
    jnlContact_cntID: null,
    jnlMemo: "",
    showPrivatePortion: false,
    showVaryManualTax: false,
    jnlCode: "",
    jnlAccount_accNo: null,
    JournalAmountType: "G",
    lockedDate: false,
    lockedAccount: false,
    lockedAmount: false,
    finalSave: false,
  });

  const reloadPage = () => {
    setPageKey((prev) => prev + 1);
    setFormState((prevState) => ({
      ...prevState,
      jnlDate: null,
    }));
    if (!journalId) {
      navigate(location.pathname, { replace: true });
    } else {
      setData(null);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get("date");
    const accountParam = queryParams.get("account");
    const amountParam = queryParams.get("amount");
    // Parse the URL parameters only if no journalId is provided
    if (!journalId) {
      setFormState((prevState) => ({
        ...prevState,
        jnlDate: dateParam
          ? dayjs(dateParam)
          : prevState.jnlDate
            ? prevState.jnlDate
            : null,
        jnlAccount_accNo: Number(accountParam) || prevState.jnlAccount_accNo,
      }));

      if (amountParam) {
        const parsedAmount = parseFloat(amountParam);
        setFormState((prevState) => ({
          ...prevState,
          jnlCode: parsedAmount < 0 ? "CO" : "CI", // Spend if negative, Receive if positive
        }));
      }
    }
  }, [location.search, journalId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get("date");
    const accountParam = queryParams.get("account");
    const amountParam = queryParams.get("amount");
    const fetchData = async () => {
      setLoading(true);
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-journal/get-journal-data?journalId=${journalId || 0}&BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        ip: "127.0.0.2",
        headers,
        onSuccess: (data) => {
          const showVaryManualTax = data?.JournalDetails?.some(
            (detail) => detail.jnsVaryTaxYN === "Y",
          );
          const showPrivatePortion = data?.JournalDetails?.some(
            (detail) => detail.jnsPrivAmt != null,
          );

          setData(data);
          setFormState((prevState) => ({
            ...prevState, // Keep the existing state from URL params
            jnlDate: dateParam
              ? dayjs(dateParam)
              : prevState.jnlDate
                ? prevState.jnlDate
                : data?.JournalHeader?.jnlDate
                  ? dayjs(data?.JournalHeader?.jnlDate)
                  : null, // Only overwrite if not set from URL
            jnlAccount_accNo:
              Number(accountParam) || data?.JournalHeader?.jnlAccount_accNo,
            jnlCode: prevState.jnlCode || data?.JournalHeader?.jnlCode,
            jnlContact_cntID: data?.JournalHeader?.jnlContact_cntID || null,
            jnlMemo: data?.JournalHeader?.jnlMemo || "",
            showPrivatePortion: showPrivatePortion,
            showVaryManualTax: showVaryManualTax,
            JournalAmountType: data?.JournalHeader?.JournalAmountType || "G",
            // Do not lock the date or account if the journal is being edited
          }));

          setLoading(false);
        },
        onError: (errorMessage) => {
          console.log(errorMessage);
          enqueueSnackbar(errorMessage, { variant: "error" });
          setLoading(false);
        },
      });
    };

    fetchData();
  }, [journalId, pageKey]);

  const isReceiveMoney = formState.jnlCode === "CI";
  const isSpendMoney = formState.jnlCode === "CO";

  console.log("formState", formState);
  return (
    <div key={pageKey}>
      <BackdropLoading open={loading} />
      <PageTitle
        title={
          data?.JournalHeader?.jnlID
            ? `Bank Transaction ${formState?.jnlCode}-${data?.JournalHeader?.jnlID}`
            : "New Bank Transaction"
        }
      />

      <BankJournalHeader
        pageKey={pageKey}
        formState={formState}
        setFormState={setFormState}
        listOfContacts={data?.ListOfContacts}
        listOfAccounts={data?.ListOfAccounts?.filter(
          (acc) => acc.accPostableYN === "Y",
        )}
        isReceiveMoney={isReceiveMoney}
        isSpendMoney={isSpendMoney}
      />
      <BankJournalGrid
        isAmountNet={formState.JournalAmountType === "N"}
        isReceiveMoney={isReceiveMoney}
        isSpendMoney={isSpendMoney}
        formState={formState}
        headerData={data?.JournalHeader || {}}
        initialData={data?.JournalDetails || []}
        listOfAccounts={data?.ListOfAccounts}
        listOfTaxCodes={data?.ListOfTaxCodes}
        listOfSites={data?.ListOfSites}
        showPrivatePortion={formState?.showPrivatePortion}
        setShowPrivatePortion={(boolean) =>
          setFormState({ ...formState, showPrivatePortion: !!boolean })
        }
        showVaryManualTax={formState?.showVaryManualTax}
        reloadPage={reloadPage}
        loadingData={loading}
      />
    </div>
  );
};

export default BankJournalPage;
