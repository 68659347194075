export function getAlignment(align: number): "center" | "left" | "right" {
  switch (align) {
    case 1:
      return "left";
    case 2:
      return "center";
    default:
      return "right";
  }
}
