import { Button, Dialog, DialogContent, Typography } from "@mui/material";

const OurDictionary = ({ isOpen, setIsOpen, displayDescription }) => {
  return (
    <Dialog open={isOpen} sx={{ ".MuiPaper-root": { maxWidth: "unset" } }}>
      <DialogContent sx={{ p: 4, maxWidth: "1030px" }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "150%",
            letterSpacing: "-0.264px",
          }}
        >
          Our Dictionary
        </Typography>
        <Typography
          sx={{
            color: "#495670",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "150%",
            letterSpacing: "-0.176px",
            my: 5,
          }}
        >
          We know that using new system gets confusing sometimes. We prepared
          our Olivs dictionary to help you get on board more easily!
        </Typography>
        {displayDescription && (
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              mt: 0,
              mb: 0,
            }}
          >
            In Olivs, you can either create only a subscriber account (if you
            are, for example, accountant, manage different clients businesses
            and pay for the subscription) or subscriber with your business
            account which means you manage your own business and pay for your
            own subscription.
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            my: 0,
            mt: 5,
          }}
        >
          Subscriber Account
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            mt: 2,
            mb: 5,
          }}
        >
          Subscriber Account helps getting you and your team organised within
          Olivs. It allows you manage many businesses and your team's access to
          them. It is also linked to payment for subscription for all businesses
          it owns.
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            my: 0,
          }}
        >
          Business
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            mt: 2,
            mb: 5,
          }}
        >
          Business File contains all data and functionality, used to run
          accounting, payroll and other functions for one business entity.
        </Typography>
        <Button
          sx={{ m: 0, float: "right" }}
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(false)}
        >
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default OurDictionary;
