import { useTheme } from "@emotion/react";
import { createTheme } from "@mui/material/styles";

export const commonThemes = createTheme({
  normalText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  inputLabel: {
    fontFamily: "Inter",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.6)",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  pageTitle: {
    color: "#495570",
    fontFamily: "Inter",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  popupTitle: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "36px",
    letterSpacing: "-0.264px",
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#849F23",
    },
    labelColor: {
      main: "rgba(255, 255, 255, 0.7)",
    },
    blackWhite: {
      main: "#000",
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
      styleOverrides: {
        root: {
          "@media (min-width:1850px)": {
            maxWidth: "1664px !important",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "red",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            width: "8px",
            borderRadius: "8px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            minHeight: 24,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              // backgroundColor: "red",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "rgba(0, 0, 0, 0.25)",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "rgba(0, 0, 0, 0.35)",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "none",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ">div": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        color: "primary",
        sx: {
          ".MuiSvgIcon-root": {
            backgroundColor: "white",
            borderRadius: 0,
          },
        },
      },
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            ".MuiSvgIcon-root": {
              backgroundColor: "transparent",
              borderRadius: 0,
            },
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: "primary",
        disableRipple: true,
        sx: {
          ".MuiSvgIcon-root": {
            backgroundColor: "white",
            borderRadius: "50%",
          },
        },
      },
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            ".MuiSvgIcon-root": {
              backgroundColor: "transparent",
              borderRadius: 0,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        sx: { color: "#7E8595" },
      },
      styleOverrides: {
        asterisk: { color: "#D32F2F" },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "12px",
          backgroundColor: "white",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "12px",
          backgroundColor: "white",
        },
        paper: {
          fontSize: "12px",
          borderRadius: "8px",
        },
        input: {
          padding: "0 0 0 10px !important",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "12px",
          marginTop: "-3px",
        },
      },
      styleOverrides: {
        shrink: ({ ownerState, theme }) => ({
          ...(ownerState.shrink && {
            fontSize: "12px",
            marginTop: "0px !important",
          }),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        primary: {
          color: "#FFF",
          backgroundColor: "#849F23",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          padding: "8px 16px",
          borderRadius: "8px",
        },
        secondary: {
          color: "#849F23",
          backgroundColor: "rgba(132, 159, 35, 0.08)",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          padding: "8px 16px",
          borderRadius: "8px",
        },
      },
    },
  },

  navigation: {
    iconColor: "#818595",
    activeIconColor: "#849F23",
    menuDividerColor: "#DDD",
    boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
    borderRadius: "8px",
    menuBackgroundColor: "transparent",
    menuItemColor: "#849F23",
    hoverMenuItemColor: "#849F23",
    subpageTitleColor: "#252525",
    extraMenuItemColor: "#495670",
  },
  switch: {
    thumbBackgroundColor: "#fff",
    trackBackgroundColor: "#8796A5",
  },
  typography: {
    // Header 3 styles
    h3: {
      fontFamily: "Inter",
      fontSize: "28px",
      fontWeight: 300,
      lineHeight: "42px",
      letterSpacing: "-0.011em",
      textAlign: "left",
    },
    button: {
      textTransform: "none",
    },
    fontFamily: "Inter",
    fontSize: 14,
    fontSizeTitle: 20,
    letterSpacing: "0.15px",
    fontWeightThin: 400,
    fontWeightNormal: 500,
    fontWeightBold: 600,
    fontWeightBolder: 700,
    linkColor: "#495670",
  },
  blueLabel: {
    color: "#495670",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  gridHeader: {
    color: "#8895AF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  h1: {
    fontSize: 24,
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#AAD269",
    },
    labelColor: {
      main: "rgba(255, 255, 255, 0.7)",
    },
    blackWhite: {
      main: "#FFF",
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
      styleOverrides: {
        root: {
          "@media (min-width:1850px)": {
            maxWidth: "1664px !important",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "red",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            width: "8px",
            borderRadius: "8px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            minHeight: 24,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              // backgroundColor: "red",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "rgba(0, 0, 0, 0.25)",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "rgba(0, 0, 0, 0.35)",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "none",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        color: "primary",
      },
    },
    MuiFormLabel: {
      defaultProps: {
        color: "labelColor",
      },
      styleOverrides: {
        asterisk: { color: "#D32F2F" },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "12px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "12px",
        },
        paper: {
          fontSize: "12px",
          borderRadius: "8px",
        },
        input: {
          padding: "0 0 0 10px !important",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        ".MuiInputBase-root": {
          borderRadius: "8px",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "12px",
          marginTop: "-3px",
        },
      },
      styleOverrides: {
        shrink: ({ ownerState, theme }) => ({
          ...(ownerState.shrink && {
            fontSize: "12px",
            marginTop: "0px !important",
          }),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: "none",
        },
      },
      styleOverrides: {
        primary: {
          color: "#383838",
          backgroundColor: "#AAD269",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          padding: "8px 16px",
          borderRadius: "8px",
        },
        secondary: {
          color: "#AAD269",
          backgroundColor: "#383838",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          padding: "8px 16px",
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#ff0000", // Set the desired red color
          },
        },
      },
    },
  },
  navigation: {
    iconColor: "#818595",
    activeIconColor: "#AAD269",
    menuDividerColor: "#DDD",
    boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
    borderRadius: "8px",
    menuBackgroundColor: "transparent",
    menuItemColor: "#AAD269",
    hoverMenuItemColor: "#AAD269",
    subpageTitleColor: "#FFF",
    extraMenuItemColor: "#7D8CAA",
  },
  switch: {
    thumbBackgroundColor: "#fff",
    trackBackgroundColor: "#8796A5",
  },
  typography: {
    // Header 3 styles
    h3: {
      fontFamily: "Inter",
      fontSize: "28px",
      fontWeight: 300,
      lineHeight: "42px",
      letterSpacing: "-0.011em",
      textAlign: "left",
    },
    button: {
      textTransform: "none",
    },
    fontFamily: "Inter",
    fontSize: 14,
    fontSizeTitle: 20,
    letterSpacing: "0.15px",
    fontWeightThin: 400,
    fontWeightNormal: 500,
    fontWeightBold: 600,
    fontWeightBolder: 700,
    linkColor: "#7D8CAA",
  },
  blueLabel: {
    color: "#7D8CAA",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  gridHeader: {
    color: "#495670",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  h1: {
    fontSize: 24,
  },
});
