import { useState, useEffect, useCallback } from "react";
import { getCookie } from "../utils/api";
import Cookies from "js-cookie";
import { useRef } from "react";
import { useParams } from "react-router-dom";

const useApiCall = ({
  url,
  method = "GET",
  headers = {},
  body = null,
  navigate = () => {},
  prefix = null,
  skip = false,
  handleOpen = () => {},
  shouldRefetch = false,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const attemptsRef = useRef(1);
  const maxAttempts = 3;
  const { dbId } = useParams();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const config = {
        method,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          ...headers,
          "api-key":
            getCookie("apiKey") + "-" + (dbId || getCookie("databaseId")),
        },
      };

      if (body !== null) {
        config.body = JSON.stringify(body);
      }

      const response = await fetch(url, config);

      if (!response.ok) {
        console.log("response", response);
        handleOpen();
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result?.SuccessYN === "X" || result === "Wrong API format!") {
        handleOpen(true);
      } else {
        setData(result);
      }
    } catch (error) {
      handleOpen();
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url, method, headers, body]);

  useEffect(() => {
    if (skip) return;
    fetchData();
  }, []);

  useEffect(() => {
    if (shouldRefetch && !skip) {
      fetchData();
    }
  }, [shouldRefetch]);

  return { data, error, loading };
};

export default useApiCall;
