import { useContext, useRef, useState } from "react";
import {
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  useMediaQuery,
  Container,
  Box,
} from "@mui/material";
import { popoverClasses } from "@mui/material/Popover";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { ReactComponent as SwitchBusinessIcon } from "../../assets/switch-account-icon.svg";
import { ReactComponent as LogOutIcon } from "../../assets/log-out-icon.svg";
import { ReactComponent as SupportIcon } from "../../assets/support_icon.svg";
import Logo from "../../assets/exacc-logo.png";
import { ReactComponent as LogoDarkMode } from "../../assets/logo-words-darkmode.svg";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import MobileNavigation from "../MobileNavigation/MobileNavigation";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getCookie, getLoginSecured } from "../../utils/api";
import { useEffect } from "react";

import { ReactComponent as FavouritesIcon } from "../../assets/star.svg";
import { ReactComponent as PayrollIcon } from "../../assets/payroll-menu-icon.svg";
import { ReactComponent as BusinessIcon } from "../../assets/helm.svg";
import { ReactComponent as FinanceIcon } from "../../assets/finance.svg";
import { ReactComponent as ReportsIcon } from "../../assets/reports.svg";
import { ReactComponent as HexagonIcon } from "../../assets/hexagon.svg";
import { useLoginPopup } from "../../context/LoginPopupContext";
import {
  createSessionForExternal,
  createSessionToLogin,
  isMobileBrowser,
} from "../../utils/reusable";
import Banner from "../Banner/Banner";
import ReportDatesContext from "../../context/ReportDatesContext";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";
import { enqueueSnackbar } from "notistack";
import Cookies from "js-cookie";

const MenuSection = ({
  ip,
  icon = {},
  handleMenuClick = () => {},
  handleMenuClose = () => {},
  companyName = "",
  currentMenuItem = "",
  urlPrefix = "",
  anchorEl = () => {},
  setAnchorEl = () => {},
  //
  mainMenuTitle = "",
  sectionOne = [],
  sectionOneTitle = "",
  sectionOneExtra = [],
  //
  sectionTwo = [],
  sectionTwoTitle = "",
  sectionTwoExtra = [],
  //
  sectionThree = [],
  sectionThreeTitle = "",
  sectionThreeExtra = [],
  //
  isCompanyTile,
  sectionFour = [],
  sectionFourTitle = "",
  sectionFourExtra = [],
  manageToolbar = [],
}) => {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const menuRef = useRef();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const columnMinWidth = 175;

  const maxItems = Math.max(
    sectionOne?.length,
    sectionTwo?.length,
    sectionThree?.length,
    sectionFour?.length,
  );

  const fillSection = (section) => {
    if (!section?.length) return section;
    const filledSection = [...section];
    while (filledSection.length < maxItems) {
      filledSection.push({ Label: "", Hyperlink: null });
    }
    return filledSection;
  };

  const filledSectionOne = fillSection(sectionOne);
  const filledSectionTwo = fillSection(sectionTwo);
  const filledSectionThree = fillSection(sectionThree);
  const filledSectionFour = fillSection(sectionFour);

  const handleOutsideClick = (event) => {
    if (event === anchorEl || anchorEl?.contains(event.target)) {
      return;
    }
    if (
      Boolean(anchorEl) &&
      menuRef.current &&
      !menuRef.current?.contains(event.target)
    ) {
      handleMenuClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [anchorEl]);

  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuLink, setContextMenuLink] = useState("");

  const handleContextMenu = (event, link) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
      anchorEl: event.currentTarget,
    });
    if (link.startsWith("https://")) {
      setContextMenuLink(link);
    } else {
      setContextMenuLink(`https://${envConfig.mainServiceUrl}${link}`);
    }
  };

  const handleClose = () => {
    setContextMenu(null);
    setContextMenuLink("");
  };

  const handleOpenInNewTab = () => {
    createSessionForExternal(contextMenuLink, ip, true, apiCall);
    handleClose();
  };

  if (
    !sectionOne?.length > 0 &&
    !sectionTwo?.length > 0 &&
    !sectionThree?.length > 0 &&
    !sectionFour?.length > 0 &&
    !sectionOneExtra?.length > 0 &&
    !sectionTwoExtra?.length > 0 &&
    !sectionThreeExtra?.length > 0 &&
    !sectionFourExtra?.length > 0 &&
    !manageToolbar?.length
  )
    return null;

  return (
    <Box>
      <IconButton
        color="inherit"
        onClick={(event) => handleMenuClick(event, mainMenuTitle)}
        disableRipple
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: isCompanyTile ? "32px" : "8px",
          borderRadius: 0,
          position: "relative",
          "&:after": {
            content: '""',
            position: "absolute",
            top: "50%",
            left: "13px",
            transform: "translateY(-50%)",
            width: "1px",
            height: isCompanyTile && "39px",
            bgcolor: theme.navigation.menuDividerColor,
          },
          color:
            currentMenuItem === mainMenuTitle
              ? theme.navigation.activeIconColor
              : theme.navigation.iconColor,
          path: {
            fill:
              currentMenuItem === mainMenuTitle
                ? theme.navigation.activeIconColor
                : isDarkMode
                  ? "#FFF"
                  : theme.navigation.iconColor,
          },
          "&:hover": {
            backgroundColor: theme.navigation.menuBackgroundColor,
            color: theme.navigation.hoverMenuItemColor,
            path: {
              fill: theme.navigation.hoverMenuItemColor,
            },
          },
        }}
        onContextMenu={(e) => handleContextMenu(e, mainMenuTitle)}
      >
        {!isCompanyTile && icon}
        <Typography
          variant="body1"
          sx={{
            marginTop: 1,
            ...theme.typography,
            fontSize: isCompanyTile ? "11px" : "13px",
            fontWeight: 400,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100px",
            position: isCompanyTile ? "absolute" : "unset",
            top: "9.5px",
            zIndex: 1,
            textTransform: isCompanyTile ? "uppercase" : "none",
            color: isCompanyTile ? theme.palette.primary.main : "inherit",
          }}
        >
          {isCompanyTile ? companyName : mainMenuTitle}
        </Typography>
        {isCompanyTile && (
          <Typography
            variant="body1"
            sx={{
              ...theme.typography,
              fontSize: "13px",
              fontWeight: 400,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100px",
              mt: "32px",
            }}
          >
            Manage
          </Typography>
        )}
      </IconButton>

      <Menu
        hideBackdrop={true}
        ref={menuRef}
        disableScrollLock={true}
        anchorEl={anchorEl}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        open={Boolean(anchorEl) && currentMenuItem === mainMenuTitle}
        onClose={handleMenuClose}
        sx={{
          ">div": {
            zIndex: 2,
            boxShadow: theme.navigation.boxShadow,
            borderRadius: theme.navigation.borderRadius,
          },
          ">div.MuiPaper-elevation": {
            position: "fixed !important",
          },
          [`&.${popoverClasses.root}`]: {
            position: "unset",
          },
          ul: {
            bgcolor: isDarkMode ? "#272E3D" : "#FFF",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: sectionOne.length > 0 ? columnMinWidth : 0,
              }}
            >
              {filledSectionOne?.length > 0 && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                    paddingLeft: 2,
                  }}
                >
                  {sectionOneTitle}
                </Typography>
              )}
              {filledSectionOne?.map((page, pageIndex) => (
                <MenuItem
                  disableRipple
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    cursor: page.Hyperlink ? "pointer" : "default",
                    color: theme.navigation.subpageTitleColor,
                    fontFeatureSettings: "clig off, liga off",
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    letterSpacing: theme.typography.letterSpacing,
                    height: "33px",
                  }}
                  key={pageIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (page.Hyperlink) {
                      if (page.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          page.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(`${page.Hyperlink}`);
                      }
                    }
                  }}
                  onContextMenu={(e) => handleContextMenu(e, page.Hyperlink)}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      page.Hyperlink?.startsWith("http")
                        ? `${page.Hyperlink}`
                        : `${page.Hyperlink}`
                    }
                  >
                    {page?.Label}
                  </a>
                </MenuItem>
              ))}
              {sectionOneExtra?.map((extra, extraIndex) => (
                <MenuItem
                  disableRipple
                  key={extraIndex}
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    fontWeight: "500",
                    letterSpacing: theme.typography.letterSpacing,
                    color: theme.navigation.extraMenuItemColor,
                    paddingTop: extraIndex === 0 ? "24px" : "6px",
                    marginLeft: "16px",
                    marginRight: "16px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderTop:
                      extraIndex === 0
                        ? isDarkMode
                          ? "rgba(255, 255, 255, 0.32) 1px solid"
                          : "rgba(0, 0, 0, 0.12) 1px solid"
                        : "none",
                    marginTop:
                      extra.type === "add_new_pay_event"
                        ? "53px"
                        : extraIndex === 0
                          ? "20px"
                          : "8px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (extra.Hyperlink) {
                      if (extra.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          extra.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(`${extra.Hyperlink}`);
                      }
                    }
                  }}
                  onContextMenu={(e) => handleContextMenu(e, extra.Hyperlink)}
                >
                  {extra?.Icon === "plus" && (
                    <AddIcon
                      width={10}
                      height={10}
                      style={{
                        marginLeft: "5px",
                        marginRight: "12px",
                      }}
                    />
                  )}
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      extra.Hyperlink?.startsWith("http")
                        ? `${extra.Hyperlink}`
                        : `${extra.Hyperlink}`
                    }
                  >
                    {extra?.Label}
                  </a>
                </MenuItem>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: sectionTwo.length > 0 ? columnMinWidth : 0,
              }}
            >
              {filledSectionTwo?.length > 0 && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                    paddingLeft: 2,
                  }}
                >
                  {sectionTwoTitle}
                </Typography>
              )}
              {filledSectionTwo?.map((page, pageIndex) => (
                <MenuItem
                  disableRipple
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    cursor: page.Hyperlink ? "pointer" : "default",
                    color: theme.navigation.subpageTitleColor,
                    fontFeatureSettings: "clig off, liga off",
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    letterSpacing: theme.typography.letterSpacing,
                    height: "33px",
                  }}
                  key={pageIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (page.Hyperlink) {
                      if (page.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          page.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(`${page.Hyperlink}`);
                      }
                    }
                  }}
                  onContextMenu={(e) => handleContextMenu(e, page.Hyperlink)}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      page.Hyperlink?.startsWith("http")
                        ? `${page.Hyperlink}`
                        : `${page.Hyperlink}`
                    }
                  >
                    {page?.Label}
                  </a>
                </MenuItem>
              ))}
              {sectionTwoExtra.map((extra, extraIndex) => (
                <MenuItem
                  disableRipple
                  key={extraIndex}
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    fontWeight: "500",
                    letterSpacing: theme.typography.letterSpacing,
                    color: theme.navigation.extraMenuItemColor,
                    paddingTop: extraIndex === 0 ? "24px" : "6px",
                    marginLeft: "16px",
                    marginRight: "16px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderTop:
                      extraIndex === 0
                        ? isDarkMode
                          ? "rgba(255, 255, 255, 0.32) 1px solid"
                          : "rgba(0, 0, 0, 0.12) 1px solid"
                        : "none",
                    marginTop:
                      extra.type === "add_new_pay_event"
                        ? "53px"
                        : extraIndex === 0
                          ? "20px"
                          : "8px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (extra.Hyperlink) {
                      if (extra.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          extra.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(`${extra.Hyperlink}`);
                      }
                    }
                  }}
                  onContextMenu={(e) => handleContextMenu(e, extra.Hyperlink)}
                >
                  {extra?.Icon === "plus" && (
                    <AddIcon
                      width={10}
                      height={10}
                      style={{
                        marginLeft: "5px",
                        marginRight: "12px",
                      }}
                    />
                  )}
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      extra.Hyperlink?.startsWith("http")
                        ? `${extra.Hyperlink}`
                        : `${extra.Hyperlink}`
                    }
                  >
                    {extra?.Label}
                  </a>
                </MenuItem>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: sectionThree.length > 0 ? columnMinWidth : 0,
              }}
            >
              {filledSectionThree?.length > 0 && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                    paddingLeft: 2,
                  }}
                >
                  {sectionThreeTitle}
                </Typography>
              )}
              {filledSectionThree?.map((page, pageIndex) => (
                <MenuItem
                  disableRipple
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    cursor: page.Hyperlink ? "pointer" : "default",
                    color: theme.navigation.subpageTitleColor,
                    fontFeatureSettings: "clig off, liga off",
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    letterSpacing: theme.typography.letterSpacing,
                    height: "33px",
                  }}
                  key={pageIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (page.Hyperlink) {
                      if (page.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          page.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(`${page.Hyperlink}`);
                      }
                    }
                  }}
                  onContextMenu={(e) => handleContextMenu(e, page.Hyperlink)}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      page.Hyperlink?.startsWith("http")
                        ? `${page.Hyperlink}`
                        : `${page.Hyperlink}`
                    }
                  >
                    {page?.Label}
                  </a>
                </MenuItem>
              ))}
              {sectionThreeExtra.map((extra, extraIndex) => (
                <MenuItem
                  disableRipple
                  key={extraIndex}
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    fontWeight: "500",
                    letterSpacing: theme.typography.letterSpacing,
                    color: theme.navigation.extraMenuItemColor,
                    paddingTop: extraIndex === 0 ? "24px" : "6px",
                    marginLeft: "16px",
                    marginRight: "16px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderTop:
                      extraIndex === 0
                        ? isDarkMode
                          ? "rgba(255, 255, 255, 0.32) 1px solid"
                          : "rgba(0, 0, 0, 0.12) 1px solid"
                        : "none",
                    marginTop:
                      extra.type === "add_new_pay_event"
                        ? "53px"
                        : extraIndex === 0
                          ? "20px"
                          : "8px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (extra.Hyperlink) {
                      if (extra.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          extra.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(`${extra.Hyperlink}`);
                      }
                    }
                  }}
                  onContextMenu={(e) => handleContextMenu(e, extra.Hyperlink)}
                >
                  {extra?.Icon === "plus" && (
                    <AddIcon
                      width={10}
                      height={10}
                      style={{
                        marginLeft: "5px",
                        marginRight: "12px",
                      }}
                    />
                  )}
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      extra.Hyperlink?.startsWith("http")
                        ? `${extra.Hyperlink}`
                        : `${extra.Hyperlink}`
                    }
                  >
                    {extra?.Label}
                  </a>
                </MenuItem>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: sectionFour.length > 0 ? columnMinWidth : 0,
              }}
            >
              {filledSectionFour.length > 0 && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                    paddingLeft: 2,
                  }}
                >
                  {sectionFourTitle}
                </Typography>
              )}
              {filledSectionFour?.map((page, pageIndex) => (
                <MenuItem
                  disableRipple
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    cursor: page.Hyperlink ? "pointer" : "default",
                    color: theme.navigation.subpageTitleColor,
                    fontFeatureSettings: "clig off, liga off",
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    letterSpacing: theme.typography.letterSpacing,
                    height: "33px",
                  }}
                  key={pageIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (page.Hyperlink) {
                      if (page.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          page.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(page.Hyperlink);
                      }
                    }
                  }}
                  onContextMenu={(e) => handleContextMenu(e, page.Hyperlink)}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      page.Hyperlink?.startsWith("http")
                        ? `${page.Hyperlink}`
                        : `${page.Hyperlink}`
                    }
                  >
                    {page?.Label}
                  </a>
                </MenuItem>
              ))}
              {sectionFourExtra.map((extra, extraIndex) => (
                <MenuItem
                  disableRipple
                  key={extraIndex}
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    fontWeight: "500",
                    letterSpacing: theme.typography.letterSpacing,
                    color: theme.navigation.extraMenuItemColor,
                    paddingTop: extraIndex === 0 ? "24px" : "6px",
                    marginLeft: "16px",
                    marginRight: "16px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderTop:
                      extraIndex === 0
                        ? isDarkMode
                          ? "rgba(255, 255, 255, 0.32) 1px solid"
                          : "rgba(0, 0, 0, 0.12) 1px solid"
                        : "none",
                    marginTop:
                      extra.type === "add_new_pay_event"
                        ? "53px"
                        : extraIndex === 0
                          ? "20px"
                          : "8px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (extra.type === "switch_business") {
                      createSessionToLogin(
                        `https://${envConfig.loginUrl}/user-dashboard`,
                        "127.0.0.2",
                      );
                      return;
                    }
                    if (extra.Hyperlink) {
                      if (extra.type === "switch_business") {
                        createSessionToLogin(
                          `https://${envConfig.loginUrl}/user-dashboard`,
                          "127.0.0.2",
                        );
                        return;
                      }

                      if (extra.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          extra.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(extra.Hyperlink);
                      }
                    }
                    if (extra.type === "dark_mode") {
                      handleMenuClose();
                    }
                  }}
                  onContextMenu={(e) => handleContextMenu(e, extra.Hyperlink)}
                >
                  {extra.type === "dark_mode" && <ThemeToggle />}
                  {extra.type === "switch_business" && (
                    <SwitchBusinessIcon
                      width={20}
                      height={20}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {extra.type === "log_out" && (
                    <LogOutIcon
                      width={20}
                      height={20}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {extra?.Icon === "plus" && (
                    <AddIcon
                      width={10}
                      height={10}
                      style={{
                        marginLeft: "5px",
                        marginRight: "12px",
                      }}
                    />
                  )}
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    to={
                      extra.type === "switch_business"
                        ? `https://${envConfig.loginUrl}/user-dashboard`
                        : extra.Hyperlink?.startsWith("http")
                          ? ""
                          : `${extra.Hyperlink}`
                    }
                  >
                    {extra?.Label}
                  </Link>
                  {extra.arrow_right && (
                    <ArrowRight
                      style={{
                        marginLeft: "15px",
                      }}
                    />
                  )}
                </MenuItem>
              ))}
            </Box>
          </div>
          {manageToolbar?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 2,
                pt: 2,
                borderTop: isDarkMode
                  ? "rgba(255, 255, 255, 0.32) 1px solid"
                  : "rgba(0, 0, 0, 0.12) 1px solid",
                justifyContent: "flex-end",
              }}
            >
              {manageToolbar.map((extra, extraIndex) => (
                <MenuItem
                  disableRipple
                  key={extraIndex}
                  sx={{
                    "&:hover": {
                      bgcolor: "unset",
                      color: theme.navigation.hoverMenuItemColor,
                    },
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    fontWeight: "500",
                    letterSpacing: theme.typography.letterSpacing,
                    color: theme.navigation.extraMenuItemColor,
                    marginLeft: "16px",
                    marginRight: "16px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginTop: 0,
                    pt: 1,
                    li: {
                      mt: 0,
                      justifyContent: "center",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (extra.Hyperlink) {
                      if (extra.type === "switch_business") {
                        createSessionToLogin(
                          `https://${envConfig.loginUrl}/user-dashboard`,
                          "127.0.0.2",
                        );
                        return;
                      }
                      if (extra.Hyperlink?.startsWith("https://help")) {
                        window.open(extra.Hyperlink);
                      }
                      if (extra.Hyperlink?.startsWith("http")) {
                        createSessionForExternal(
                          extra.Hyperlink,
                          ip,
                          false,
                          apiCall,
                        );
                      } else {
                        navigate(extra.Hyperlink);
                      }
                    }
                    if (extra.type === "dark_mode") {
                      handleMenuClose();
                    }
                  }}
                >
                  {extra.type === "support" && (
                    <SupportIcon
                      style={{
                        fill: isDarkMode ? "#7D8CAA" : "#495670",
                      }}
                      width={20}
                      height={20}
                    />
                  )}
                  {extra.type === "dark_mode" && (
                    <Box
                      sx={{
                        li: { mr: 0 },
                        label: { mr: 0 },
                      }}
                    >
                      <ThemeToggle title="" />
                    </Box>
                  )}
                  {extra.type === "switch_business" && (
                    <SwitchBusinessIcon
                      width={20}
                      height={20}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {extra.type === "log_out" && (
                    <LogOutIcon
                      width={20}
                      height={20}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {extra.Icon === "plus" && (
                    <AddIcon
                      width={10}
                      height={10}
                      style={{
                        marginLeft: "5px",
                        marginRight: "12px",
                      }}
                    />
                  )}
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    to={
                      extra.type === "switch_business"
                        ? `https://${envConfig.loginUrl}/user-dashboard`
                        : `${extra.Hyperlink}`
                    }
                  >
                    {extra?.Label}
                  </Link>
                  {extra.arrow_right && (
                    <ArrowRight
                      style={{
                        marginLeft: "15px",
                      }}
                    />
                  )}
                </MenuItem>
              ))}
            </Box>
          )}
        </Box>
      </Menu>

      <Menu
        open={Boolean(contextMenu?.anchorEl)}
        anchorEl={contextMenu?.anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        sx={{ zIndex: 9999999999 }}
      >
        <MenuItem onClick={handleOpenInNewTab}>Open in new tab</MenuItem>
      </Menu>
    </Box>
  );
};

const MainNavigation = ({ pageCode }) => {
  const { apiCall } = useApi();
  const databaseIdFromCookies = getCookie("databaseId");
  const { dbId, lang } = useParams();
  const urlPrefix = `/${dbId || databaseIdFromCookies}/${lang || "en-au"}/`;
  const { setPageMainData } = useContext(ReportDatesContext);

  const navigate = useNavigate();
  const { handleOpen } = useLoginPopup();
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery("(max-width: 805px)");
  const ip = useGetIP();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);
  const [newMenuItems, setNewMenuItems] = useState({});
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    setAnchorEl(null);
    setCurrentMenuItem(null);
  }, [location]);

  const handleMenuClick = (event, menuItem) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenuItem(menuItem);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentMenuItem(null);
  };

  useEffect(() => {
    const apiKeyLoaded = getCookie("apiKey");
    const fetchData = async () => {
      await apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/front-end/get-page-main-data?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        ip: ip,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": ip,
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "session-id": getCookie("sessionId"),
          "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": apiKeyLoaded + "-" + (dbId || getCookie("databaseId")),
        },
        body: {
          Tool0004: "string",
          PageCode: pageCode,
          LinkS: "",
          LinkA: `https://${envConfig.correspondingService}`,
          Lang: "en-au",
          BrowserType: isMobileBrowser() ? "M" : "D",
          LinkMy: `https://${envConfig.loginUrl}`,
        },
        onSuccess: (responseData) => {
          setPageTitle(responseData.PageTitle);
          setPageMainData({
            dates: {
              reportsEndDate: responseData?.ReportsEndDate,
              reportsStartDate: responseData?.ReportsStartDate,
            },
            interfacePreference: responseData?.InterfacePreference,
          });
          setNewMenuItems(responseData);
          localStorage.setItem("businessCode", responseData?.BusinessCode);
        },
        onError: (error) => {
          console.log("Error: ", error);
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    };
    fetchData();
  }, [pageCode]);

  useEffect(() => {
    if (dbId && !isNaN(Number(dbId))) {
      Cookies.set("databaseId", dbId);
    }
  }, []);

  useEffect(() => {
    document.title = pageTitle ? pageTitle : "Exacc";
  }, [pageTitle]);

  return (
    <>
      <Container
        key={newMenuItems?.BusinessCode}
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #EEE",
          marginBottom: "32px",
          padding: 0,
        }}
      >
        <img
          onClick={() => {
            if (newMenuItems?.HomeUrl.startsWith("http")) {
              window.location = newMenuItems?.HomeUrl;
            } else {
              navigate(newMenuItems?.HomeUrl);
            }
          }}
          height={42}
          style={{ cursor: "pointer", marginTop: 20, marginBottom: 20 }}
          src={Logo}
          alt="Logo"
        />
        <Toolbar sx={{ display: "flex", maxWidth: "724px", pr: 0 }}>
          {isMobile ? (
            <MobileNavigation
              ip={ip}
              newMenuItems={newMenuItems}
              urlPrefix={urlPrefix}
            />
          ) : (
            // menuItems.map(
            //   (menuItem, index) =>
            //     (companyName !== "My Profile" || menuItem.type === "company") && (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <MenuSection
                ip={ip}
                icon={<FavouritesIcon width={24} height={24} />}
                handleMenuClick={handleMenuClick}
                handleMenuClose={handleMenuClose}
                companyName={newMenuItems?.BusinessCode}
                currentMenuItem={currentMenuItem}
                urlPrefix={urlPrefix}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                //
                mainMenuTitle="Favorites"
                sectionOneTitle="Favorites"
                sectionOne={newMenuItems?.Me_QuickLinks}
              />
              <MenuSection
                ip={ip}
                icon={<PayrollIcon width={24} height={24} />}
                handleMenuClick={handleMenuClick}
                handleMenuClose={handleMenuClose}
                companyName={newMenuItems?.BusinessCode}
                currentMenuItem={currentMenuItem}
                urlPrefix={urlPrefix}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                //
                mainMenuTitle="Payroll"
                sectionOne={newMenuItems?.Payroll_Payroll_Links}
                sectionOneTitle="Payroll"
                sectionOneExtra={newMenuItems?.Payroll_Payroll_Extras}
                sectionTwo={newMenuItems?.Payroll_Rosters_Links}
                sectionTwoTitle="Rosters"
                sectionTwoExtra={newMenuItems?.Payroll_Rosters_Extras}
                sectionThree={newMenuItems?.Payroll_Timesheet_Links}
                sectionThreeTitle="Timesheet"
                sectionThreeExtra={newMenuItems?.Payroll_Timesheet_Extras}
              />
              <MenuSection
                ip={ip}
                icon={<BusinessIcon width={23} height={24} />}
                handleMenuClick={handleMenuClick}
                handleMenuClose={handleMenuClose}
                companyName={newMenuItems?.BusinessCode}
                currentMenuItem={currentMenuItem}
                urlPrefix={urlPrefix}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                //
                mainMenuTitle="Business"
                sectionOne={newMenuItems?.Contacts_Links}
                sectionOneTitle="Contacts"
                sectionOneExtra={newMenuItems?.Contacts_Extras}
                sectionTwo={newMenuItems?.Invoices_Purchases_Links}
                sectionTwoTitle="Purchases"
                sectionTwoExtra={newMenuItems?.Invoices_Purchases_Extras}
                sectionThree={newMenuItems?.Invoices_Sales_Links}
                sectionThreeTitle="Sales"
                sectionThreeExtra={newMenuItems?.Invoices_Sales_Extras}
              />

              <MenuSection
                ip={ip}
                icon={
                  <FinanceIcon
                    style={{ marginBottom: "-3px" }}
                    width={30}
                    height={30}
                  />
                }
                handleMenuClick={handleMenuClick}
                handleMenuClose={handleMenuClose}
                companyName={newMenuItems?.BusinessCode}
                currentMenuItem={currentMenuItem}
                urlPrefix={urlPrefix}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                //
                mainMenuTitle="Finance"
                sectionOneTitle="Accounting"
                sectionOne={newMenuItems?.Accounting_Links}
                sectionOneExtra={newMenuItems?.Accounting_Extras}
                sectionTwoTitle="Banking"
                sectionTwo={newMenuItems?.Accounting_Banking_Links}
                sectionTwoExtra={newMenuItems?.Accounting_Banking_Extras}
              />
              <MenuSection
                ip={ip}
                icon={<ReportsIcon width={28} height={28} />}
                handleMenuClick={handleMenuClick}
                handleMenuClose={handleMenuClose}
                companyName={newMenuItems?.BusinessCode}
                currentMenuItem={currentMenuItem}
                urlPrefix={urlPrefix}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                //
                mainMenuTitle="Reports"
                sectionOne={newMenuItems?.Reports}
              />
              <MenuSection
                ip={ip}
                icon={
                  <HexagonIcon
                    style={{ marginBottom: "-1px" }}
                    width={27}
                    height={27}
                  />
                }
                handleMenuClick={handleMenuClick}
                handleMenuClose={handleMenuClose}
                companyName={newMenuItems?.BusinessCode || "Exacc"}
                currentMenuItem={currentMenuItem}
                urlPrefix={urlPrefix}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                isCompanyTile={true}
                sectionOne={
                  newMenuItems?.Me_Configuration?.length
                    ? newMenuItems?.Me_Configuration
                    : newMenuItems.Subscribers
                }
                sectionOneTitle={
                  newMenuItems?.Me_Configuration?.length
                    ? "Configuration"
                    : "Subscribers"
                }
                sectionOneExtra={
                  newMenuItems?.Me_Configuration?.length
                    ? newMenuItems?.Me_Configuration_Extras
                    : newMenuItems?.Subscribers_Extra
                }
                sectionTwo={newMenuItems?.Me_MyProfile}
                sectionTwoTitle="My Profile"
                sectionFour={
                  newMenuItems?.Me_Users?.length
                    ? newMenuItems?.Me_Users
                    : newMenuItems?.GlobalProfile
                }
                sectionFourTitle={
                  newMenuItems?.Me_Users?.length ? "Users" : "My Profile"
                }
                sectionFourExtra={newMenuItems?.Users_Extras}
                manageToolbar={[
                  {
                    type: "support",
                    Hyperlink: newMenuItems?.HelpUrl,
                  },
                  {
                    type: "dark_mode",
                  },
                  {
                    name: "Switch",
                    type: "switch_business",
                    Hyperlink: `https://${envConfig.loginUrl}/user-dashboard`,
                    Label: "Switch",
                  },
                  {
                    name: "Log Out",
                    type: "log_out",
                    Hyperlink: `https://${envConfig.loginUrl}/logout?apiKey=${getCookie(
                      "apiKey",
                    )}-${dbId || getCookie("databaseId")}&sessionId=${getCookie(
                      "sessionId",
                    )}`,
                    Label: "Log out",
                  },
                ]}
              />
            </div>
            //       ),
            //   )
          )}
        </Toolbar>
      </Container>
      {newMenuItems?.BannerHtml?.severity && (
        <Banner
          ip={ip}
          severity={newMenuItems?.BannerHtml?.severity}
          title={newMenuItems?.BannerHtml?.title}
          description={newMenuItems?.BannerHtml?.description}
          backgroundColor={newMenuItems?.BannerHtml?.backgroundColor}
          linkText={newMenuItems?.BannerHtml?.linkText}
          linkUrl={newMenuItems?.BannerHtml?.linkUrl}
        />
      )}
    </>
  );
};

export default MainNavigation;
