import { Box, Button } from "@mui/material";
import React from "react";
import { DownloadButton } from "../../../../components/ui/buttons/download-button";
import { FullScreenButton } from "../../../../components/ui/buttons/fullscreen-button";
import { PDFButton } from "../../../../components/ui/buttons/pdf-button";
import { RefreshButton } from "../../../../components/ui/buttons/refresh-button";
import { SearchInput } from "../../../../components/ui/inputs/search-input";

interface TaxCodesTableToolbarProps {
  // isCollapsed: boolean;
  // onCollapse: () => void;
  onRefresh: () => void;
  onExcel: () => void;
  // onPDF: () => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClear?: () => void;
  onFullScreen: () => void;
}

export const TaxCodesTableToolbar = ({
  // isCollapsed,
  // onCollapse,
  onRefresh,
  onExcel,
  // onPDF,
  onSearchChange,
  onSearchClear,
  onFullScreen,
}: TaxCodesTableToolbarProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      gap={3}
      sx={{ marginBottom: 3, marginTop: 1 }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={3}
        flexWrap={"wrap"}
      >
        <RefreshButton title={"Refresh"} onClick={onRefresh} />
        <Box fontWeight={500}>Download:</Box>
        {/* TODO: keeping for the reference - probably need to create ExcelButton - depends on the Icon */}
        <DownloadButton title={"To Excel"} onClick={onExcel} />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={3}
        flexWrap={"wrap"}
      >
        <SearchInput onChange={onSearchChange} onClear={onSearchClear} />
        <FullScreenButton onClick={onFullScreen} />
      </Box>
    </Box>
  );
};
