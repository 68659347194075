import { Box, Typography } from "@mui/material";
import { ReactComponent as ShowLess } from "../../assets/expand_less.svg";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { useTheme } from "../../context/ThemeContext";

const ToggleSection = ({ title, expanded, onClick, children }) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <div
      style={{
        paddingBottom: "20px",
        borderBottom: "1px solid #EEE",
      }}
    >
      <div>
        <div
          onClick={onClick}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              marginRight: "8px",
              fontWeight: theme.typography.fontWeightNormal,
              fontSize: theme.typography.fontSizeTitle,
            }}
            style={{ marginRight: "8px" }}
          >
            {title}
          </Typography>

          <Box
            sx={{
              transform: !expanded ? "rotateX(180deg)" : "rotateX(0deg)",
              transition: "transform 0.5s ease",
            }}
          >
            <ShowLess
              style={{ marginTop: "5px" }}
              width={24}
              height={24}
              fill={theme.palette.blackWhite.main}
            />
          </Box>
        </div>
      </div>
      <Box
        sx={{
          maxHeight: expanded ? "500px" : "0px",
          overflow: "hidden",
          transition: "max-height 0.3s ease, padding-top 0.3s ease",
          paddingTop: expanded ? 2 : 0,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default ToggleSection;
