import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import envConfig from "../../../../config";
import { getHeaders } from "../../../../utils/api";
import { EXAMPLE_ACCOUNTS_INFORMATION_RESPONSE } from "./example-responses";
import { useParams } from "react-router-dom";

export interface Account {
  accNo: number;
  accName: string;
  LockDate: string;
  LastTransDate: string;
  UnfinImport: number;
}

interface AccountsInformationResponse {
  SuccessYN: string;
  ErrorMessage: string;
  ListOfAccounts: Account[];
}

export const GET_ACCOUNTS_INFORMATION_KEY = "GET_ACCOUNTS_INFORMATION_KEY";

export const getAccountsInformation =
  async (dbId): Promise<AccountsInformationResponse> => {
    const headers = getHeaders(dbId);
    const api = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-entries-import/get-accounts-information?BaseHostURL=${envConfig.mainServiceUrl}`;

    if (process.env.NODE_ENV === "development") {
      return EXAMPLE_ACCOUNTS_INFORMATION_RESPONSE;
    }

    const response = await fetch(api, {
      method: "GET",
      // @ts-ignore
      headers: {
        ...headers,
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to get Accounts Information: ${response.statusText}`,
      );
    }

    const result = await response.json();

    if (result?.SuccessYN === "Y" || result?.successYN === "Y") {
      return result;
    } else {
      throw new Error(
        `Failed to return Accounts Information: ${JSON.stringify(result)}`,
      );
    }
  };

type UseGetAccountsInformation = {
  queryOptions?: Omit<
    UseQueryOptions<
      AccountsInformationResponse,
      Error,
      AccountsInformationResponse
    >,
    "queryKey" | "queryFn"
  >;
};

export const useAccountsInformation = ({
  queryOptions = {},
}: UseGetAccountsInformation) => {
  const { dbId } = useParams();

  return useQuery({
    ...queryOptions,
    queryKey: [GET_ACCOUNTS_INFORMATION_KEY],
    queryFn: () => getAccountsInformation(dbId),
  });
};
