import React from "react";

import { ReactComponent as DoubleLeftArrow } from "../../assets/double-arrow-left-keyboard.svg";
import { ReactComponent as DoubleRightArrow } from "../../assets/double-arrow-right-keyboard.svg";

import { Button } from "@mui/material";

interface DoubleArrowProps {
  disabled?: boolean;
  isRight?: boolean;
  onClick: () => void;
}

export const DoubleArrow: React.FC<DoubleArrowProps> = ({
  disabled = false,
  isRight = false,
  onClick,
}) => {
  return (
    <Button
      variant="text"
      disabled={disabled}
      onClick={onClick}
      sx={{ minWidth: "24px", padding: "2px" }}
    >
      {isRight ? <DoubleRightArrow /> : <DoubleLeftArrow />}
    </Button>
  );
};
