import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useReportsParameters } from "../../features/reports/custom-reports/api/get-reports-parameters";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import PageTitle from "../../components/PageTitle/PageTitle";
import { CustomReportsFiltersPanel } from "../../features/reports/custom-reports/components/custom-reports-filter-panel";
import { boolToYN, YNToBool } from "../../utils/format-bool";
import { CustomReportsTable } from "../../features/reports/custom-reports/components/custom-reports-table";
import { useGetCustomReportDataGrid } from "../../features/reports/custom-reports/api/get-reports-data-grid";
import { enqueueSnackbar } from "notistack";
import {
  convertSimpleDateToDayjs,
  formatDateToISO,
} from "../../utils/format-date";
import { useLoginPopup } from "../../context/LoginPopupContext";

const getDefaultValue = (parameter) => {
  if (parameter.ControlType === "checkbox") {
    return YNToBool(parameter.DefaultValue);
  }

  if (parameter.ControlType === "users") {
    return [];
  }

  if (parameter.ControlType === "single-selection") {
    return { Value: undefined, Label: "" };
  }

  if (parameter.ControlType === "date") {
    return parameter.DefaultValue !== "" && parameter.DefaultValue !== "Y"
      ? convertSimpleDateToDayjs(parameter.DefaultValue)
      : null;
  }

  return parameter.DefaultValue;
};

export function CustomReports() {
  const params = useParams();
  const reportName = params.reportName;
  const { handleOpen } = useLoginPopup();

  const [state, setState] = React.useState({
    predefinedPeriod: {
      value: "custom",
    },
  });
  const [isFieldValid, setIsFieldValid] = React.useState({});

  const {
    data,
    isLoading: isLoadingReportParameters,
    isSuccess,
    isError,
    error,
  } = useReportsParameters(reportName);

  const customReportDataGridMutation = useGetCustomReportDataGrid({
    mutationConfig: {
      onError: (error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
      },
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const parameters = data?.Parameters;

      setState(
        parameters.reduce(
          (acc, parameter) => {
            return {
              ...acc,
              [parameter.Label]: {
                value: getDefaultValue(parameter),
                ControlType: parameter.ControlType,
                RequiredYN: YNToBool(parameter.RequiredYN),
                MaxValue: parameter.MaxValue,
                MinValue: parameter.MinValue,
              },
            };
          },
          {
            predefinedPeriod: {
              value: "custom",
            },
          },
        ),
      );
      setIsFieldValid(
        parameters.reduce((acc, parameter) => {
          return {
            ...acc,
            [parameter.Label]: {
              isValid: true,
              errorMessage: "",
            },
          };
        }, {}),
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  }, [isError, error]);

  const handleOnChange = useCallback((label, value) => {
    setIsFieldValid((prev) => {
      return {
        ...prev,
        [label]: {
          isValid: true,
          errorMessage: "",
        },
      };
    });

    setState((prev) => {
      return {
        ...prev,
        [label]: {
          ...prev[label],
          value: value,
        },
      };
    });
  }, []);

  const getParametersForRequest = () => {
    return data?.Parameters.map((parameter) => {
      if (parameter.ControlType === "checkbox") {
        return `${boolToYN(state[parameter.Label].value)}`;
      } else if (parameter.ControlType === "users") {
        // return state[parameter.Label].value.map((user) => user.Value);
        return state[parameter.Label].value.map((user) => user.Value).join(",");
      } else if (parameter.ControlType === "single-selection") {
        return `${state[parameter.Label].value.Value}`;
      } else if (parameter.ControlType === "multi-selection") {
        return state[parameter.Label].value
          .map((option) => option.Value)
          .join(",");
      } else if (
        state[parameter.Label].value === "" ||
        !state[parameter.Label].value
      ) {
        return parameter.DefaultValue;
      } else if (parameter.ControlType === "date") {
        return formatDateToISO(state[parameter.Label].value?.toDate());
      } else {
        return `${state[parameter.Label].value}`;
      }
    });
  };

  const onApplyFilters = () => {
    const newValidations = data?.Parameters.reduce((acc, parameter) => {
      if (
        parameter.RequiredYN === "Y" &&
        (parameter.ControlType === "single-selection" ||
          parameter.ControlType === "multi-selection") &&
        !state[parameter.Label].value?.Value
      ) {
        return {
          ...acc,
          [parameter.Label]: {
            isValid: false,
            errorMessage: "This field is required",
          },
        };
      }

      if (parameter.RequiredYN === "Y" && !state[parameter.Label].value) {
        return {
          ...acc,
          [parameter.Label]: {
            isValid: false,
            errorMessage: "This field is required",
          },
        };
      }

      if (
        parameter.ControlType === "integer" &&
        state[parameter.Label].value &&
        isNaN(state[parameter.Label].value)
      ) {
        return {
          ...acc,
          [parameter.Label]: {
            isValid: false,
            errorMessage: "This field should be a number",
          },
        };
      }

      if (
        parameter.ControlType === "date" &&
        state[parameter.Label].value &&
        !state[parameter.Label].value.isValid()
      ) {
        return {
          ...acc,
          [parameter.Label]: {
            isValid: false,
            errorMessage: "This field should be a valid date",
          },
        };
      }

      return {
        ...acc,
        [parameter.Label]: {
          isValid: true,
          errorMessage: "",
        },
      };
    }, {});

    if (
      // @ts-ignore
      Object.values(newValidations).some((validation) => !validation.isValid)
    ) {
      setIsFieldValid(newValidations);
      enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
      return;
    }

    const requestBody = {
      ReportID: reportName,
      Parameters: getParametersForRequest(),
    };

    customReportDataGridMutation.mutate(requestBody, handleOpen);
  };

  const isLoading = customReportDataGridMutation.isPending;
  const addDateSelector = data?.AddDateSelector;

  if (isLoadingReportParameters) {
    return <BackdropLoading open={isLoadingReportParameters} />;
  }

  return (
    <>
      <BackdropLoading open={isLoading} />
      <PageTitle
        title={data?.ReportTitle || "Custom Report"}
        displayBackIcon={null}
        onBack={null}
      />
      <CustomReportsFiltersPanel
        state={state}
        isFieldValid={isFieldValid}
        handleOnChange={handleOnChange}
        parameters={data?.Parameters || []}
        onApplyFilters={onApplyFilters}
        addDateSelector={addDateSelector}
      />
      <CustomReportsTable
        title={data?.ReportTitle || "Custom Report"}
        data={customReportDataGridMutation?.data?.DataDT}
        columns={customReportDataGridMutation?.data?.ColumnsList}
        actionsList={customReportDataGridMutation?.data?.ActionsList}
        tooltipsList={customReportDataGridMutation?.data?.TooltipsList}
        onRefresh={onApplyFilters}
      />
    </>
  );
}
