import React, { useState, useCallback } from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import envConfig from "../../config";
import Popup from "devextreme-react/popup";
import { getCookie } from "../../utils/api";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";

const FileUploadPopup = ({ journalId, setOpenUploadNewFile }) => {
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileBase64, setFileBase64] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [file, setFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { apiCall } = useApi();
  const ip = useGetIP();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      if (!selectedFile) {
        enqueueSnackbar("No file selected!", { variant: "error" });
        return;
      }

      setFile(selectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        const mimeType = selectedFile.type;
        const dataUrl = `data:${mimeType};base64,${base64Data}`;
        setFileBase64(dataUrl);
        setOriginalName(selectedFile.name);
      };
      reader.onerror = () => {
        enqueueSnackbar("Failed to read file!", { variant: "error" });
      };
      reader.readAsDataURL(selectedFile);
    },
    [enqueueSnackbar],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const saveFile = async () => {
    setLoading(true);
    const payload = {
      Base64String: `${fileBase64}`,
      FileName: originalName,
      Description: description,
      AssignTo: { JournalId: journalId },
    };

    await apiCall({
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/files/upload?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      ip: ip,
      body: payload,
      onSuccess: (result) => {
        enqueueSnackbar("File saved successfully", {
          variant: "success",
        });
        setLoading(false);
        setOpenUploadNewFile(false);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
        setLoading(false);
      },
    });
  };

  return (
    <Popup
      visible={true}
      title="File Upload"
      showTitle={true}
      closeOnOutsideClick={true}
      height={400}
      onHiding={() => setOpenUploadNewFile(false)}
      wrapperAttr={{ class: "upload-file-popup-zindex" }}
    >
      <BackdropLoading open={loading} />
      <form id="frmFileDirectory" method="post">
        <Grid style={{ flexDirection: "column" }} container spacing={2}>
          <Grid sx={{ mt: 3 }}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <div
              {...getRootProps()}
              style={{
                border: "2px dashed #ccc",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the file here...</p>
              ) : (
                <p>Drag and drop a file here, or click to select a file</p>
              )}
              {file && (
                <Typography variant="body2" style={{ marginTop: "10px" }}>
                  Selected file: {file.name}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={saveFile}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Popup>
  );
};

export default FileUploadPopup;
