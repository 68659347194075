import { useContext } from "react";
import ReportDatesContext from "../context/ReportDatesContext";

export const useInterfacePreference = () => {
  // TOOD: rename context to different name
  const { interfacePreference } = useContext(ReportDatesContext);

  console.log(
    "DEBUGGING useInterfacePreference: ",
    interfacePreference,
    useContext(ReportDatesContext),
  );

  return interfacePreference || "";
};
