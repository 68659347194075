import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  TextField,
} from "@mui/material";

import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";
import { useGetSites } from "../../../accounting/api/get-sites";
import { useLedgedStore } from "../store";

export const GeneralLedgerSites = () => {
  const { isDarkMode } = useTheme();
  const { data, loading } = useGetSites();

  const selectedSites = useLedgedStore.use.selectedSites();
  const setSelectedSites = useLedgedStore.use.setSelectedSites();

  const sites = data?.ListOfSites || [];

  const values = sites.filter((site) => selectedSites.includes(site.dpsCode));

  return (
    <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
      <Autocomplete
        loading={loading}
        multiple
        id="sites-autocomplete"
        options={data?.ListOfSites || []}
        getOptionLabel={(option) => `${option.dpsCode}`}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props}>
            <Box>
              <Box fontWeight={"bold"}>{option.dpsCode}</Box>
              <div>{option.dpsName}</div>
            </Box>
          </ListItem>
        )}
        value={values}
        onChange={(_, newValue) =>
          setSelectedSites(newValue.map((v) => v.dpsCode))
        }
        isOptionEqualToValue={(option, value) => option.dpsCode === value.dpsCode}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sites"
            sx={{
              label: {
                fontSize: 12,
                // @ts-ignore
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
              minWidth: "240px",
              maxWidth: "240px",
            }}
          />
        )}
      />
    </FormControl>
  );
};
