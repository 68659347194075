import { Box, Typography } from "@mui/material";
import { commonThemes } from "../../utils/themes/themes";
import { ReactComponent as ArrowBack } from "../../assets/arrow_back.svg";
import { useTheme } from "../../context/ThemeContext";

const PageTitle = ({ title = "", onBack, displayBackIcon }) => {
  const { isDarkMode } = useTheme();
  return (
    <Box sx={{ display: displayBackIcon ? "flex" : "block" }}>
      {displayBackIcon && (
        <Box sx={{ cursor: "pointer", mr: "10px" }} onClick={onBack}>
          <ArrowBack />
        </Box>
      )}
      <Typography
        sx={{
          ...commonThemes.pageTitle,
          color: isDarkMode ? "#FFF" : "rgb(73, 85, 112)",
          marginBottom: "40px",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PageTitle;
