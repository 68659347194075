import envConfig from "../config";
import { getCookie } from "./api";
import { createSessionForExternal } from "./reusable";

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const onClickUrl =
  (url: string): (() => void) =>
  () =>
    openInNewTab(url);

export const openInNewTabCustom = (partialUrl: string): void => {
  const databaseId = getCookie("databaseId") as string;
  const lang = "en-au";
  const url = `${window.location.origin}/${databaseId}/${lang}/${partialUrl}`;

  openInNewTab(url);
};

const isLegacySystem = (partialUrl: string): boolean => {
  return partialUrl.includes("[A]");
};

export const openInCurrentTabFromPartialUrl = (partialUrl: string, apiCall: any): void => {
  if (isLegacySystem(partialUrl)) {
    const updatedUrl = partialUrl
      .replace("[A]", envConfig.correspondingService)
      .replace("[DbID]", getCookie("databaseId") as string)
      .replace("[Lang]", "en-au");

    const url = `https://${updatedUrl}`;
    createSessionForExternal(url, "127.0.0.2", false, apiCall);

    return;
  } else {
    const updatedUrl = partialUrl
      .replace("[S]", window.location.origin)
      .replace("[DbID]", getCookie("databaseId") as string)
      .replace("[Lang]", "en-au");

    window.location.href = updatedUrl;
    return;
  }
};

export const openInNewTabFromPartialUrl = (partialUrl: string, apiCall): void => {
  if (isLegacySystem(partialUrl)) {
    const updatedUrl = partialUrl
      .replace("[A]", envConfig.correspondingService)
      .replace("[DbID]", getCookie("databaseId") as string)
      .replace("[Lang]", "en-au");

      const url = `https://${updatedUrl}`;
      createSessionForExternal(url, "127.0.0.2", true, apiCall);
    
    return;
  } else {
    const updatedUrl = partialUrl
      .replace("[S]", window.location.origin)
      .replace("[DbID]", getCookie("databaseId") as string)
      .replace("[Lang]", "en-au");

    openInNewTab(updatedUrl);
    return;
  }
};
