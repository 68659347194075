import { Box, Button } from "@mui/material";
import React from "react";
import { DownloadButton } from "../../../../components/ui/buttons/download-button";
import { FullScreenButton } from "../../../../components/ui/buttons/fullscreen-button";
import { RefreshButton } from "../../../../components/ui/buttons/refresh-button";
import { SearchInput } from "../../../../components/ui/inputs/search-input";

interface CustomReportsDataGridToolbarProps {
  isCollapsed: boolean;
  onCollapse: () => void;
  onRefresh: () => void;
  onExcel: () => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClear?: () => void;
  onFullScreen: () => void;
}

export const CustomReportsDataGridToolbar = ({
  isCollapsed,
  onCollapse,
  onRefresh,
  onExcel,
  onSearchChange,
  onSearchClear,
  onFullScreen,
}: CustomReportsDataGridToolbarProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      gap={3}
      sx={{ marginBottom: 3, marginTop: 1 }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={3}
        flexWrap={"wrap"}
      >
        <Button
          // @ts-ignore
          variant="secondary"
          sx={{ fontWeight: 700 }}
          onClick={onCollapse}
        >
          {!isCollapsed ? "Expand All" : "Collapse All"}
        </Button>
        <RefreshButton title={"Refresh"} onClick={onRefresh} />
        <Box fontWeight={500}>Download:</Box>
        {/* TODO: keeping for the reference - probably need to create ExcelButton - depends on the Icon */}
        <DownloadButton title={"To Excel"} onClick={onExcel} />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={3}
        flexWrap={"wrap"}
      >
        <SearchInput onChange={onSearchChange} onClear={onSearchClear} />
        <FullScreenButton onClick={onFullScreen} />
      </Box>
    </Box>
  );
};
