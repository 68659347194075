import React, { useRef, useState } from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import { useTheme } from "../../../../context/ThemeContext";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupItem,
  SearchPanel,
  Sorting,
  Summary,
  TotalItem,
} from "devextreme-react/cjs/data-grid";
import { YNToBool } from "../../../../utils/format-bool";
import { getAlignment } from "../../../../helpers/table";
import { formatFinancialNumber } from "../../../../utils/format-number";
import { formatDate, isValidDateString } from "../../../../utils/format-date";
import { ActionMenu } from "../../../../components/ui/table/action-menu";
import { ItemClickEvent } from "devextreme/ui/context_menu";
import { useInterfacePreference } from "../../../../hooks/use-interface-preference";
import {
  openInCurrentTabFromPartialUrl,
  openInNewTabFromPartialUrl,
} from "../../../../utils/open-in-new-tab";
import {
  findColumnDataType,
  getColumnDataType,
  getColumnNameTooltip,
  getSummaryType,
  isColumnDateType,
  isColumnMoneyType,
} from "../../../../utils/tables";
import { ITooltip } from "../../../../types/utils";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import { useDownloadPDFCustomReport } from "../api/get-custom-pdf-report";
import { enqueueSnackbar } from "notistack";
import useApi from "../../../../hooks/useApi";
import { DataGridWrapper } from "../../../../components/ui/table/data-grid-wrapper";
import { CustomReportsDataGridToolbar } from "./custom-reports-data-grid-toolbar";
import { useLoginPopup } from "../../../../context/LoginPopupContext";

const formatCellValue = (
  cellInfo: DataGridTypes.ColumnCellTemplateData,
  currentColumn: any,
) => {
  if (isColumnMoneyType(currentColumn.RpcDataType_rpdID)) {
    return formatFinancialNumber(cellInfo.value, {
      hideZero: currentColumn.HideZeroOnAmountColumnYN === "Y",
      rounding: currentColumn.RpcRounding,
      defaultValue: "-",
    });
  }

  if (
    (cellInfo.column.dataField === "TrDate" ||
      cellInfo.column.dataField === "Date" ||
      isColumnDateType(currentColumn.RpcDataType_rpdID)) &&
    isValidDateString(cellInfo.value)
  ) {
    return formatDate(cellInfo.value);
  }

  return cellInfo.value;
};

const renderGeneralTableCell = (
  cellInfo: DataGridTypes.ColumnCellTemplateData,
  currentColumn: any,
  tooltips: ITooltip[],
) => {
  const tooltipedColumn = getColumnNameTooltip(
    tooltips,
    currentColumn.RpcHeader,
  );

  if (tooltipedColumn) {
    return (
      <Tooltip title={cellInfo.data?.[tooltipedColumn]} placement="top">
        {formatCellValue(cellInfo, currentColumn)}
      </Tooltip>
    );
  }

  return formatCellValue(cellInfo, currentColumn);
};

interface CustomReportsTableProps {
  title: string;
  data: any;
  columns: any[];
  actionsList: any[];
  tooltipsList: ITooltip[];
  onRefresh: () => void;
}

// The only reason for missing type is that I don't know what type
// of data you are passing to this component - it is dynamic based
// on the data from the backend and can be easily changed
const CustomReportsTableBefore = ({
  title,
  data,
  columns = [],
  actionsList = [],
  tooltipsList = [],
  onRefresh,
}: CustomReportsTableProps) => {
  const dataGridRef = useRef<any>();
  const { apiCall } = useApi();
  const { handleOpen } = useLoginPopup();
  const { isDarkMode } = useTheme();
  const interfacePreference = useInterfacePreference();
  const { mutate: downloadAsPDF, isPending } = useDownloadPDFCustomReport({
    mutationConfig: {
      onError: (error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
      },
      onSuccess: () => {
        enqueueSnackbar("PDF report downloaded successfully!", {
          variant: "success",
        });
      },
    },
  });

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [collapseAll, setCollapseAll] = useState(true);

  // For different component I think you can provide a type
  // instead of any - provide a list of possible action items
  const onActionMenuItemClick = async (e: ItemClickEvent<any>, data: any) => {
    // @ts-ignore - they have fuckup types
    const action = e.itemData?.ActionType;
    // @ts-ignore - they have fuckup types
    const column = e.itemData?.ColumnWithLink;

    switch (action) {
      case "OPEN":
        openInCurrentTabFromPartialUrl(data[column], apiCall);
        break;
      case "OPEN_NEW_TAB":
        openInNewTabFromPartialUrl(data[column], apiCall);
        break;
      case "PDF":
        await downloadAsPDF(data[column], handleOpen);
        break;
      default:
        console.warn("Unknow Action");
        break;
    }
  };

  const handleOnExporting = async (columns) => {
    try {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");

      await exportDataGrid({
        component: dataGridRef?.current?.instance,
        worksheet,
        customizeCell: (options) => {
          const columnType = findColumnDataType(
            columns,
            options.gridCell?.column?.dataField,
          );
          const cellType = columnType
            ? getColumnDataType(columnType)
            : undefined;

          if (cellType === "DateShort" && options.gridCell?.value) {
            options.excelCell.value = options.gridCell?.value.split("T")[0];
          }
        },
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${title}.xlsx`,
      );
    } catch (e) {
      console.error("Error exporting Custom Report to Excel: ", e);
    }
  };

  const tooltips = tooltipsList || [];
  const actions = actionsList.map((action) => {
    return {
      text: action?.DisplayText,
      actionType: action?.ActionType,
      ...action,
    };
  });

  const displayActions = actions.length > 0;

  if (!data) {
    return (
      <Stack
        py={2}
        alignItems="center"
        justifyContent="center"
        boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
        marginTop={3}
        height={"calc(100vh - 300px)"}
        paddingTop={2}
        sx={{
          transition: "all 1s ease",
        }}
      >
        <span>Apply filters to view data</span>
      </Stack>
    );
  }

  return (
    <DataGridWrapper isFullScreen={isFullScreen} height="calc(100vh - 320px)">
      <CustomReportsDataGridToolbar
        isCollapsed={collapseAll}
        onCollapse={() => setCollapseAll(!collapseAll)}
        onRefresh={onRefresh}
        onExcel={() => handleOnExporting(columns)}
        onSearchChange={(e) => {
          dataGridRef.current.instance.searchByText(e.target.value);
        }}
        onSearchClear={() => {}}
        onFullScreen={() => setIsFullScreen(!isFullScreen)}
      />
      <Box
        sx={{
          ".MuiBox-root > .MuiDataGrid-root": {
            marginTop: 0,
          },
          ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
            backgroundColor: isDarkMode ? "#232939" : "#FAFAFA",
          },
        }}
      >
        <DataGrid
          ref={dataGridRef}
          dataSource={data}
          showBorders={false}
          rowAlternationEnabled={true}
          style={{
            border: "none",
            borderRadius: "8px",
            marginTop: "10px",
            // maxHeight: "calc(100vh - 320px)",
          }}
          scrolling={{
            mode: "infinite",
          }}
          hoverStateEnabled={true}
          wordWrapEnabled={true}
          width={"100%"}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          onRowClick={(e) => {
            if (e.rowType === "group") {
              e.isExpanded
                ? e.component?.collapseRow(e.key)
                : e.component?.expandRow(e.key);
            }
          }}
        >
          <SearchPanel visible={false} highlightCaseSensitive={true} />
          <Grouping autoExpandAll={collapseAll} allowCollapsing />
          <Sorting mode="multiple" />

          {displayActions && interfacePreference === "left" && (
            <Column
              width={50}
              caption=""
              alignment="left"
              cellRender={({ data }) => (
                <ActionMenu
                  dataSource={actions}
                  onItemClick={(e) => onActionMenuItemClick(e, data)}
                />
              )}
            />
          )}

          {columns?.length &&
            columns.map((column, index) => (
              <Column
                key={index}
                dataField={column.RpcDataSource}
                caption={column.RpcHeader}
                allowResizing={YNToBool(column.RpcAllowResizing)}
                allowFiltering={YNToBool(column.RpcFilter)}
                alignment={getAlignment(column.RpcAlign)}
                allowSearch={YNToBool(column.RpcSearchable)}
                allowSorting={YNToBool(column.RpcSort)}
                groupIndex={
                  column.RpcGroupBy === "Y" || column.RpcGroupBy === "y"
                    ? index
                    : undefined
                }
                cellRender={(cellInfo) =>
                  renderGeneralTableCell(cellInfo, column, tooltips)
                }
                visible={YNToBool(column.RpcVisible)}
                fixed={YNToBool(column.RpcFreeze)}
                minWidth={column.RpcMinWidth}
                width={column.RpcMaxWidth}
                allowGrouping={YNToBool(column.RpcGroupBy)}
              />
            ))}

          {displayActions && interfacePreference !== "left" && (
            <Column width={undefined} />
          )}

          {displayActions && interfacePreference !== "left" && (
            <Column
              width={50}
              caption=""
              alignment="right"
              cellRender={({ data }) => {
                return (
                  <ActionMenu
                    dataSource={actions}
                    onItemClick={(e) => onActionMenuItemClick(e, data)}
                  />
                );
              }}
            />
          )}

          {!displayActions && <Column caption="" width={undefined} />}
          {displayActions && interfacePreference === "left" && (
            <Column caption="" width={undefined} />
          )}

          <Summary>
            {columns?.length &&
              columns.map((column, index) => {
                if (
                  column?.RpcAvgOrSumOrCount &&
                  isColumnMoneyType(column?.RpcDataType_rpdID)
                ) {
                  return (
                    <GroupItem
                      key={`group-item-${column.RpcHeader}`}
                      column={column.RpcDataSource}
                      summaryType={getSummaryType(column?.RpcAvgOrSumOrCount)}
                      showInGroupFooter={true}
                      // @ts-ignore
                      customizeText={(e) => {
                        const summaryType = getSummaryType(
                          column?.RpcAvgOrSumOrCount,
                        );
                        const isAmount = isColumnMoneyType(
                          column?.RpcDataType_rpdID,
                        );

                        if (summaryType === "sum" && isAmount) {
                          // @ts-ignore
                          return formatFinancialNumber(e.value, {
                            hideZero: column.HideZeroOnAmountColumnYN === "Y",
                            rounding: column.RpcRounding,
                            defaultValue: "0",
                          });
                        }

                        if (summaryType === "avg" && isAmount) {
                          return (
                            "Avg: " +
                            // @ts-ignore
                            formatFinancialNumber(e.value, {
                              hideZero: column.HideZeroOnAmountColumnYN === "Y",
                              rounding: column.RpcRounding,
                              defaultValue: "0",
                            })
                          );
                        }

                        return e.valueText;
                      }}
                    />
                  );
                }
              })}
            {columns?.length &&
              columns.map((column, index) => {
                if (column?.RpcAvgOrSumOrCount) {
                  return (
                    <TotalItem
                      key={`total-item-${column.RpcHeader}`}
                      column={column.RpcDataSource}
                      summaryType={getSummaryType(column?.RpcAvgOrSumOrCount)}
                      // @ts-ignore
                      customizeText={(e) => {
                        const summaryType = getSummaryType(
                          column?.RpcAvgOrSumOrCount,
                        );
                        const isAmount = isColumnMoneyType(
                          column?.RpcDataType_rpdID,
                        );

                        if (summaryType === "sum" && isAmount) {
                          // @ts-ignore
                          return formatFinancialNumber(e.value, {
                            hideZero: column.HideZeroOnAmountColumnYN === "Y",
                            rounding: column.RpcRounding,
                            defaultValue: "0",
                          });
                        }

                        if (summaryType === "avg" && isAmount) {
                          return (
                            "Avg: " +
                            // @ts-ignore
                            formatFinancialNumber(e.value, {
                              hideZero: column.HideZeroOnAmountColumnYN === "Y",
                              rounding: column.RpcRounding,
                              defaultValue: "0",
                            })
                          );
                        }

                        return e.valueText;
                      }}
                    />
                  );
                }
              })}
          </Summary>
        </DataGrid>
      </Box>
    </DataGridWrapper>
  );
};

// export const CustomReportsTable = React.memo(CustomReportsTableBefore);
export const CustomReportsTable = CustomReportsTableBefore;
