import React from "react";
import { CustomReportsParameters } from "../../../../types/api/custom-reports";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { OlivsDataPicker } from "../../../../components/ui/date-picker";
import { Box, FormHelperText } from "@mui/material";

export const CustomReportsDate = ({
  parameter,
  value = "",
  handleOnChange,
  isFieldValid = true,
  errorMessage = "",
}: {
  parameter: CustomReportsParameters;
  value: string;
  handleOnChange: any;
  isFieldValid: boolean;
  errorMessage: string;
}) => {
  return (
    <Box sx={{ width: "100%", maxWidth: "240px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Box>
          <OlivsDataPicker
            label={parameter.Label}
            // maxDate={parameter.MaxValue ? parameter.MaxValue : undefined}
            // minDate={parameter.MinValue ? parameter.MinValue : undefined}
            value={value}
            onChange={(date) => handleOnChange(parameter.Label, date)}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: !isFieldValid ? "#d32f2f" : undefined,
              },
            }}
          />
          {errorMessage && (
            <FormHelperText error sx={{ marginLeft: 2 }}>
              {errorMessage}
            </FormHelperText>
          )}
        </Box>
      </LocalizationProvider>
    </Box>
  );
};
