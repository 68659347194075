import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import Popup from "devextreme-react/popup";
import DataGrid, { Column } from "devextreme-react/data-grid";
import ContextMenu from "devextreme-react/context-menu";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FileUploadPopup from "./FileUploadPopup";
import "./GeneralJournal.css";
import envConfig from "../../config";
import { enqueueSnackbar } from "notistack";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";
import { useTheme } from "../../context/ThemeContext";

const FilesUploadedPopup = ({ journalId, setOpenFilesPopup }) => {
  const [data, setData] = useState([]);
  const [openUploadNewFile, setOpenUploadNewFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const { apiCall } = useApi();
  const ip = useGetIP();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (openUploadNewFile) return;
    fetchData();
  }, [openUploadNewFile]);

  const fetchData = async () => {
    setLoading(true);
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/files/get-all?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        ip: ip,
        body: { JournalId: Number(journalId) },
        onSuccess: (result) => {
          setData(result?.ListOfFiles);
          setLoading(false);
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
          setLoading(false);
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleDownload = async (file) => {
    if (!file.filUniqueIdentifer) return;
    try {
      await apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/files/download?UniqueIdentifier=${file.filUniqueIdentifer}&BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        ip: ip,
        onSuccess: (result) => {
          const link = document.createElement("a");
          link.href = `data:application/octet-stream;base64,${result["application/json"].FileBase64}`;
          link.download = result["application/json"].FileName;
          link.click();
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDelete = async (file) => {
    if (!file.filUniqueIdentifer) return;

    await apiCall({
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/files/delete?UniqueIdentifier=${file.filUniqueIdentifer}&BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      ip: ip,
      onSuccess: () => {
        setData((prevData) =>
          prevData.filter(
            (item) => item.filUniqueIdentifer !== file.filUniqueIdentifer,
          ),
        );
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  const menuItems = [
    { text: "Download", onClick: handleDownload },
    { text: "Delete", onClick: handleDelete },
  ];

  const ActionMenu = ({ data }) => {
    const [target, setTarget] = useState(null);

    const handleMenuClick = (event) => {
      setTarget(event.currentTarget);
    };

    const handleMenuClose = () => {
      setTarget(null);
    };

    return (
      <>
        <IconButton sx={{ padding: 0 }} onClick={handleMenuClick}>
          <MoreHorizIcon />
        </IconButton>
        <ContextMenu
          dataSource={menuItems}
          target={target}
          onItemClick={(e) => {
            e.itemData.onClick(data.data);
            handleMenuClose();
          }}
          visible={!!target}
          onHiding={handleMenuClose}
        />
      </>
    );
  };

  return (
    <Box
      sx={{
        ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
          backgroundColor: isDarkMode ? "#232939" : "#FAFAFA",
        },
      }}
    >
      <BackdropLoading open={loading} />
      {openUploadNewFile && (
        <FileUploadPopup
          journalId={journalId}
          setOpenUploadNewFile={setOpenUploadNewFile}
        />
      )}
      <Popup
        visible={true}
        title="Files Uploaded"
        showTitle={true}
        closeOnOutsideClick={true}
        height={400}
        onHiding={() => setOpenFilesPopup(false)}
        wrapperAttr={{ class: "upload-file-popup-zindex" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenUploadNewFile(true)}
            style={{ marginRight: "auto" }}
          >
            + New File
          </Button>
        </div>
        {data?.length > 0 ? (
          <DataGrid
            rowAlternationEnabled={true}
            dataSource={data || []}
            showBorders={true}
          >
            <Column dataField="filDescription" caption="Description" />
            <Column dataField="filOriginalName" caption="File Name" />
            <Column
              dataField="filCreatedDateTime"
              caption="Created On"
              dataType="date"
            />
            <Column dataField="filCreatedBy_usrCode" caption="Created By" />
            <Column
              caption="Actions"
              cellRender={(cellData) => <ActionMenu data={cellData} />}
            />
          </DataGrid>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            No files found
          </div>
        )}
      </Popup>
    </Box>
  );
};

export default FilesUploadedPopup;
