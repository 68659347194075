import { Backdrop, CircularProgress } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";

const BackdropLoading = ({ open }) => {
  const { isDarkMode } = useTheme();

  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <Backdrop
      sx={{ color: theme.palette.primary.main, zIndex: 9999999999999 }}
      open={open}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default BackdropLoading;
