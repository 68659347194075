import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { enqueueSnackbar } from "notistack";

import { ReactComponent as FinanceIcon } from "../../assets/reports-finance.svg";
import { ReactComponent as PayrollIcon } from "../../assets/reports-payroll.svg";
import { ReactComponent as RosterIcon } from "../../assets/reports-roster.svg";
import { ReactComponent as SalesIcon } from "../../assets/reports-sales.svg";
import { ReactComponent as TimesheetIcon } from "../../assets/reports-timesheets.svg";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import ReportsTabs from "./ReportsTabs";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";

const ReportsDashboard = () => {
  const { dbId, lang } = useParams();

  const { apiCall } = useApi();
  const prefix = `/${dbId}/${lang}/reports`;
  const [reportsData, setReportsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const navigate = useNavigate();

  //   useEffect(() => {
  //     // Simulate loading
  //     setLoading(true);
  //     setTimeout(() => {
  //       const fakeData = {
  //         Accounting: [
  //           {
  //             ID: "balance-sheet",
  //             Group: "Accounting",
  //             Title: "Balance Sheet",
  //             Description:
  //               "Statement of assets, liabilities and equity on selected day",
  //             Address: "/balance-sheet",
  //           },
  //           {
  //             ID: "profit-loss",
  //             Group: "Accounting",
  //             Title: "Profit & Loss",
  //             Description: "Statement of income and expenses for period",
  //             Address: "/profit-loss",
  //           },
  //         ],
  //         Payroll: [
  //           {
  //             ID: "payroll-summary",
  //             Group: "Payroll",
  //             Title: "Payroll Summary",
  //             Description: "Payroll summary for all employees.",
  //             Address: "/payroll-summary",
  //           },
  //         ],
  //         Sales: [
  //           {
  //             ID: "receivables-summary",
  //             Group: "Sales",
  //             Title: "Receivables Summary",
  //             Description:
  //               "Total amount owing per Debtor with in-line breakdown on days overdue.",
  //             Address: "/receivables-summary",
  //           },
  //         ],
  //         Timesheet: [
  //           {
  //             ID: "timesheet-summary",
  //             Group: "Timesheet",
  //             Title: "Timesheet Summary",
  //             Description: "Timesheet report based on activity type.",
  //             Address: "/timesheet-summary",
  //           },
  //         ],
  //       };
  //       setReportsData(fakeData);
  //       setLoading(false);
  //     }, 1000);
  //   }, []);

  useEffect(() => {
    const fetchReports = () => {
      apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/reports/get-list-of-reports-for-dashboard?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        onSuccess: (data) => {
          if (data.SuccessYN === "Y") {
            // Group reports by Group field
            const groupedReports = data.Items.reduce((acc, item) => {
              acc[item.Group] = [...(acc[item.Group] || []), item];
              return acc;
            }, {});
            setReportsData(groupedReports);
            setLoading(false);
          } else {
            enqueueSnackbar(data.ErrorMessage || "Failed to fetch reports", {
              variant: "error",
            });
            setLoading(false);
          }
        },
        onError: (errorMessage) => {
          enqueueSnackbar(errorMessage || "An error occurred", {
            variant: "error",
          });
          setLoading(false); // Set loading to false on error
        },
      });
    };

    fetchReports();
  }, [apiCall]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCardClick = (address) => {
    navigate(prefix + address);
  };

  const renderReportItem = (report) => {
    const getIcon = (group) => {
      switch (group) {
        case "Accounting":
          return (
            <FinanceIcon
              style={{
                width: 40,
                height: 40,
                opacity: 0,
                transition: "0.2s",
                stroke: theme.palette.primary.main,
              }}
            />
          );
        case "Payroll":
          return (
            <PayrollIcon
              style={{
                stroke: theme.palette.primary.main,
                width: 40,
                height: 40,
                opacity: 0,
                transition: "0.2s",
              }}
            />
          );
        case "Roster":
          return (
            <RosterIcon
              style={{
                stroke: theme.palette.primary.main,
                width: 40,
                height: 40,
                opacity: 0,
                transition: "0.2s",
              }}
            />
          );
        case "Sales":
          return (
            <SalesIcon
              style={{
                stroke: theme.palette.primary.main,
                width: 40,
                height: 40,
                opacity: 0,
                transition: "0.2s",
              }}
            />
          );
        case "Timesheet":
          return (
            <TimesheetIcon
              style={{
                stroke: theme.palette.primary.main,
                width: 40,
                height: 40,
                opacity: 0,
                transition: "0.2s",
              }}
            />
          );
        default:
          return (
            <FinanceIcon
              style={{
                stroke: theme.palette.primary.main,
                width: 40,
                height: 40,
                opacity: 0,
                transition: "0.2s",
              }}
            />
          );
      }
    };

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.5} key={report.ID}>
        <Card
          onClick={() => handleCardClick(report.Address)}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            transition: "0.3s",
            px: 8,
            py: 4,
            borderRadius: "8px",
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
            "&:hover": {
              backgroundColor: "rgba(132, 159, 35, 0.04)",
              svg: {
                opacity: "1 !important",
              },
            },
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 0,
            }}
          >
            {getIcon(report.Group)}
            <Typography
              variant="h6"
              sx={{
                mt: "13px",
                mb: "13px",
                color: "#757575",
                textAlign: "center",
                leadingTrim: "both",
                textEdge: "cap",
                fontWeight: 700,
              }}
            >
              {report.Title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {report.Description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  if (loading) {
    return <BackdropLoading open={loading} />;
  }

  const availableGroups = Object.keys(reportsData);

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Reports Dashboard
      </Typography>

      {availableGroups.length > 0 && (
        <ReportsTabs
          availableGroups={availableGroups}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      )}

      <Card
        sx={{
          p: 4,
          gap: 4,
          boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        }}
      >
        <Box mt={3}>
          {availableGroups.map((group, index) => (
            <div
              key={group}
              style={{ display: selectedTab === index ? "block" : "none" }}
            >
              <Grid container spacing={3}>
                {reportsData[group].map((report) => renderReportItem(report))}
              </Grid>
            </div>
          ))}
        </Box>
      </Card>
    </Box>
  );
};

export default ReportsDashboard;
