import * as React from "react";
import { Box, Button, Divider, List, ListItem } from "@mui/material";
import {
  DatePickerSlotsComponentsProps,
  PickersShortcutsProps,
} from "@mui/x-date-pickers-pro";

interface DataPickerCustomShortcutsProps extends PickersShortcutsProps<any> {
  onShortcutClick: (newValue: any) => void;
}

export const DatePickerShortcuts:
  | React.JSXElementConstructor<DataPickerCustomShortcutsProps>
  | undefined = (props) => {
  const { items, onChange, isValid, onShortcutClick } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onShortcutClick(newValue);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2,
      }}
    >
      <List
        dense
        sx={(theme) => ({
          display: "flex",
          "& .MuiListItem-root": {
            pt: 0,
            pl: 1,
          },
        })}
      >
        {resolvedItems.map((item) => {
          return (
            <ListItem sx={{ px: 0, justifyContent: "center" }} key={item.label}>
              <Button
                sx={{
                  fontSize: "12px",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  item.onClick();
                }}
                disabled={item.disabled}
              >
                {item.label}
              </Button>
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
};
