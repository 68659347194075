import React from "react";
import { Button, SxProps, Theme } from "@mui/material";

// @ts-ignore TODO: Fix webpack configuration (probably)
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";

interface DownloadButtonProps {
  title: string;
  // TODO: add more variants
  variant?: "text" | "outlined" | "contained";
  sx?: SxProps<Theme> | undefined;
  onClick: () => void;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  title,
  sx,
  onClick,
}) => {
  return (
    <Button
      // @ts-ignore
      variant="secondary"
      onClick={onClick}
      sx={{ fontWeight: 700, ...sx }}
    >
      <DownloadIcon style={{ marginRight: "8px" }} /> {title}
    </Button>
  );
};
