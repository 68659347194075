import {
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "../../context/ThemeContext";
import { commonThemes } from "../../utils/themes/themes";
import { useLocation } from "react-router-dom";

const BankJournalHeader = ({
  isReceiveMoney,
  isSpendMoney,
  formState,
  setFormState,
  listOfContacts,
  listOfAccounts,
}) => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    jnlDate,
    jnlAccount_accNo,
    jnlContact_cntID,
    jnlMemo,
    showPrivatePortion,
    showVaryManualTax,
    jnlCode,
  } = formState;

  const handleDateChange = (newDate) => {
    setFormState({ ...formState, jnlDate: newDate });
  };

  const handleContactChange = (event) => {
    setFormState({ ...formState, jnlContact_cntID: event.target.value });
  };

  const handleAccountChange = (event) => {
    setFormState({ ...formState, jnlAccount_accNo: event.target.value });
  };

  const handleMemoChange = (event) => {
    setFormState({ ...formState, jnlMemo: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.checked });
  };

  const handleTypeChange = (event) => {
    setFormState({ ...formState, jnlCode: event.target.value });
  };

  return (
    <Box
      className={
        isReceiveMoney ? "receive-money" : isSpendMoney ? "spend-money" : ""
      }
      component="form"
      sx={{
        mb: 5,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 2,
        padding: "15px 15px 15px 15px",
        border: isDarkMode ? "1px solid #272E3D" : "1px solid #ddd",
        backgroundColor: isDarkMode ? "#272E3D" : "#fafafa",
        boxShadow: "0 0 30px 0 rgba(82, 63, 105, 0.05)",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mr: 4,
        }}
      >
        <Typography sx={{ fontSize: 14 }} variant="p">
          Type
        </Typography>
        <RadioGroup
          aria-label="jnlCode"
          name="jnlCode"
          value={jnlCode || ""}
          sx={{ display: "flex", flexDirection: "column", mt: -0.5 }}
          onChange={handleTypeChange}
        >
          <FormControlLabel
            value="CI"
            sx={{ span: { ...commonThemes.normalText } }}
            control={<Radio />}
            label="Receive Money"
          />
          <FormControlLabel
            value="CO"
            sx={{ span: { ...commonThemes.normalText } }}
            control={<Radio />}
            label="Spend Money"
          />
        </RadioGroup>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          {console.log(jnlDate)}
          <DatePicker
            label="Date"
            sx={{
              label: {
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
            }}
            value={jnlDate || null}
            onChange={handleDateChange}
            disabled={!!queryParams.get("date")} // Lock if the date is provided in the URL
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Typography sx={{ fontSize: 14, mb: -1.5 }} variant="p">
          Show / Hide Columns
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPrivatePortion}
                onChange={handleCheckboxChange}
                name="showPrivatePortion"
              />
            }
            label="Private Portion"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showVaryManualTax}
                onChange={handleCheckboxChange}
                name="showVaryManualTax"
              />
            }
            label="Vary / Manual Tax"
          />
        </Box>
      </Box>
      <TextField
        select
        label="Bank Account"
        InputLabelProps={{ shrink: !!jnlAccount_accNo }}
        sx={{
          minWidth: "200px",
          label: {
            ...commonThemes.inputLabel,
            color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
          },
        }}
        value={jnlAccount_accNo}
        onChange={handleAccountChange}
        disabled={queryParams.get("account") || !listOfAccounts?.length > 0} // Lock if account is provided in URL
      >
        {listOfAccounts?.map((data) => (
          <MenuItem key={data?.accNo} value={data?.accNo}>
            <div
              style={{
                whiteSpace: "normal",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <b>{data?.accNo}</b>
              </div>
              <div>{data?.accName}</div>
              <div>
                <i>{`(${data?.accType})`}</i>
              </div>
            </div>
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Contact"
        sx={{
          minWidth: "200px",
          label: {
            ...commonThemes.inputLabel,
            color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
          },
        }}
        InputLabelProps={{ shrink: !!jnlContact_cntID }}
        value={jnlContact_cntID}
        onChange={handleContactChange}
        disabled={!listOfContacts?.length > 0}
      >
        {listOfContacts?.map((contact) => (
          <MenuItem key={contact.cntId} value={contact.cntId}>
            {contact.cntName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Transaction Memo"
        multiline
        rows={3}
        value={jnlMemo || ""}
        onChange={handleMemoChange}
        InputLabelProps={{ shrink: !!jnlMemo }}
        sx={{
          label: {
            ...commonThemes.inputLabel,
            color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column", mr: 0, gap: 0 }}
        ></Box>
      </Box>
    </Box>
  );
};

export default BankJournalHeader;
