import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";
import { default_headers } from "../../utils/api";

const SessionManagement = () => {
  const { lang, dbId, sessionGuid } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { apiCall } = useApi();
  const ip = useGetIP();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseIp = await fetch("https://api.ipify.org?format=json");
        const dataIp = await responseIp.json();

        const url = `https://${envConfig.apiLogin}/api/v1/en-au/session-management/register-new-session-instance?BaseHostURL=${envConfig.mainServiceUrl}`;

        await apiCall({
          ip: ip,
          url,
          method: "POST",
          headers: {
            ...default_headers,
            "Content-Type": "application/json",
            "browser-agent": window.navigator.userAgent,
          },
          body: {
            IpAddress: dataIp.ip,
            UserAgentAkaBrowserDetails: navigator.userAgent,
            CurrentHost: window.location.origin,
            SessionGuid: sessionGuid,
          },
          onSuccess: (dataApiCall) => {
            const regex = new RegExp(`${envConfig.mainServiceUrl}/([^/]+)/`);
            const match = dataApiCall?.redirectUrl.match(regex);
            let databaseId;
            if (match && match[1]) {
              databaseId = match[1];
            }
            localStorage.setItem("savedReferrer", dataApiCall.referrerUrl);
            document.cookie = `loginEmail=${dataApiCall.loginEmail}; path=/`;
            document.cookie = `loginPassword=${dataApiCall.loginPassword}; path=/`;
            document.cookie = `sessionGuid=${sessionGuid}; path=/`;
            document.cookie = `globalUserId=${dataApiCall.globalUserID}; path=/`;
            document.cookie = `databaseId=${databaseId || dataApiCall.databaseID}; path=/`;
            document.cookie = `login2FASecKey=${dataApiCall.login2FASecKey}; path=/`;
            document.cookie = `login2FaCodeLastUsedUTCDate=${dataApiCall.login2FaCodeLastUsedUTCDate}; path=/`;
            document.cookie = `loginLastSec2CheckInUTCDate=${dataApiCall.LoginLastSec2CheckInUTCDate}; path=/`;
            document.cookie = `loginLastPwdUTCDate=${dataApiCall.loginLastPwdUTCDate}; path=/`;
            document.cookie = `apiKeyExpirationUTCDate=${dataApiCall.apiKeyExpirationUTCDate}; path=/`;
            document.cookie = `sessionId=${dataApiCall.sessionId}; path=/`;
            document.cookie = `apiKey=${dataApiCall.apiKey}; path=/`;

            const path =
              new URL(dataApiCall?.redirectUrl)?.pathname +
              new URL(dataApiCall?.redirectUrl)?.search;

            if (path) {
              navigate(`${path}`);
            } else {
              navigate(`/0/en-au/dashboard`);
            }
          },
          onError: (errorMessage) => {
            if (
              errorMessage === "Session Guid has been expired" ||
              errorMessage === "Session Guid has been used before"
            ) {
              const savedReferrer = localStorage.getItem("savedReferrer");
              if (savedReferrer) {
                localStorage.removeItem("savedReferrer");
                window.location.href = savedReferrer;
                return;
              }
              enqueueSnackbar(errorMessage, { variant: "error" });
              window.location = `https://${envConfig.loginUrl}`;
            } else {
              enqueueSnackbar(errorMessage || "An error occurred", {
                variant: "error",
              });
              window.location = `https://${envConfig.loginUrl}`;
            }
          },
        });
      } catch (error) {
        console.error("Error fetching IP address or making API call:", error);
        enqueueSnackbar(error.message || "An error occurred", {
          variant: "error",
        });
        navigate(envConfig.loginUrl);
      }
    };

    fetchData();
  }, [apiCall, enqueueSnackbar, navigate, sessionGuid]);

  return <div></div>;
};

export default SessionManagement;
