// ReportDatesContext.js
import React, { createContext, useState } from "react";

const ReportDatesContext = createContext();

export const ReportDatesProvider = ({ children }) => {
  const [reportDates, setReportDates] = useState(null);
  const [interfacePreference, setInterfacePreference] = useState(null);

  const setPageMainData = ({ dates, interfacePreference }) => {
    setReportDates(dates);
    setInterfacePreference(interfacePreference);
  };

  return (
    <ReportDatesContext.Provider
      value={{ setPageMainData, reportDates, interfacePreference }}
    >
      {children}
    </ReportDatesContext.Provider>
  );
};

export default ReportDatesContext;
