import React, { forwardRef } from "react";
import DataGrid, {
  Column,
  Scrolling,
  Selection,
} from "devextreme-react/cjs/data-grid";
import { SelectColumnHeader } from "./import-transactions-from-csv-table-header-cell";
import { Box } from "@mui/material";

interface ImportTransactionFromCSVTableProps {
  dataSource: any[];
  columns: any[];
  listOfHeaders: string[];
  handleHeaderChange: any;
  headers: any;
}

export const ImportTransactionFromCSVTable = forwardRef<
  any,
  ImportTransactionFromCSVTableProps
>(
  (
    { dataSource, columns, listOfHeaders, headers, handleHeaderChange },
    ref,
  ) => {
    const renderHeaderCell = React.useCallback(
      (cell: any) => {
        if (cell.column.caption === "RowNo") {
          return <div>{cell.column.caption}</div>;
        }

        return (
          <SelectColumnHeader
            listOfHeaders={listOfHeaders}
            value={cell.column.caption}
            onHeaderSelect={(e) =>
              handleHeaderChange(e.target.value, cell.columnIndex - 1)
            }
          />
        );
      },
      [handleHeaderChange, listOfHeaders],
    );

    if (columns.length === 0) {
      return null;
    }

    return (
      <Box
        sx={{
          "& .dx-datagrid-text-content": {
            width: "100%",
          },
        }}
      >
        <DataGrid
          ref={ref}
          dataSource={dataSource}
          columnAutoWidth={true}
          repaintChangesOnly={true}
          allowColumnResizing={false}
          height={750}
        >
          <Selection mode="multiple" />
          <Scrolling mode="virtual" />
          {columns.map((col, colIndex) => (
            <Column
              key={col}
              dataField={col}
              caption={headers[colIndex]}
              headerCellRender={renderHeaderCell}
            />
          ))}
        </DataGrid>
      </Box>
    );
  },
);
