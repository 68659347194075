import { useMutation } from "@tanstack/react-query";

import envConfig from "../../../../config";
import { getHeaders } from "../../../../utils/api";
import { MutationConfig } from "../../../../lib/react-query";
import { BASReportsGridRS1RequestBody, BASReportsGridRS1Response } from "../../../../types/api/business-activity";
import { EXAMPLE_GET_BAS_REPORTS_RESPONSE } from "../../../../data/example-get-bas-reports-response";
import { checkAndUpdateApiKeyExpiration } from "../../../../hooks/useApi";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { useParams } from "react-router-dom";

export const getBASReports = async (
  body: BASReportsGridRS1RequestBody, handleOpen, dbId
): Promise<BASReportsGridRS1Response> => {
  if (process.env.NODE_ENV === "development") {
    console.log("Get BAS Initial Settings (DEV)");
    return EXAMPLE_GET_BAS_REPORTS_RESPONSE;
  }

  const controllerName = "accounting";
  const actionName = "get-bas-grid-rs1";
  const api = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

  const headers = getHeaders(dbId);

  const response = await fetch(api, {
    method: "POST",
    // @ts-ignore
    headers: headers,
    body: JSON.stringify(body),
  });

  if (response.status === 403 || response.status === 401) {
    setTimeout(async () => {
handleOpen();
      // window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}` as unknown as Location;
    }, 1000);
    throw new Error("Your session has expired, please log back in.");
  }

  if (api.includes(envConfig.apiDev1) || api.includes(envConfig.apiDev2)) {
    checkAndUpdateApiKeyExpiration();
  }

  const result = await response.json();

  if (response.ok && result?.SuccessYN === "Y") {
    return result;
  } else {
    throw new Error(
      `An error occurred: ${result?.ErrorMessage || result?.errorMessage || "Unknown error"}`,
    );
  }
};

type UseGetGeneralLedgerReportsOptions = {
  mutationConfig?: MutationConfig<typeof getBASReports>;
};

export const useGetBASReports = ({
  mutationConfig,
}: UseGetGeneralLedgerReportsOptions = {}) => {
  const { ...restConfig } = mutationConfig || {};
  const {handleOpen} = useLoginPopup();
  const { dbId } = useParams();

  return useMutation({
    ...restConfig,
    retry: 0,
    mutationFn: (body) => getBASReports(body, handleOpen, dbId), // Pass handleOpen to getBASReports
  });
};
