import { InputAdornment, TextField } from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid-pro";
// import { ClearIcon } from "@mui/x-date-pickers-pro";
import React from "react";

interface SearchInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
}

export const SearchInput = ({ onChange, onClear }: SearchInputProps) => {
  return (
    <TextField
      placeholder="Search ..."
      variant="outlined"
      label="Search"
      size="small"
      sx={{ width: "100%", maxWidth: "240px" }}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <GridSearchIcon />
          </InputAdornment>
        ),
        // endAdornment: (
        //   <InputAdornment
        //     position="end"
        //     // style={{ display: showClearIcon }}
        //     onClick={onClear}
        //   >
        //     <ClearIcon />
        //   </InputAdornment>
        // ),
      }}
    />
  );
};
