import React from "react";

import { ReactComponent as SingleLeftArrow } from "../../assets/arrow-left-keyboard.svg";
import { ReactComponent as SingleRightArrow } from "../../assets/arrow-right-keyboard.svg";

import { Button } from "@mui/material";

interface SingleArrowProps {
  disabled?: boolean;
  isRight?: boolean;
  onClick: () => void;
}

export const SingleArrow: React.FC<SingleArrowProps> = ({
  disabled = false,
  onClick,
  isRight = false,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={{ minWidth: "24px", padding: "2px" }}
    >
      {isRight ? <SingleRightArrow /> : <SingleLeftArrow />}
    </Button>
  );
};
