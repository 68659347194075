import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  TextField,
} from "@mui/material";

import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";
import { useLedgedStore } from "../store";
import { Account } from "../../../../types/api/accounts";
import { useGetAccounts } from "../../../accounting/api/get-accounts";
import { useLoginPopup } from "../../../../context/LoginPopupContext";

export const GeneralLedgerAccounts = () => {
  const { isDarkMode } = useTheme();
  const { data: accountsData, isPending: accountsIsLoading } = useGetAccounts();

  const selectedAccounts = useLedgedStore.use.selectedAccounts();
  const setSelectedAccounts = useLedgedStore.use.setSelectedAccounts();

  // @ts-ignore
  const accounts = accountsData?.ListOfAccounts || [];

  const values = accounts.filter((account) =>
    selectedAccounts.includes(account.accNo),
  );

  return (
    <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
      <Autocomplete
        loading={accountsIsLoading}
        multiple
        id="accounts-autocomplete"
        options={accounts || []}
        getOptionLabel={(option) => `${option.accNo} `}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props}>
            <Box>
              <Box fontWeight={"bold"}>{option.accNo}</Box>
              <div>{option.accName}</div>
            </Box>
          </ListItem>
        )}
        value={values}
        onChange={(_, newValue) =>
          setSelectedAccounts(newValue.map((v) => v.accNo))
        }
        // onChange={(_, newValue) => setSelectedAccounts(newValue)}
        isOptionEqualToValue={(option, value) => option.accNo === value.accNo}
        // isOptionEqualToValue={(option, value) => option.accNo === value}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label="Accounts"
            sx={{
              label: {
                fontSize: 12,
                // @ts-ignore
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
              minWidth: "240px",
              maxWidth: "240px",
            }}
          />
        )}
      />
    </FormControl>
  );
};
