import {
  Typography,
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
} from "@mui/material";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import HelpTooltip from "../../components/HelpTooltip/HelpTooltip";
import { useEffect, useState } from "react";
import { getCookie, getHeaders } from "../../utils/api";
import { useTheme } from "../../context/ThemeContext";
import ReCAPTCHA from "react-google-recaptcha";
import { useLoginPopup } from "../../context/LoginPopupContext";
import envConfig from "../../config";
import { useGetIP } from "../../hooks/get-ip";
import useApi from "../../hooks/useApi";
import { enqueueSnackbar } from "notistack";

const AustralianBusinessNumber = (ABN) => {
  ABN = ABN.trim().replace(" ", "");

  if (ABN.length !== 11) {
    return false;
  }

  if (isNaN(ABN)) {
    return false;
  }

  let T1 = (parseInt(ABN.substring(0, 1)) - 1) * 10;
  let T2 = parseInt(ABN.substring(1, 2)) * 1;
  let T3 = parseInt(ABN.substring(2, 3)) * 3;
  let T4 = parseInt(ABN.substring(3, 4)) * 5;
  let T5 = parseInt(ABN.substring(4, 5)) * 7;
  let T6 = parseInt(ABN.substring(5, 6)) * 9;
  let T7 = parseInt(ABN.substring(6, 7)) * 11;
  let T8 = parseInt(ABN.substring(7, 8)) * 13;
  let T9 = parseInt(ABN.substring(8, 9)) * 15;
  let T10 = parseInt(ABN.substring(9, 10)) * 17;
  let T11 = parseInt(ABN.substring(10, 11)) * 19;

  let Chk = T1 + T2 + T3 + T4 + T5 + T6 + T7 + T8 + T9 + T10 + T11;
  Chk = Chk / 89;
  if (Chk === Math.floor(Chk)) {
    return true;
  }

  return false;
};

const CreateBusinessAccount = ({ isCreatingSubscriber }) => {
  const navigate = useNavigate();
  const { dbId, lang } = useParams();
  const prefix = `/${dbId}/${lang}/`;
  const { isDarkMode } = useTheme();

  const { handleOpen } = useLoginPopup();
  const { apiCall } = useApi();
  const ip = useGetIP();

  const [isLoading, setIsLoading] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [subscribersData, setSubscribersData] = useState(null);
  const [subscribersError, setSubscribersError] = useState(null);
  const [subscribersLoading, setSubscribersLoading] = useState(false);

  const [countriesData, setCountriesData] = useState(null);
  const [countriesError, setCountriesError] = useState(null);
  const [countriesLoading, setCountriesLoading] = useState(false);

  const [businessTypesData, setBusinessTypesData] = useState(null);
  const [businessTypesError, setBusinessTypesError] = useState(null);
  const [businessTypesLoading, setBusinessTypesLoading] = useState(false);

  const defaultHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "olivs-api-real-ip": ip,
    "olivs-api-computer-name": "BTM-WS2019",
    "session-id": getCookie("sessionId"),
    "browser-agent": window.navigator.userAgent,
    "olivs-root-password": process.env.REACT_APP_OLIVS_ROOT_PASSWORD,
    "api-key": getCookie("apiKey") + "-0",
  };

  useEffect(() => {
    const fetchSubscribers = async () => {
      setSubscribersLoading(true);
      await apiCall({
        url: `https://${envConfig.apiDev1}/api/v1/en-au/subscribers/get-subscribers-list?LoginEmail=${getCookie(
          "loginEmail",
        )}&BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        headers: defaultHeaders,
        onSuccess: (data) => {
          setSubscribersData(data);
          setSubscribersLoading(false);
        },
        onError: (errorMessage) => {
          setSubscribersError(errorMessage);
          setSubscribersLoading(false);
        },
      });
    };

    if (!isCreatingSubscriber) {
      fetchSubscribers();
    }
  }, [apiCall, isCreatingSubscriber]);

  useEffect(() => {
    const fetchCountries = async () => {
      setCountriesLoading(true);
      await apiCall({
        url: `https://${envConfig.apiDev1}/api/v3/en-au/countries/get-all?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        headers: defaultHeaders,
        onSuccess: (data) => {
          setCountriesData(data);
          setCountriesLoading(false);
        },
        onError: (errorMessage) => {
          setCountriesError(errorMessage);
          setCountriesLoading(false);
        },
      });
    };

    fetchCountries();
  }, [apiCall]);

  useEffect(() => {
    const fetchBusinessTypes = async () => {
      setBusinessTypesLoading(true);
      await apiCall({
        url: `https://${envConfig.apiDev1}/api/v3/en-au/businesses/get-types?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        headers: defaultHeaders,
        onSuccess: (data) => {
          setBusinessTypesData(data);
          setBusinessTypesLoading(false);
        },
        onError: (errorMessage) => {
          setBusinessTypesError(errorMessage);
          setBusinessTypesLoading(false);
        },
      });
    };

    fetchBusinessTypes();
  }, [apiCall]);

  const formSchema = Yup.object().shape({
    subscriberAccount: isCreatingSubscriber
      ? Yup.string()
      : Yup.string().required("Selecting a subscriber is required"),
    businessName: Yup.string().required("Business Name is required"),
    abn: Yup.string()
      .required("ABN is required")
      .test("valid-abn", "Invalid ABN", (value) =>
        AustralianBusinessNumber(value),
      ),
    businessOwnerEmail: Yup.string()
      .email("Invalid email")
      .required("Business Owner Email is required"),
    contactName: Yup.string(),
    contactEmail: Yup.string().email("Invalid email"),
    contactPhone: Yup.string(),
    businessType: Yup.string().required("Business Type is required"),
    gstRegistered: Yup.boolean(),
    countryOfResidence: Yup.string().required(
      "Country of Residence is required",
    ),
    allowTeamAccess: Yup.boolean(),
    allowTeamInviteExternal: Yup.boolean(),
    taxAgent: Yup.boolean(),
    registeredAgentNumber: Yup.string(),
    registeredAgentName: Yup.string(),
    registeredAgentEmailAddress: Yup.string().email("Invalid email"),
    registeredAgentPhoneNumber: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      subscriberAccount: "",
      businessName: "",
      abn: "",
      businessOwnerEmail: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      manageBusiness: false,
      businessType: "",
      gstRegistered: false,
      countryOfResidence: "AUS",
      allowTeamAccess: false,
      allowTeamInviteExternal: false,
      taxAgent: false,
      registeredAgentNumber: "",
      registeredAgentName: "",
      registeredAgentEmailAddress: "",
      registeredAgentPhoneNumber: "",
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      if (!recaptchaValue) return;
      setIsLoading(true);
      const apiData = {
        // subscriberAccountID: values.subscriberAccount,
        BusinessName: values.businessName,
        BusinessTypeId: values.businessType,
        BusinessRegistered4GSTYN: values.gstRegistered ? "Y" : "N",
        AutoAccessYN: values.allowTeamAccess ? "Y" : "N",
        AllowTeamMembersInviteExternalUsersYN:
          values.allowTeamAccess && values.allowTeamInviteExternal ? "Y" : "N",
        CountryId: 1,
        TaxCode: values.abn,
        ContactName: values.contactName,
        ContactPhoneNo: values.contactPhone,
        OwnerEmail: values.businessOwnerEmail,
        TaxAgentRAN: values.taxAgent ? values.registeredAgentNumber : "",
        TaxAgentName: values.taxAgent ? values.registeredAgentName : "",
        TaxAgentEmail: values.taxAgent
          ? values.registeredAgentEmailAddress
          : "",
        TaxAgentPhone: values.taxAgent ? values.registeredAgentPhoneNumber : "",
      };

      const controllerName = isCreatingSubscriber ? "subscribers" : "databases";
      const actionName = isCreatingSubscriber
        ? "create-subscriber-with-business-file"
        : "create-new-business-file";
      const url = `https://${envConfig.apiDev1}/api/v3/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

      await apiCall({
        url,
        ip: ip,
        method: "POST",
        body: apiData,
        onSuccess: (response) => {
          setIsLoading(false);
          navigate(prefix + "dashboard");
        },
        onError: (error) => {
          setIsLoading(false);
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    },
  });

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleContinueEditing = () => {
    setOpenCancelDialog(false);
  };

  const handleCancelConfirm = () => {
    setOpenCancelDialog(false);
    navigate(prefix + "dashboard");
  };

  return (
    <>
      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
      >
        <DialogContent>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 400,
              lineHeight: "150%",
              letterSpacing: "-0.264px",
              mb: 5,
            }}
          >
            Cancel creating Business Account?
          </Typography>
          <Typography
            sx={{
              color: "#495670",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "150%",
              letterSpacing: "-0.176px",
            }}
          >
            Do you want to cancel creating Business Account?
          </Typography>
          <Typography
            sx={{
              color: "#495670",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "150%",
              letterSpacing: "-0.176px",
            }}
          >
            Your data will be lost.
          </Typography>
          <Box sx={{ mt: 5, textAlign: "right" }}>
            <Button
              sx={{
                background: "transparent",
                height: "36px",
                mr: "9px",
                "&:hover": {
                  background: "transparent",
                },
              }}
              disableRipple
              variant="secondary"
              onClick={handleContinueEditing}
            >
              Continue Editing
            </Button>
            <Button
              disableRipple
              variant="contained"
              color="primary"
              onClick={handleCancelConfirm}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          borderRadius: "4px",
          bgcolor: isDarkMode ? "unset" : "#FEFDFE",
          mb: "74px",
        }}
      >
        <PageTitle
          title={
            isCreatingSubscriber
              ? "Create New Subscriber"
              : "Create Business Account"
          }
          onBack={() => setOpenCancelDialog(true)}
          displayBackIcon={true}
        />

        <Box
          sx={{
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
            px: 5,
            pt: 6,
            pb: 10.25,
            borderRadius: "4px",
            bgcolor: isDarkMode ? "#272E3D" : "#fff",
          }}
        >
          <Typography
            sx={{
              color: isDarkMode ? "rgba(255, 255, 255, 0.70)" : "#495670",
              fontWeight: 300,
            }}
          >
            You can enjoy 3 months using Olivs completely for free! No payment
            details needed now.
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            {!isCreatingSubscriber && (
              <Autocomplete
                sx={{
                  mt: 3,
                  maxWidth: "480px",
                  input: { height: "28px" },
                  label: { fontSize: 16, marginTop: 0 },
                  "p.Mui-error": {
                    position: "absolute",
                    bottom: -25,
                  },
                }}
                required={Boolean(!isCreatingSubscriber)}
                options={subscribersData || []}
                getOptionLabel={(option) => option?.xxsName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Subscriber account"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("subscriberAccount")}
                    error={
                      formik.touched.subscriberAccount &&
                      Boolean(formik.errors.subscriberAccount)
                    }
                    helperText={
                      formik.touched.subscriberAccount &&
                      formik.errors.subscriberAccount
                    }
                  />
                )}
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "subscriberAccount",
                    value?.xxsID || null,
                  );
                }}
              />
            )}
            {/* Business Name textbox required*/}
            <TextField
              sx={{
                mt: 4,
                maxWidth: "480px",
                display: "block",
                label: { fontSize: 16, marginTop: 0 },
                "p.Mui-error": {
                  position: "absolute",
                },
              }}
              label="Business Name"
              variant="outlined"
              required
              fullWidth
              {...formik.getFieldProps("businessName")}
              error={
                formik.touched.businessName &&
                Boolean(formik.errors.businessName)
              }
              helperText={
                formik.touched.businessName && formik.errors.businessName
              }
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* ABN textbox required */}
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "320px",
                  display: "block",
                  label: { fontSize: 16, marginTop: 0 },
                  "p.Mui-error": {
                    position: "absolute",
                  },
                }}
                label="ABN"
                required
                variant="outlined"
                fullWidth
                {...formik.getFieldProps("abn")}
                error={formik.touched.abn && Boolean(formik.errors.abn)}
                helperText={formik.touched.abn && formik.errors.abn}
              />
              <Link
                className="link"
                style={{
                  marginTop: "30px",
                  marginLeft: "16px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  color: isDarkMode ? "#AAD269" : "#849F23",
                  textDecoration: "none",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                }}
                to="#"
              >
                Lookup ABN
              </Link>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Business Owner Email Address - textbox required, email address*/}
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "480px",
                  display: "block",
                  label: { fontSize: 16, marginTop: 0 },
                  "p.Mui-error": {
                    position: "absolute",
                  },
                }}
                required
                label="Business Owner Email Address"
                variant="outlined"
                fullWidth
                type="email"
                {...formik.getFieldProps("businessOwnerEmail")}
                error={
                  formik.touched.businessOwnerEmail &&
                  Boolean(formik.errors.businessOwnerEmail)
                }
                helperText={
                  formik.touched.businessOwnerEmail &&
                  formik.errors.businessOwnerEmail
                }
              />
              <Box sx={{ mt: 4.75, ml: 2 }}>
                <HelpTooltip
                  tooltipContent={
                    <Box
                      sx={{
                        padding: "4px 8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#ffffff",
                        }}
                      >
                        You will have admin permissions as you create this
                        Business Account. If you’re not a Business Owner,
                        provide their email address here to grant an admin
                        permissions as well. This way, they can manage the
                        Business Account.
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>

            {/* Contact Name - textbox, not required */}
            {isCreatingSubscriber && (
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "480px",
                  display: "block",
                  label: { fontSize: 16, marginTop: 0 },
                }}
                label="Contact Name"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps("contactName")}
              />
            )}

            {/* Contact Email Address - textbox, not required */}
            {isCreatingSubscriber && (
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "480px",
                  display: "block",
                  label: { fontSize: 16, marginTop: 0 },
                }}
                label="Contact Email Address"
                variant="outlined"
                fullWidth
                type="email"
                {...formik.getFieldProps("contactEmail")}
              />
            )}

            {/* Contact Phone Number, not required */}
            {isCreatingSubscriber && (
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "480px",
                  label: { fontSize: 16, marginTop: 0 },
                }}
                label="Contact Phone Number"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps("contactPhone")}
              />
            )}
            <Autocomplete
              sx={{
                mt: 3,
                maxWidth: "480px",
                input: { height: "28px" },
                label: { fontSize: 16, marginTop: 0 },
                "p.Mui-error": {
                  position: "absolute",
                  bottom: -25,
                },
              }}
              required
              options={businessTypesData?.ListOfBusinessTypes || []}
              getOptionLabel={(option) => option?.xxtTypeName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Business Type"
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps("businessType")}
                  error={
                    formik.touched.businessType &&
                    Boolean(formik.errors.businessType)
                  }
                  helperText={
                    formik.touched.businessType && formik.errors.businessType
                  }
                />
              )}
              onChange={(event, value) => {
                formik.setFieldValue("businessType", value?.xxtID || null);
              }}
            />

            {/* Checkbox with label "This business is GST registered" */}
            <FormControlLabel
              sx={{
                mt: isCreatingSubscriber ? 3 : 2,
                label: { fontSize: 16, marginTop: 0 },
              }}
              control={
                <Checkbox
                  checked={formik.values.gstRegistered}
                  onChange={formik.handleChange}
                  name="gstRegistered"
                />
              }
              label="This business is GST registered"
            />

            {/* Country of Residence - Autocomplete select, required if above switch is true */}
            <Autocomplete
              sx={{
                mt: isCreatingSubscriber ? 3 : 1,
                maxWidth: "480px",
                input: { height: "28px" },
                label: { fontSize: 16, marginTop: 0 },
                "p.Mui-error": {
                  position: "absolute",
                  bottom: -25,
                },
              }}
              required
              options={countriesData?.ListOfCountries || []}
              getOptionLabel={(option) => option?.ctrName}
              defaultValue={{ ctrCode: "AUS", ctrName: "Australia" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country of Residence"
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps("countryOfResidence")}
                  error={
                    formik.touched.countryOfResidence &&
                    Boolean(formik.errors.countryOfResidence)
                  }
                  helperText={
                    formik.touched.countryOfResidence &&
                    formik.errors.countryOfResidence
                  }
                />
              )}
              onChange={(event, value) => {
                formik.setFieldValue(
                  "countryOfResidence",
                  value?.ctrCode || null,
                );
              }}
            />

            {/* Checkbox with label "Allow Team members of Premium Accounting Ltd to access business details automatically with Admin privileges" */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                sx={{ mt: 3 }}
                control={
                  <Checkbox
                    checked={formik.values.allowTeamAccess}
                    onChange={formik.handleChange}
                    name="allowTeamAccess"
                  />
                }
                label="Allow Team members of Premium Accounting Ltd to access business details automatically with Admin privileges"
              />
              <Box sx={{ mt: 3.6 }}>
                <HelpTooltip
                  tooltipContent={
                    <Box
                      sx={{
                        padding: "4px 8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#ffffff",
                        }}
                      >
                        Selecting this option will allow your Subscriber Account
                        team members to gain access with Admin privileges
                        without any invitation or further confirmation. They can
                        do it by selecting the company and clicking 'Request
                        access'. If this option is not selected, the same action
                        will send you a request to approve the request.
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>

            {formik.values.allowTeamAccess && (
              <FormControlLabel
                sx={{ mt: 1, display: "block" }}
                control={
                  <Checkbox
                    checked={formik.values.allowTeamInviteExternal}
                    onChange={formik.handleChange}
                    name="allowTeamInviteExternal"
                  />
                }
                label="Allow Team members of Premium Accounting Ltd to invite external users"
              />
            )}

            {/* Switch with label "This Subscriber Account is a tax/BAS agent" */}
            {isCreatingSubscriber && (
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <Switch
                    checked={formik.values.taxAgent}
                    onChange={formik.handleChange}
                    name="taxAgent"
                  />
                }
                label="This Subscriber Account is a tax/BAS agent"
              />
            )}

            {formik.values.taxAgent && (
              <>
                <Typography
                  sx={{
                    color: isDarkMode ? "#FFF" : "#252525",
                    mt: 1,
                    fontWeight: 300,
                    lineHeight: "175%",
                    letterSpacing: "0.15px",
                  }}
                >
                  To send various reports (including TPAR and STP) as
                  intermediaries you will need some special permissions. They
                  are granted to every member of Subscriber Account if
                  Registered Agent Number and Name are provided. You can add
                  these details anytime later.
                </Typography>

                <TextField
                  sx={{
                    mt: 4,
                    maxWidth: "480px",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Contact Phone Number"
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps("contactPhone")}
                />

                <TextField
                  sx={{
                    mt: 4,
                    maxWidth: "480px",
                    display: "block",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Registered Agent Number"
                  variant="outlined"
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("registeredAgentNumber")}
                />

                <TextField
                  sx={{
                    mt: 4,
                    maxWidth: "480px",
                    display: "block",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Registered Agent Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("registeredAgentName")}
                />

                {/* Contact Phone Number, not required */}
                <TextField
                  sx={{
                    mt: 4,
                    maxWidth: "480px",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps("registeredAgentEmailAddress")}
                />

                <TextField
                  sx={{
                    mt: 4,
                    maxWidth: "480px",
                    display: "block",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("registeredAgentPhoneNumber")}
                />
              </>
            )}

            {/* Buttons "Cancel" and "Create" */}
            <Box sx={{ mt: 2 }}>
              <ReCAPTCHA
                sitekey="6LfmX3spAAAAAKHh4UCwUWx5RMzzv1OFDFJyi8sw"
                onChange={handleRecaptcha}
                theme={isDarkMode ? "dark" : "light"}
              />
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Button
                sx={{
                  background: "transparent",
                  height: "36px",
                  mr: "9px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                disableRipple
                variant="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                sx={{ ml: 2 }}
              >
                Create
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default CreateBusinessAccount;
