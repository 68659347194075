import BankFeedsNotConnected from "./BankFeedsNotConnected";

const BankFeedsAllAccounts = ({ handleActivate, handleDeactivate, data }) => {
  return (
    <BankFeedsNotConnected
      handleDeactivate={handleDeactivate}
      handleActivate={handleActivate}
      data={data?.map((row, index) => ({ ...row, id: index }))}
    />
  );
};

export default BankFeedsAllAccounts;
