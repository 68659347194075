import React, { createContext, useContext, useEffect, useState } from "react";
import { darkTheme, lightTheme } from "../utils/themes/themes";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { getCookie } from "../utils/api";
import Cookies from "js-cookie";
import themes from "devextreme/ui/themes";

interface ThemeContextProps {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const storedTheme = getCookie("theme");
  const prefersDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkMode, setIsDarkMode] = useState<boolean>(storedTheme ? storedTheme === "dark" : prefersDarkMode);

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  const appliedTheme = isDarkMode ? darkTheme : lightTheme;

  useEffect(() => {
    Cookies.set("theme", isDarkMode ? "dark" : "light", {
      path: "/",
    });

    const themeName = isDarkMode ? "material.custom-scheme-dark" : "material.custom-scheme-light";
    themes.current(themeName);
    themes.ready(() => {
      console.log("Switched to theme:", themeName);
    });
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      <MuiThemeProvider theme={appliedTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
