import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import envConfig from "../../../../config";
import { getHeaders } from "../../../../utils/api";
import { BASInitialSettingsResponse } from "../../../../types/api/business-activity";
import { EXAMPLE_GET_BAS_INITIAL_SETTINGS_RESPONSE } from "../../../../data/example-get-bas-initial-settings-response";
import { checkAndUpdateApiKeyExpiration } from "../../../../hooks/useApi";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { useParams } from "react-router-dom";

export const GET_BAS_INITIAL_SETTINGS_KEY = "GET_BAS_INITIAL_SETTINGS";

export const getBASInitialSettings =
  async (body, handleOpen, dbId): Promise<BASInitialSettingsResponse> => {
    if (process.env.NODE_ENV === "development") {
      return EXAMPLE_GET_BAS_INITIAL_SETTINGS_RESPONSE as BASInitialSettingsResponse;
    }

    const controllerName = "accounting";
    const actionName = "get-bas-initial-data-rs1";
    const api = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

    const headers = getHeaders(dbId);

    const response = await fetch(api, {
      method: "GET",
      // @ts-ignore
      headers: {
        // @ts-ignore
        "Content-Type": "application/json",
        ...headers,
      },
    });

    if (response.status === 403 || response.status === 401) {
      setTimeout(async () => {
      handleOpen();
        // window.location =
        //   `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}` as unknown as Location;
      }, 1000);
      throw new Error("Your session has expired, please log back in.");
    }

    if (api.includes(envConfig.apiDev1) || api.includes(envConfig.apiDev2)) {
      checkAndUpdateApiKeyExpiration();
    }

    const result = await response.json();

    if (response.ok && result?.SuccessYN === "Y") {
      return result;
    } else {
      throw new Error(
        `An error occurred: ${result?.ErrorMessage || result?.errorMessage || "Unknown error"}`,
      );
    }
  };

type UseGetBASInitialSettings = {
  queryOptions?: Omit<
    UseQueryOptions<
      BASInitialSettingsResponse,
      Error,
      BASInitialSettingsResponse
    >,
    "queryKey" | "queryFn"
  >;
};

export const useBASInitialSettings = ({
  queryOptions = {},
}: UseGetBASInitialSettings) => {
  const { handleOpen } = useLoginPopup();
  const { dbId } = useParams();

  return useQuery({
    ...queryOptions,
    queryKey: [GET_BAS_INITIAL_SETTINGS_KEY],
    queryFn: (body) => getBASInitialSettings(body, handleOpen, dbId),
  });
};
