import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { commonThemes } from "../../../../utils/themes/themes";

export const BASConfigureReportButton = ({
  onClick,
}: {
  onClick: () => void;
}) => {

  return (
    <Button
      onClick={onClick}
      sx={{

      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <FilterIcon /> */}
        <Typography
          sx={{
            position: "relative",
            // ml: 1,
            // @ts-ignore
            ...commonThemes.normalText,
            fontWeight: 700,
          }}
        >
          Configure Report
        </Typography>
      </Box>
    </Button>
  );
};
