import { useLayoutEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Autocomplete,
} from "@mui/material";
import MainNavigation from "./components/MainNavigation/MainNavigation";
import { ThemeProvider, useTheme } from "./context/ThemeContext";
import ProfitAndLoss from "./pages/ProfitAndLoss/ProfitAndLoss";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import SessionManagement from "./pages/SessionManagement/SessionManagement";
import { getCookie } from "./utils/api";
import { ReactComponent as ArrowBack } from "./assets/arrow-back.svg";
import { useEffect } from "react";
import Dashboard from "./pages/Dashboard/Dashboard";
import CreateBusinessAccount from "./pages/CreateBusinessAccount/CreateBusinessAccount";
import MSAuthentication from "./components/MicrosoftAuth/MicrosoftAuth";
import Callback from "./pages/Callback/Callback";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { commonThemes } from "./utils/themes/themes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ReactInputMask from "react-input-mask";
import { isValid, parse } from "date-fns";
import PageTitle from "./components/PageTitle/PageTitle";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import FindAndRedo from "./pages/FindAndRedo/FindAndRedo";
import DashboardMobile from "./pages/DashboardMobile/DashboardMobile";
import { PopupProvider, useLoginPopup } from "./context/LoginPopupContext";
import { AuthProvider } from "./context/CheckApiKeyContext";
import BankFeeds from "./pages/BankFeeds/BankFeeds";
import BankFeedsLinked from "./pages/BankFeedsLinked/BankFeedsLinked";
import config from "devextreme/core/config";
import { licenseKey } from "./devextreme-license";
import { ReportDatesProvider } from "./context/ReportDatesContext";
import BalanceSheet from "./pages/BalanceSheet/BalanceSheet";
import "timers-browserify";
import { SnackbarProvider, closeSnackbar, useSnackbar } from "notistack";
import NewCodes from "./pages/NewCodes/NewCodes";
import envConfig from "./config";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import GeneralJournalPage from "./components/GeneralJournal/GeneralJournalPage";
import BusinessActivityStatement from "./pages/BusinessActivityStatement/BusinessActivityStatement";
import { GeneralLedger } from "./pages/GeneralLedger/GeneralLedger";
import BankJournalPage from "./components/BankJournal/BankJournalPage";
import { CustomReports } from "./pages/CustomReports/CustomReports";
import { TaxCodesPage } from "./pages/TaxCodes/TaxCodes";
import NewPayEventPage from "./pages/NewPayEventPage/NewPayEventPage";
import ChartOfAccountsPage from "./pages/ChartOfAccounts/ChartOfAccountsPage";
import TwoFactorSetup from "./pages/MFAConfig/MFAConfig";
import TPARBatches from "./pages/ListOfBatches/ListOfBatches";
import { ImportTransactionsFromCSVPage } from "./pages/ImportTransactionFromCSV/ImportTransactionsFromCSV";
import ReportsDashboard from "./pages/ReportsDashboard/ReportsDashboard";

const action = (key) => (
  <IconButton
    size="small"
    aria-label="close"
    color="inherit"
    onClick={() => closeSnackbar(key)}
  >
    <GridCloseIcon fontSize="small" />
  </IconButton>
);

const ErrorPage = () => {
  const location = useLocation();
  const breakpoint = useMediaQuery("(max-width: 640px)");
  const { dbId, lang } = useParams();
  const prefix =
    dbId && lang ? `/${dbId}/${lang}/dashboard` : "/0/en-au/dashboard";
  const navigate = useNavigate();
  const [errorCode, setErrorCode] = useState();
  const [response, setResponse] = useState();

  useEffect(() => {
    if (!location.state?.errorCode && !errorCode) {
      navigate(-1);
    } else {
      if (!errorCode) {
        setErrorCode(location.state?.errorCode);
      }
      if (!response) {
        setResponse(location.state?.response);
      }
    }
  }, [location.state]);

  return (
    <Box sx={{ mx: "10%" }}>
      <Typography
        sx={{
          color: "#CECECE",
          fontFamily: "Inter",
          fontSize: breakpoint ? "90px" : "128px",
          fontStyle: "normal",
          fontWeight: "100",
          lineNeight: "128px",
          letterSpacing: "0.15px",
        }}
      >
        {errorCode}
      </Typography>
      <Typography
        sx={{
          ml: 1,
          color: "#495570",
          fontFamily: "Inter",
          fontSize: breakpoint ? "14px" : "24px",
          fontWeight: 700,
          lineHeight: "24px",
          letterSpacing: "0.15px",
        }}
      >
        Something went wrong on our side, sorry!
      </Typography>
      {errorCode == "500" ? (
        <Typography
          sx={{
            ml: 1,
            mt: 1,
            color: "#495570",
            fontSize: breakpoint ? "14px" : "24px",
            fontWeight: 400,
          }}
        >
          Please try again in a moment.
        </Typography>
      ) : (
        <Button
          disableRipple
          onClick={() => {
            navigate(prefix);
          }}
          sx={{
            bgcolor: "#F0F2E7",
            borderRadius: "8px",
            padding: "8px 16px",
            height: "40px",
            mt: 16,
          }}
          variant="secondary"
        >
          <ArrowBack style={{ marginRight: "8px" }} />
          Back to Dashboard
        </Button>
      )}
      {response && (
        <Box sx={{ mt: 10 }}>
          <Accordion
            sx={{
              outline: "none",
              boxShadow: "none",
              ".MuiAccordionSummary-root": {
                minHeight: "20px",
                justifyContent: "flex-start",
                paddingLeft: 0,
              },
              ".MuiAccordionSummary-content": {
                maxWidth: "105px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Typography
                variant="p"
                sx={{
                  color: "#495670",
                  fontSize: "13px",
                  fontWeight: 700,
                }}
              >
                See the details
              </Typography>
            </AccordionSummary>
            <Box sx={{ pb: 5 }}>
              {response?.time && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>Time:</Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {response.time}
                  </Box>
                </Box>
              )}
              {response?.controllerName && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Controller name:
                  </Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {response?.controllerName}
                  </Box>
                </Box>
              )}
              {response?.actionName && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Action name:
                  </Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {response?.actionName}
                  </Box>
                </Box>
              )}
              {response?.businessFileName && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Business file name:
                  </Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {response?.businessFileName}
                  </Box>
                </Box>
              )}

              {errorCode && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Error Code:
                  </Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {errorCode}
                  </Box>
                </Box>
              )}

              {response?.errorMessage && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Error message:
                  </Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {response?.errorMessage}
                  </Box>
                </Box>
              )}

              {response?.url && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Request URL:
                  </Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {response?.url}
                  </Box>
                </Box>
              )}

              {response?.isPostRequest && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Is post request:
                  </Box>
                  <Box
                    sx={{ fontSize: "14px", fontWeight: 500 }}
                  >{`${response?.isPostRequest}`}</Box>
                </Box>
              )}

              {response?.method && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Request method:
                  </Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {response?.method}
                  </Box>
                </Box>
              )}

              {response?.isAjaxRequest && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box sx={{ minWidth: "170px", fontSize: "14px" }}>
                    Is Ajax request:
                  </Box>
                  <Box
                    sx={{ fontSize: "14px", fontWeight: 500 }}
                  >{`${response?.isAjaxRequest}`}</Box>
                </Box>
              )}
            </Box>
          </Accordion>
        </Box>
      )}
    </Box>
  );
};

const Page404 = () => {
  const { dbId, lang } = useParams();
  const navigate = useNavigate();
  const prefix =
    dbId && lang
      ? `/${dbId}/${lang}/mobile/dashboard`
      : "/0/en-au/mobile/dashboard";
  const breakpoint = useMediaQuery("(max-width: 640px)");
  return (
    <Box sx={{ mx: "10%" }}>
      <Typography
        sx={{
          color: "#CECECE",
          fontFamily: "Inter",
          fontSize: breakpoint ? "90px" : "128px",
          fontStyle: "normal",
          fontWeight: "100",
          lineNeight: "128px",
          letterSpacing: "0.15px",
        }}
      >
        404
      </Typography>
      <Typography
        sx={{
          ml: 1,
          color: "#495570",
          fontFamily: "Inter",
          fontSize: breakpoint ? "14px" : "24px",
          fontWeight: 700,
          lineHeight: "24px",
          letterSpacing: "0.15px",
        }}
      >
        It seems this page doesn’t exist, sorry!
      </Typography>
      <Button
        disableRipple
        onClick={() => {
          navigate(prefix);
        }}
        sx={{
          bgcolor: "#F0F2E7",
          borderRadius: "8px",
          padding: "8px 16px",
          height: "40px",
          mt: 16,
        }}
        variant="secondary"
      >
        <ArrowBack style={{ marginRight: "8px" }} />
        Back to Dashboard
      </Button>
    </Box>
  );
};

const client_id = "1c75d035-1d15-415f-bb28-d86fe98e457a";

const msalConfig = {
  auth: {
    clientId: client_id,
    authority:
      "https://login.microsoftonline.com/2cafed09-2a9e-4e96-866d-e10f8a223acb",
    redirectUri: `${window.location.origin}/callback-ms365`,
  },
};

const InputBasedOnDataType = ({
  onUpdateSettings = () => {},
  idCode,
  loading,
  dataType,
  options,
  value,
}) => {
  const { isDarkMode } = useTheme();

  const [currentValue, setCurrentValue] = useState(
    options?.find((opt) => opt.Value === value) || value,
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (newValue) => {
    setCurrentValue(newValue);
    setIsEditing(newValue !== value);
  };

  const handleCancel = () => {
    setCurrentValue(value);
    setIsEditing(false);
  };

  const handleSave = async () => {
    await onUpdateSettings({
      idCode,
      newValue: currentValue?.Value || currentValue,
    });
    setIsEditing(false);
  };

  const onDDMMChange = (e) => {
    const inputValue = e.target.value;
    const parsedDate = parse(inputValue, "dd/MM", new Date());
    setCurrentValue(inputValue);
    if (isValid(parsedDate)) {
      setIsEditing(inputValue !== value);
    } else {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (dataType == "radio") {
      setCurrentValue(value);
    }
  }, [value]);

  switch (dataType) {
    case "dropdown":
      return (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Autocomplete
            value={currentValue}
            onChange={(event, newValue) => {
              console.log("newValue", newValue);
              handleInputChange(newValue);
            }}
            disabled={loading}
            isOptionEqualToValue={(option, value) => option.Value == value}
            options={options}
            getOptionLabel={(option) => option?.Label || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  minWidth: "280px",
                  maxWidth: "280px",
                }}
                variant="outlined"
                disabled={loading}
              />
            )}
          />
          {isEditing && (
            <Box sx={{ ml: 2 }}>
              <Button
                sx={{
                  background: "transparent",
                  height: "36px",
                  mr: "9px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                disableRipple
                variant="secondary"
                disabled={loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  "&:hover": {
                    background: "rgb(92, 111, 24)",
                  },
                }}
                disableRipple
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={handleSave}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Box>
          )}
        </Box>
      );
    case "radio":
      return (
        <Box
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <RadioGroup
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
            value={currentValue}
            onChange={(e) => handleInputChange(e.target.value)}
          >
            {options?.map((option) => {
              return (
                <FormControlLabel
                  key={option?.Value}
                  disabled={loading}
                  value={option.Value}
                  control={<Radio />}
                  label={option?.Label}
                  sx={{
                    mr: 2,
                    span: {
                      ...commonThemes.normalText,
                      color: isDarkMode ? "#fff" : "rgba(0,0,0,0.6)",
                    },
                  }}
                />
              );
            })}
          </RadioGroup>
          {isEditing && (
            <Box sx={{ ml: 2 }}>
              <Button
                sx={{
                  background: "transparent",
                  height: "36px",
                  mr: "9px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                disableRipple
                variant="secondary"
                disabled={loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  "&:hover": {
                    background: "rgb(92, 111, 24)",
                  },
                }}
                variant="contained"
                color="primary"
                disabled={loading}
                disableRipple
                onClick={handleSave}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Box>
          )}
        </Box>
      );
    case "string":
      return (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            disabled={loading}
            variant="outlined"
            value={currentValue}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
          {isEditing && (
            <Box sx={{ ml: 2 }}>
              <Button
                sx={{
                  background: "transparent",
                  height: "36px",
                  mr: "9px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                disableRipple
                variant="secondary"
                disabled={loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  "&:hover": {
                    background: "rgb(92, 111, 24)",
                  },
                }}
                variant="contained"
                color="primary"
                disabled={loading}
                disableRipple
                onClick={handleSave}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Box>
          )}
        </Box>
      );
    case "integer":
      return (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            disabled={loading}
            variant="outlined"
            type="number"
            value={currentValue}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
          {isEditing && (
            <Box sx={{ ml: 2 }}>
              <Button
                sx={{
                  background: "transparent",
                  height: "36px",
                  mr: "9px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                disableRipple
                variant="secondary"
                disabled={loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  "&:hover": {
                    background: "rgb(92, 111, 24)",
                  },
                }}
                variant="contained"
                color="primary"
                disabled={loading}
                disableRipple
                onClick={handleSave}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Box>
          )}
        </Box>
      );
    case "string_format_dd/MM":
      return (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ReactInputMask
            disabled={loading}
            mask="99/99"
            value={currentValue}
            onChange={onDDMMChange}
          >
            {() => <TextField disabled={loading} variant="outlined" />}
          </ReactInputMask>
          {isEditing && (
            <Box sx={{ ml: 2 }}>
              <Button
                sx={{
                  background: "transparent",
                  height: "36px",
                  mr: "9px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                disableRipple
                variant="secondary"
                disabled={loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  "&:hover": {
                    background: "rgb(92, 111, 24)",
                  },
                }}
                variant="contained"
                color="primary"
                disabled={loading}
                disableRipple
                onClick={handleSave}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Box>
          )}
        </Box>
      );
    case "date":
      return (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              disabled={loading}
              value={dayjs(currentValue)}
              onChange={(date) => {
                const newDate = dayjs(date).format("YYYY-MM-DD");
                const startDate = dayjs("1900-01-01");
                const endDate = dayjs("2099-12-31");
                if (
                  dayjs(newDate).isValid() &&
                  dayjs(newDate).isAfter(startDate) &&
                  dayjs(newDate).isBefore(endDate)
                ) {
                  handleInputChange(newDate);
                } else {
                  setIsEditing(false);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {isEditing && (
            <Box sx={{ ml: 2 }}>
              <Button
                sx={{
                  background: "transparent",
                  height: "36px",
                  mr: "9px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                disableRipple
                variant="secondary"
                disabled={loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  "&:hover": {
                    background: "rgb(92, 111, 24)",
                  },
                }}
                variant="contained"
                color="primary"
                disabled={loading}
                disableRipple
                onClick={handleSave}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </Box>
          )}
        </Box>
      );
  }
};

const SettingLine = ({ setting, onUpdateSettings = () => {}, loading }) => {
  const { isDarkMode } = useTheme();
  return (
    <Box sx={{ mb: 6 }} id={setting.Anchor}>
      <Typography
        sx={{
          color: isDarkMode ? "#FFF" : "#495670",
          fontSize: "20px",
          fontWeight: 500,
          lineHeight: "160%",
          letterSpacing: "0.15px",
        }}
      >
        {setting.Name}
      </Typography>
      <Typography
        sx={{
          mt: 1,
          color: isDarkMode ? "#FFF" : "#252525",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "0.15px",
        }}
        dangerouslySetInnerHTML={{ __html: setting.Description }}
      />
      <InputBasedOnDataType
        onUpdateSettings={onUpdateSettings}
        dataType={setting?.DataType}
        options={setting?.Options}
        value={setting?.Value}
        idCode={setting?.IdCode}
        loading={loading}
      />
    </Box>
  );
};

const Settings = () => {
  const { handleOpen } = useLoginPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { dbId } = useParams();

  const fetchSettings = async () => {
    try {
      const response = await fetch(
        `https://${envConfig.apiDev2}/api/en-au/front-end/get-settings-list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "115.70.37.103",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "api-key":
              getCookie("apiKey") + "-" + (dbId || getCookie("databaseId")),
          },
          body: JSON.stringify({
            Tool0005: "string",
          }),
        },
      );

      if (response.ok) {
        const data = await response.json();
        const dataCopy = JSON.parse(JSON.stringify(data));
        dataCopy.Settings.sort((a, b) => a.Order - b.Order);
        setSettings(dataCopy);
      } else {
        handleOpen();
        enqueueSnackbar("API Error: " + response.status, { variant: "error" });
      }
    } catch (error) {
      handleOpen();
      enqueueSnackbar("Fetch Error: " + error.message, { variant: "error" });
    }
  };

  const onUpdateSettings = async ({ idCode, newValue }) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev2}/api/en-au/front-end/save-updated-settings?BaseHostURL=${envConfig.mainServiceUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "api-key":
              getCookie("apiKey") + "-" + (dbId || getCookie("databaseId")),
          },
          body: JSON.stringify({
            Tool0006: "string",
            Settings: [
              {
                IdCode: idCode,
                NewValue: newValue,
              },
            ],
          }),
        },
      );

      if (response.ok) {
        await fetchSettings();
        setLoading(false);
      } else {
        setLoading(false);
        handleOpen();
        enqueueSnackbar("API Error: " + response.status, { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
      handleOpen();
      enqueueSnackbar("Fetch Error: " + error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  useLayoutEffect(() => {
    if (settings) {
      const hash = window.location.hash;
      if (hash) {
        const anchor = hash.substring(1);
        setTimeout(() => {
          const element = document.getElementById(anchor);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 600);
      }
    }
  }, [settings]);

  return (
    <div>
      <PageTitle title="General Settings" />
      <Box
        sx={{
          borderRadius: "8px",
          boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
          padding: "28px",
        }}
      >
        {settings?.Settings?.map((setting) => (
          <SettingLine
            loading={loading}
            key={setting.IdCode}
            setting={setting}
            onUpdateSettings={onUpdateSettings}
          />
        ))}
      </Box>
    </div>
  );
};

const queryClient = new QueryClient();

const App = () => {
  config({ licenseKey: licenseKey });
  const pca = new PublicClientApplication(msalConfig);

  const prefix = "/:dbId/:lang";

  useEffect(() => {
    document.body.style.visibility = "visible";
    document.body.style.backgroundColor = "";
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <SnackbarProvider maxSnack={8} action={action} autoHideDuration={6000}>
          <BrowserRouter>
            <PopupProvider>
              <AuthProvider>
                <ReportDatesProvider>
                  <>
                    <CssBaseline />
                    <Routes>
                      <Route
                        path={`${prefix}/bank-feeds-linked`}
                        element={
                          <Container>
                            <MainNavigation pageCode="lc-bank-feeds-linked" />
                            <BankFeedsLinked />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/bank-feeds-linked/:token`}
                        element={
                          <Container>
                            <MainNavigation pageCode="lc-bank-feeds=linked" />
                            <BankFeedsLinked />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/my-bank-feeds`}
                        element={
                          <Container>
                            <MainNavigation pageCode="lc-bank-feeds" />
                            <BankFeeds />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/my-bank-feeds/:token`}
                        element={
                          <Container>
                            <MainNavigation pageCode="lc-bank-feeds" />
                            <BankFeeds />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/settings`}
                        element={
                          <Container>
                            <MainNavigation pageCode="lc-settings" />
                            <Settings />
                          </Container>
                        }
                      />

                      <Route
                        path={`${prefix}/books/find-and-update`}
                        element={
                          <Container>
                            <MainNavigation pageCode="lc-find-and-update" />
                            <FindAndRedo />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/books/find-and-update`}
                        element={
                          <Container>
                            <MainNavigation pageCode="lc-find-and-update" />
                            <FindAndRedo />
                          </Container>
                        }
                      />

                      <Route
                        path={`${prefix}/dashboard/create`}
                        element={
                          <Container>
                            <MainNavigation pageCode="gl-dashboard-create-business-file" />
                            <CreateBusinessAccount />
                          </Container>
                        }
                      />

                      <Route
                        path={`${prefix}/dashboard/create-subscriber`}
                        element={
                          <Container>
                            <MainNavigation pageCode="gl-dashboard-create-subscriber" />
                            <CreateBusinessAccount
                              isCreatingSubscriber={true}
                            />
                          </Container>
                        }
                      />

                      <Route
                        path={`${prefix}/dashboard`}
                        element={
                          <Container>
                            <MainNavigation pageCode="gl-dashboard" />
                            <Dashboard />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/authorization/validation/:sessionGuid`}
                        element={
                          <>
                            <Container>
                              {/* <MainNavigation pageCode="gl-authorization-validation" /> */}
                              <SessionManagement />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/reports/profit-loss`}
                        element={
                          <>
                            <Container maxWidth={1664}>
                              <MainNavigation pageCode="lc-profit-loss" />
                              <ProfitAndLoss />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/reports/balance-sheet`}
                        element={
                          <>
                            <Container maxWidth={1664}>
                              <MainNavigation pageCode="lc-balance-sheet" />
                              <BalanceSheet />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/reports/general-ledger`}
                        element={
                          <Container maxWidth={1664}>
                            <MainNavigation pageCode="lc-general-ledger" />
                            <GeneralLedger />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/mfa-setup`}
                        element={
                          <Container maxWidth={1664}>
                            <MainNavigation pageCode="gl-user-2fa-setup" />
                            <TwoFactorSetup />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/books/tpar-batches`}
                        element={
                          <Container maxWidth={1664}>
                            <MainNavigation pageCode="lc-bas" />
                            <TPARBatches />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/reports/bas`}
                        element={
                          <Container maxWidth={1664}>
                            <MainNavigation pageCode="lc-bas" />
                            <BusinessActivityStatement />
                          </Container>
                        }
                      />
                      <Route
                        path={`${prefix}/reports`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-reports" />
                              <ReportsDashboard />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/reports/:reportName`}
                        element={
                          <Container maxWidth={1664}>
                            <MainNavigation pageCode="lc-bas" />
                            <CustomReports />
                          </Container>
                        }
                      />

                      <Route
                        path={`${prefix}/MSAuthentication`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="gl-ms-callback" />

                              <MsalProvider instance={pca}>
                                <MSAuthentication />
                              </MsalProvider>
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`/callback-ms365`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="gl-ms-callback" />
                              <Callback />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/callback-ms365`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="gl-ms-callback" />
                              <Callback />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/error`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="gl-error" />
                              <ErrorPage />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path="codes"
                        element={
                          <Box sx={{ mx: 3 }}>
                            <MainNavigation pageCode="gl-codes" />
                            <NewCodes />
                          </Box>
                        }
                      />
                      <Route
                        path={`${prefix}/mobile/dashboard`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-mobile-dashboard" />
                              <DashboardMobile />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/books/general-journal/:journalId`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-general-journal" />
                              <GeneralJournalPage />
                            </Container>
                          </>
                        }
                      />
                      {/* <Route
                        path={`${prefix}/payroll/new-pay-event`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-new-pay-event" />
                              <NewPayEventPage />
                            </Container>
                          </>
                        }
                      /> */}
                      <Route
                        path={`${prefix}/books/chart-of-accounts`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-accounts-list" />
                              <ChartOfAccountsPage />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/books/general-journal`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-general-journal" />
                              <GeneralJournalPage />
                            </Container>
                          </>
                        }
                      />

                      <Route
                        path={`${prefix}/books/bank-journal/:journalId`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-bank-journal" />
                              <BankJournalPage />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/books/bank-journal`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-bank-journal" />
                              <BankJournalPage />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/books/tax-codes`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-tax-codes" />
                              <TaxCodesPage />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/books/import-transactions-from-csv`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="lc-tax-codes" />
                              <ImportTransactionsFromCSVPage />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path={`${prefix}/*`}
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="gl-404" />
                              <Page404 />
                            </Container>
                          </>
                        }
                      />
                      <Route
                        path="*"
                        element={
                          <>
                            <Container>
                              <MainNavigation pageCode="gl-404" />
                              <Page404 />
                            </Container>
                          </>
                        }
                      />
                    </Routes>
                  </>
                </ReportDatesProvider>
              </AuthProvider>
            </PopupProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
