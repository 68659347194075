import { parse, isValid } from "date-fns";
import DataGrid from "devextreme-react/cjs/data-grid";
import dxDataGrid from "devextreme/ui/data_grid";

export const DATE_COLUMN = "Date";
export const ACCOUNT_COLUMN = "Account";
export const TAX_CODE_COLUMN = "Tax Code";
export const DESCRIPTION_COLUMN = "Description";
export const CONTACT_COLUMN = "Contact Code";
export const SITE_COLUMN = "Site Code / Name";
export const AMOUNT1_COLUMN = "+ Debit / - Credit";
export const AMOUNT2_COLUMN = "- Debit / + Credit";
export const AMOUNT3_COLUMN = "Debit / Expense";
export const AMOUNT4_COLUMN = "Credit / Income";
export const PRIV_PORT_COLUMN = "Priv Port 1..99";
export const BANK_R1_COLUMN = "Bank Rule - Search Text";
export const BANK_R2_COLUMN = "Bank Rule - Ranking";
export const BANK_R3_COLUMN = "Bank Rule – Minimum Amount";
export const BANK_R4_COLUMN = "Bank Rule - Maximum Amount";

const supportedDateFormats = [
  "dd/MM/yyyy",
  "d/M/yyyy",
  "d/MM/yyyy",
  "dd/M/yyyy",
  "dd-MM-yyyy",
  "d-M-yyyy",
  "dd-MM-yy",
  "dd-MM-yyyy",
  "d-MMM-yyyy",
  "dd.MM.yyyy",
  "dd.MM.yy",
  "d.M.yy",
  "dd-MMM-yy",
  "d-MMM-yy",
  "d/MM/yy",
  "d/MM/yy",
];

export const isDate = (cell: string) => {
  // Trim the input string to remove any leading/trailing whitespace
  const trimmedCell = cell.trim();

  // TODO: Probably delete this check - empty seems to be valid
  // Check if the trimmed string is empty
  if (trimmedCell.length === 0) {
    return false;
  }

  // Try parsing the string with each supported format
  for (const format of supportedDateFormats) {
    try {
      const parsedDate = parse(trimmedCell, format, new Date());
      if (isValid(parsedDate)) {
        return true;
      }
    } catch (error) {
      continue;
    }
  }
  return false;
};

const isNumeric = (cell: string): boolean => {
  const cleanedValue = cell.replace(/,/g, "");
  if (cleanedValue === "") return false;
  const number = Number(cleanedValue);
  return !isNaN(number) && isFinite(number) && number !== 0;
};

const isPositiveInteger = (n: string) => {
  return Number(n) >>> 0 === parseFloat(n);
};

const isNumericOnly = (str) => {
  return !/\D/.test(str);
};

const isStringNumber = (cell: string) => {
  return !isNaN(Number(cell));
};

const isDescription = (cell: string) => {
  if (isStringNumber(cell)) return false;
  return cell.length > 5;
};

// temporary to check if the cell is an account
const isAccount = (cell: string, accountList: string[]) => {
  return true; // TODO: implement the check
};

// temporary to check if the cell is a tax code
const isTaxCode = (cell: string, taxCodes: string[]) => {
  return true; // TODO: implement the check
};

const detectColumnName = (detectedColumns, length, maxCount) => {
  const result = new Array(length).fill("n/a");
  result[0] = "RowNo";

  const columnMap = new Map(
    detectedColumns
      .filter((item) => item.count > maxCount / 2)
      .map((item) => [item.index + 1, item.column]),
  );

  // Replace "n/a" with actual column names where applicable
  for (let i = 1; i < length; i++) {
    if (columnMap.has(i)) {
      result[i] = columnMap.get(i);
    }
  }

  return result;
};

export const getHeadersFromCSV = ({
  hasHeader,
  parsedData,
  headersList,
}: {
  hasHeader: boolean;
  parsedData: string[][];
  headersList: string[];
}): string[] => {
  let headers: string[];

  console.log("[DEBUG] getHeadersFromCSV", hasHeader, parsedData, headersList);

  if (hasHeader) {
    return [];
  } else {
    headers = parsedData[0].map((rowCell, rowIndex) => "n/a");
  }

  return headers;
};

// TODO: needs to returns the headers, columns, and rows
export const parseTranscationsCSV = ({
  data,
  isFirstRowHeader = false,
  dataGridAPI,
}: {
  data: string[][];
  isFirstRowHeader?: boolean;
  dataGridAPI: DataGrid | null;
}) => {
  let totalRowCount = 0; // can be replaced

  const allRows = new Array();
  const invalidCSVRows = new Array();
  const detectedColumns = new Array();

  for (let row of data) {
    totalRowCount++;

    let isValidRow = false;
    let rowHasDate = false;
    let rowHasAccountNumber = false;
    let rowHasNumericValue = false;

    let possibleDescColIndex = -1;
    let rowLongestDescriptionLen = 0;

    console.log("ROW: ", row);

    if (Array.isArray(row)) {
      for (let i = 0; i < row.length; i++) {
        const cell = row[i];

        // TODO: parse the value?
        let cellValue = cell.trim();

        if (!rowHasDate && cellValue) {
          const hasDate = isDate(cellValue);
          if (hasDate) {
            const dateColIndex = detectedColumns.findIndex(
              (colObj) => colObj.column === DATE_COLUMN,
            );

            if (dateColIndex > -1) {
              detectedColumns[dateColIndex].count++;
            } else {
              detectedColumns.push({
                index: i,
                column: DATE_COLUMN,
                count: 1,
              });
            }

            rowHasDate = true;
            continue;
          }
        }

        let isNumericValue = isNumeric(cellValue);
        let numericStrValue = cellValue.replace(/,/g, "");

        if (isNumericValue && !rowHasNumericValue) {
          rowHasNumericValue = true;
        }

        if (
          isPositiveInteger(numericStrValue) &&
          parseInt(numericStrValue) > 0 &&
          isNumericOnly(numericStrValue) && // meaning is an Account (4 digits)
          cellValue.length === 4
        ) {
          console.log("NUMERIC STR VALUE: ", numericStrValue);

          const isAccountValue = isAccount(numericStrValue, []);

          if (isAccountValue) {
            const accountColIndex = detectedColumns.findIndex(
              (colObj) => colObj.column === ACCOUNT_COLUMN,
            );

            if (accountColIndex > -1) {
              detectedColumns[accountColIndex].count++;
            } else {
              detectedColumns.push({
                index: i,
                column: ACCOUNT_COLUMN,
                count: 1,
              });
            }

            rowHasAccountNumber = true;
            continue;
          }
        }

        if (
          cellValue.length > 0 &&
          cellValue.length <= 4 &&
          !isNumeric(cellValue)
        ) {
          const isTaxCodeValue = isTaxCode(cellValue, []);

          if (isTaxCodeValue) {
            const taxCodeColIndex = detectedColumns.findIndex(
              (colObj) => colObj.column === TAX_CODE_COLUMN,
            );

            if (taxCodeColIndex > -1) {
              detectedColumns[taxCodeColIndex].count++;
            } else {
              detectedColumns.push({
                index: i,
                column: TAX_CODE_COLUMN,
                count: 1,
              });
            }

            continue;
          }
        }

        if (
          cellValue.length > 0 &&
          !isNumeric(cellValue) &&
          cellValue.length > rowLongestDescriptionLen
        ) {
          possibleDescColIndex = i;
          rowLongestDescriptionLen = cellValue.length;

          const descriptionColIndex = detectedColumns.findIndex(
            (colObj) => colObj.column === DESCRIPTION_COLUMN,
          );

          if (descriptionColIndex > -1) {
            detectedColumns[descriptionColIndex].count++;
          } else {
            detectedColumns.push({
              index: possibleDescColIndex,
              column: DESCRIPTION_COLUMN,
              count: 1,
            });
          }
        }
      }

      if (rowHasDate || rowHasNumericValue) {
        isValidRow = true;
        allRows.push(row);
      } else {
        isValidRow = false;
        invalidCSVRows.push(row);
      }

      row.unshift(`${totalRowCount}`);

      console.log("[READ CSV] ------------------------------------");
      console.log("[READ CSV] ------------------------------------");
    }
    console.log("[READ CSV] ====================================");
    console.log("[READ CSV] detected columns: ", detectedColumns);
    // console.log("[READ CSV] total row count: ", totalRowCount);
    console.log("[READ CSV] ====================================");
  }
  //

  return {
    totalRowCount: totalRowCount,
    totalValidRowCount: totalRowCount - invalidCSVRows.length,
    totalInvalidRowCount: invalidCSVRows.length,
    allRows,
    invalidCSVRows,
    detectedColumns,
    headers: detectColumnName(
      detectedColumns,
      allRows[0].length,
      totalRowCount,
    ),
  };
};

export const isAmountValidRow = (
  row: string[],
  wtd1Index,
  wtd2Index,
  debitIndex,
  crdtIndex,
) => {
  if ((debitIndex >= 0 || crdtIndex >= 0) && wtd1Index <= 0 && wtd2Index <= 0) {
    const debitValue = row[debitIndex];
    const creditValue = crdtIndex >= 0 ? row[crdtIndex] : 0;

    console.log(
      "[OVERALL]---- debitValue: ",
      debitValue,
      " creditValue: ",
      creditValue,
    );

    if (Number(debitValue) >= 0 && Number(creditValue) >= 0) {
      return true;
    }
  }

  if (wtd1Index >= 0 && wtd2Index <= 0 && debitIndex <= 0 && crdtIndex <= 0) {
    const wtd1Value = row[wtd1Index];

    if (Number(wtd1Value) !== 0) {
      return true;
    }
  }

  if (wtd2Index >= 0 && wtd1Index <= 0 && debitIndex <= 0 && crdtIndex <= 0) {
    const wtd2Value = row[wtd2Index];

    if (Number(wtd2Value) !== 0) {
      return true;
    }
  }

  return false;
};

export const isPrivPortValidRow = (row: string[], privIndex) => {
  if (privIndex >= 0) {
    const privValue = row[privIndex];

    if (
      isNumeric(privValue) &&
      Number(privValue) >= 0 &&
      Number(privValue) < 100
    ) {
      return true;
    }
  }

  return false;
};

export const cleanArrays = (
  rows: string[][],
  headers: string[],
): [string[][], string[]] => {
  const validIndices = headers.reduce<number[]>((indices, header, index) => {
    if (header !== "n/a") {
      indices.push(index);
    }
    return indices;
  }, []);

  // Clean the headers
  const cleanedHeaders = validIndices.map((i) => headers[i]);

  // Clean the data arrays
  const cleanedRows = rows.map((row) => validIndices.map((i) => row[i]));

  return [cleanedRows, cleanedHeaders];
};
