const { origin } = window.location;

const mainServiceUrl = origin.replace(/^https:\/\/(www\.)?/, "");

const envConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  olivsRootPassword: process.env.REACT_APP_OLIVS_ROOT_PASSWORD,
  mainServiceUrl,
  apiDev1: origin.includes(".app")
    ? "webservice02002.olivs.cloud"
    : origin.includes(".ssx.")
      ? "dev1v-api.ssx.au"
      : "dev1.olivs.com",
  apiDev2: origin.includes(".app")
    ? "tmwebservice.olivs.cloud"
    : origin.includes(".ssx.")
      ? "dev2v-api.ssx.au"
      : "dev2.olivs.com",
  apiDev1Exacc: origin.includes(".app")
    ? "webservice01003.olivs.cloud"
    : origin.includes(".ssx.")
      ? "dev1z-api.ssx.au"
      : "api-dev1v.exacc.au",
  apiLogin: origin.includes(".app")
    ? "login-api.olivs.app"
    : origin.includes(".ssx.")
      ? "login-api.ssx.au"
      : "login-api.olivs.com",
};

if (mainServiceUrl === "s8.olivs.com") {
  envConfig.loginUrl = "login.olivs.com";
  envConfig.correspondingService = "s1.olivs.com";
} else if (/^s\d+\.exacc\.au$/.test(mainServiceUrl)) {
  const number = mainServiceUrl.match(/^s(\d+)\.exacc\.au$/)[1];
  envConfig.loginUrl = "my.exacc.au";
  envConfig.correspondingService = `s${number}v.exacc.au`;
} else if (/^s\d+\.olivs\.app$/.test(mainServiceUrl)) {
  const number = mainServiceUrl.match(/^s(\d+)\.olivs\.app$/)[1];
  envConfig.loginUrl = "my.olivs.app";
  envConfig.correspondingService = `s${number}v.olivs.app`;
} else if (/^s\d+\.ssx\.au$/.test(mainServiceUrl)) {
  const number = mainServiceUrl.match(/^s(\d+)\.ssx\.au$/)[1];
  envConfig.loginUrl = "my.ssx.au";
  envConfig.correspondingService = `s${number}v.ssx.au`;
} else {
  envConfig.loginUrl = process.env.REACT_APP_LOGIN_URL;
  envConfig.correspondingService = process.env.REACT_APP_CORRESPONDING_SERVICE;
}

export default envConfig;
