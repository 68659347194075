import React, { useEffect } from "react";
import dayjs from "dayjs";

import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import PageTitle from "../../components/PageTitle/PageTitle";
import { BASFiltersPanel } from "../../features/reports/business-activity/components/bas-filters-panel";
import { BASFiltersModal } from "../../features/reports/business-activity/components/bas-filters-modal";
import { BASTable } from "../../features/reports/business-activity/components/bas-table";
import { useBASInitialSettings } from "../../features/reports/business-activity/api/get-initial-settings";
import {
  useBASStore,
  useBASStoreBase,
} from "../../features/reports/business-activity/store/bas";
import { formatDateToISO } from "../../utils/format-date";
import { useGetBASReports } from "../../features/reports/business-activity/api/get-bas-reports";
import { BASReportsGridRS1RequestBody } from "../../types/api/business-activity";
import { boolToYN } from "../../utils/format-bool";
import { enqueueSnackbar } from "notistack";

function BusinessActivityStatement() {
  const [isModalOpen, setIsModalOpen] = React.useState(true);

  const { data, isError, error, isPending, isSuccess } = useBASInitialSettings(
    {},
  );
  const {
    data: basData,
    isPending: basIsPending,
    mutate: getBASReports,
  } = useGetBASReports({
    mutationConfig: {
      onError: (error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
      },
    },
  });

  const state = useBASStoreBase((state) => state);
  const setState = useBASStore.use.setState();
  const setStateFromResponse = useBASStore.use.setStateFromResponse();
  const setPrevState = useBASStore.use.setPrevState();

  const handleOnOpen = () => setIsModalOpen(true);
  const handleOnClose = () => setIsModalOpen(false);

  const handleRestorePrevState = () => {
    if (state?.prevState) {
      setState(state.prevState);
    }
  };

  const handleApplyFilters = () => {
    console.log("Applying Filters");
    console.log("STATE: ", state);

    const requestBody: BASReportsGridRS1RequestBody = {
      // Acc004
      AccountingMethodCA: state.accountingMethodCA,
      GstEnd: formatDateToISO(state.endDate?.toDate()),
      GstStart: formatDateToISO(state.gstStartDate?.toDate()),
      PrlStart: formatDateToISO(state.paygStartDate?.toDate()),
      PrlEnd: formatDateToISO(state.endDate?.toDate()),
      PaygInstalment: 0,
      ShowPrintDetsYN: boolToYN(state.showFilters.showPrintDetailsYN),
    };

    getBASReports(requestBody);
    setPrevState(state);
  };

  useEffect(() => {
    if (isSuccess) {
      const stateFromResponse = {
        accountingMethodCA: data?.AccountingMethodCA,
        endDate: dayjs(formatDateToISO(data?.EndDate)),
        gstCycle: data?.GstCycle,
        gstPeriod: data?.GstPeriod,
        gstStartDate: dayjs(formatDateToISO(data?.GstStartDate)),
        paygCycle: data?.PaygCycle,
        paygPeriod: data?.PaygPeriod,
        paygStartDate: dayjs(formatDateToISO(data?.PaygStartDate)),
      };

      setStateFromResponse(stateFromResponse);
      setPrevState({
        ...stateFromResponse,
        showFilters: { ...state.showFilters },
        unlockSettings: state.unlockSettings,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  });

  return (
    <>
      <BackdropLoading open={isPending || basIsPending} />
      <PageTitle
        title="Business Activity Statement Report"
        displayBackIcon={null}
        onBack={null}
      />
      <BASFiltersModal
        isModalOpen={isModalOpen}
        onClose={handleOnClose}
        onCancelCallback={handleRestorePrevState}
        onApplyFilters={handleApplyFilters}
      />
      {/* <BASFiltersPanel
        onApplyFilters={handleApplyFilters}
        onOpenModal={handleOnOpen}
      /> */}
      <BASTable
        data={basData?.DataDT}
        columns={basData?.ColumnsList}
        endDate={state.endDate}
        onModalOpen={handleOnOpen}
        onRefresh={handleApplyFilters}
      />
    </>
  );
}

export default BusinessActivityStatement;
