import {
  addMonths,
  addQuarters,
  addYears,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  endOfDay,
} from "date-fns";
import dayjs from "dayjs";

export const formatDate = (date: Date | string | null | undefined): string => {
  if (!date) {
    return "";
  }

  let d: Date;

  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

// TODO: [IMPLEMENT] formatRangeDate
export const formatRangeDate = (date: Date | string | null) => {};

type PeriodType =
  | "current_month"
  | "previous_month"
  | "current_quarter"
  | "previous_quarter"
  | "current_financialYear"
  | "previous_financialYear"
  | "current_calendarYear"
  | "previous_calendarYear"
  | "custom";

export function getPredefinedDates(
  periodType: PeriodType,
): [dayjs.Dayjs, dayjs.Dayjs] | undefined {
  if (periodType === "custom") {
    return undefined;
  }

  let startDate: Date;
  let endDate: Date;
  const currentDate = new Date();
  const isCurrent = periodType.startsWith("current_");
  const isPrevious = periodType.startsWith("previous_");
  const actualPeriodType = periodType.split("_").pop();

  switch (actualPeriodType) {
    case "month":
      startDate = isCurrent
        ? startOfMonth(currentDate)
        : startOfMonth(addMonths(currentDate, -1));
      endDate = isCurrent
        ? endOfMonth(currentDate)
        : endOfMonth(addMonths(currentDate, -1));
      break;
    case "quarter":
      startDate = isCurrent
        ? startOfQuarter(currentDate)
        : startOfQuarter(addQuarters(currentDate, -1));
      endDate = isCurrent
        ? endOfQuarter(currentDate)
        : endOfQuarter(addQuarters(currentDate, -1));
      break;
    case "financialYear":
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      if (currentMonth >= 6) {
        startDate = isCurrent
          ? new Date(currentYear, 6, 1)
          : new Date(currentYear - 1, 6, 1);
        endDate = isCurrent
          ? new Date(currentYear + 1, 5, 30)
          : new Date(currentYear, 5, 30);
      } else {
        startDate = isCurrent
          ? new Date(currentYear - 1, 6, 1)
          : new Date(currentYear - 2, 6, 1);
        endDate = isCurrent
          ? new Date(currentYear, 5, 30)
          : new Date(currentYear - 1, 5, 30);
      }
      break;
    case "calendarYear":
      startDate = isCurrent
        ? startOfYear(currentDate)
        : startOfYear(addYears(currentDate, -1));
      endDate = isCurrent
        ? endOfYear(currentDate)
        : endOfYear(addYears(currentDate, -1));
      break;
    default:
      throw new Error("Unsupported period type");
  }

  const formattedStartDate = new Date(
    Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
    ),
  )
    .toISOString()
    .split("T")[0];
  const formattedEndDate = new Date(
    Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
  )
    .toISOString()
    .split("T")[0];

  const dayjsStartDate = dayjs(formattedStartDate);
  const dayjsEndDate = dayjs(formattedEndDate);

  return [dayjsStartDate, dayjsEndDate];
}

export const formatDateToISO = (
  date: Date | string | null | undefined,
): string => {
  if (!date) {
    return "";
  }

  let d: Date;

  if (typeof date === "string" && checkIfParamDate(date)) {
    d = getDateFromParamDate(date) || new Date(date);
  } else if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getDateMatchFromParamDate = (
  dateStr: string,
): RegExpMatchArray | null => {
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{2})$/;
  return dateStr.match(dateRegex);
};

export const checkIfParamDate = (dateStr: string): boolean => {
  return getDateMatchFromParamDate(dateStr) ? true : false;
};

export const getDateFromParamDate = (dateStr: string): Date | null => {
  const match = getDateMatchFromParamDate(dateStr);

  if (!match) {
    return null;
  }

  // Extract day, month, and year from the match
  const day = parseInt(match[1], 10);
  const month = parseInt(match[2], 10) - 1; // Month is 0-based in JavaScript
  const year = parseInt(match[3], 10);

  // Handling the year to assume 20th or 21st century
  // This assumes any year '00' to '49' is 2000 to 2049,
  // and any year '50' to '99' is 1950 to 1999.
  const fullYear = year + (year < 50 ? 2000 : 1900);

  // Construct a new Date object
  return new Date(fullYear, month, day);
};

/**
 * Converts a simple date string to a Day.js object.
 *
 * @param dateString - The date string in the format "dd/MM/year".
 * @returns A Day.js object representing the converted date.
 */
export const convertSimpleDateToDayjs = (dateString: string) => {
  const [day, month, year] = dateString.split("/");
  return dayjs(`${year}-${month}-${day}`);
};

/**
 * Checks if a given string from the backend in 2022-09-30T00:00:00 format is a valid date string.
 *
 * @param dateString - The string to be checked.
 * @returns A boolean indicating whether the string is a valid date string.
 */
export function isValidDateString(dateString: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  return regex.test(dateString);
}

export type EndDatePeriodType =
  | "today"
  | "end_of_previous_month"
  | "end_of_previous_quarter"
  | "end_of_previous_year"
  | "custom";

export const getEndDatePeriodDate = (periodType: EndDatePeriodType) => {
  if (periodType === "custom") {
    return undefined;
  }

  let endDate;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  if (periodType === "today") {
    endDate = endOfDay(currentDate);
  } else if (periodType === "end_of_previous_month") {
    endDate = endOfMonth(addMonths(currentDate, -1));
  } else if (periodType === "end_of_previous_quarter") {
    endDate = endOfQuarter(addQuarters(currentDate, -1));
  } else if (periodType === "end_of_previous_year") {
    endDate = endOfYear(addYears(currentDate, -1));
  }

  if (!endDate) return;

  const formattedDate = new Date(
    Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
  )
    .toISOString()
    .split("T")[0];

  const dayjsDate = dayjs(formattedDate);
  return dayjsDate;
};
