import { Box, Button } from "@mui/material";
import React from "react";
import { DownloadButton } from "../../../../components/ui/buttons/download-button";
import { FullScreenButton } from "../../../../components/ui/buttons/fullscreen-button";
import { PDFButton } from "../../../../components/ui/buttons/pdf-button";
import { RefreshButton } from "../../../../components/ui/buttons/refresh-button";
import { SearchInput } from "../../../../components/ui/inputs/search-input";
import GridDownloadExcelPdfButtons from "../../../../components/GridDownloadExcelPdfButtons/GridDownloadExcelPdfButtons";

interface GeneralLedgerDataGridToolbarProps {
  isCollapsed: boolean;
  onCollapse: () => void;
  onRefresh: () => void;
  onExcel: () => void;
  onPDF: () => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClear?: () => void;
  onFullScreen: () => void;
}

export const GeneralLedgerDataGridToolbar = ({
  isCollapsed,
  onCollapse,
  onRefresh,
  onExcel,
  onPDF,
  onSearchChange,
  onSearchClear,
  onFullScreen,
}: GeneralLedgerDataGridToolbarProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      gap={3}
      sx={{ marginBottom: 3, marginTop: 1 }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={3}
        flexWrap={"wrap"}
      >
        <Button
          // @ts-ignore
          variant="secondary"
          sx={{ fontWeight: 700 }}
          onClick={onCollapse}
        >
          {!isCollapsed ? "Expand All" : "Collapse All"}
        </Button>
        <RefreshButton title={"Refresh"} onClick={onRefresh} />
        <GridDownloadExcelPdfButtons
          downloadExcel={onExcel}
          downloadPDF={onPDF}
          displayDownloadPDF={true}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={3}
        flexWrap={"wrap"}
      >
        <SearchInput onChange={onSearchChange} onClear={onSearchClear} />
        <FullScreenButton onClick={onFullScreen} />
      </Box>
    </Box>
  );
};
