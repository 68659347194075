import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import envConfig from "../../../../config";
import { getHeaders } from "../../../../utils/api";
import { useParams } from "react-router-dom";

interface ColumnsHeaderListResponse {
  SuccessYN: string;
  ErrorMessage: string;
  ListOfColumns: string[];
}

const EXAMPLE_COLUMNS_HEADER_LIST_RESPONSE: ColumnsHeaderListResponse = {
  SuccessYN: "Y",
  ErrorMessage: "",
  ListOfColumns: [
    "n/a",
    "Date",
    "Account",
    "Tax Code",
    "Description",
    "Debit / Expense",
    "Credit / Income",
    "+ Debit / - Credit",
    "- Debit / + Credit",
    "Reference",
    "Priv Port 1..99",
    "Contact Code",
    "Site Code / Name",
    "Bank Rule - Search Text",
    "Bank Rule – Ranking",
    "Bank Rule – Minimum Amount",
    "Bank Rule – Maximum Amount",
  ],
};

export const GET_COLUMNS_HEADER_LIST_KEY = "GET_COLUMNS_HEADER_LIST_KEY";

export const getColumnsHeaderList =
  async (dbId): Promise<ColumnsHeaderListResponse> => {
    const headers = getHeaders(dbId);
    const api = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-entries-import/get-importing-columns-header-list?BaseHostURL=${envConfig.mainServiceUrl}`;

    if (process.env.NODE_ENV === "development") {
      return EXAMPLE_COLUMNS_HEADER_LIST_RESPONSE;
    }

    const response = await fetch(api, {
      method: "GET",
      // @ts-ignore
      headers: {
        ...headers,
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to get Columns Header List: ${response.statusText}`,
      );
    }

    const result = await response.json();

    if (result?.SuccessYN === "Y" || result?.successYN === "Y") {
      return result;
    } else {
      throw new Error(
        `Failed to return Columns Header List: ${JSON.stringify(result)}`,
      );
    }
  };

type UseGetColumnsHeaderList = {
  queryOptions?: Omit<
    UseQueryOptions<
      ColumnsHeaderListResponse,
      Error,
      ColumnsHeaderListResponse
    >,
    "queryKey" | "queryFn"
  >;
};

export const useColumnsHeaderList = ({
  queryOptions = {},
}: UseGetColumnsHeaderList = {}) => {
  const { dbId } = useParams();

  return useQuery({
    ...queryOptions,
    queryKey: [GET_COLUMNS_HEADER_LIST_KEY],
    queryFn: () => getColumnsHeaderList(dbId),
  });
};
