import dayjs from "dayjs";
import { create } from "zustand";
import { createSelectors } from "../../../../utils/createSelectors";

type IDate = dayjs.Dayjs | null;

type ShowFilters = {
  showPrintDetailsYN: boolean;
};

interface DefaultState {
  gstStartDate: IDate;
  paygStartDate: IDate;
  endDate: IDate;
  accountingMethodCA: "C" | "A";
  gstPeriod: string;
  paygPeriod: string;
  gstCycle: string | "A" | "Q" | "M" | "";
  paygCycle: string | "A" | "Q" | "M" | "";
  showFilters: ShowFilters;
  unlockSettings: boolean;
}

interface State extends DefaultState {
  prevState?: DefaultState;
}

interface StateFromResponse extends Omit<DefaultState, "unlockSettings" | "showFilters"> {}

interface Actions {
  setGstStartDate: (gstStartDate: IDate) => void;
  setPaygStartDate: (paygStartDate: IDate) => void;
  setEndDate: (endDate: IDate) => void;
  setAccountingMethodCA: (accountingMethodCA: "C" | "A") => void;
  setGstPeriod: (gstPeriod: string) => void;
  setPaygPeriod: (paygPeriod: string) => void;
  setGstCycle: (gstCycle: string) => void;
  setPaygCycle: (paygCycle: string) => void;
  setShowFilterByType: (
    filterType: keyof ShowFilters,
    checked: boolean,
  ) => void;
  setUnlockSettings: (unlockSettings: boolean) => void;
  setState: (state: DefaultState) => void;
  setStateFromResponse: (state: StateFromResponse) => void;
  setPrevState: (prevState: DefaultState) => void;
}

export const useBASStoreBase = create<State & Actions>()((set) => ({
  // STATE
  gstStartDate: null,
  paygStartDate: null,
  endDate: null,
  accountingMethodCA: "A",
  gstPeriod: "",
  paygPeriod: "",
  gstCycle: "",
  paygCycle: "",
  showFilters: {
    showPrintDetailsYN: false,
  },
  unlockSettings: false,
  prevState: undefined,

  // ACTIONS
  setGstStartDate: (gstStartDate) => set({ gstStartDate }),
  setPaygStartDate: (paygStartDate) => set({ paygStartDate }),
  setEndDate: (endDate) => set({ endDate }),
  setAccountingMethodCA: (accountingMethodCA) => set({ accountingMethodCA }),
  setGstPeriod: (gstPeriod) => set({ gstPeriod }),
  setPaygPeriod: (paygPeriod) => set({ paygPeriod }),
  setGstCycle: (gstCycle) => set({ gstCycle }),
  setPaygCycle: (paygCycle) => set({ paygCycle }),
  setShowFilterByType: (filterType, checked) =>
    set((state) => ({
      showFilters: {
        ...state.showFilters,
        [filterType]: checked,
      },
    })),
  setUnlockSettings: (unlockSettings) => set({ unlockSettings }),
  setState: (state) => set(state),
  setStateFromResponse: (state) => set(state),
  setPrevState: (prevState) => set({ prevState }),
}));

export const useBASStore = createSelectors(useBASStoreBase);
