import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ImportTranscationsFromCSVValidationHeadersModalProps {
  validationStatus: any;
  isModalOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export const ImportTranscationsFromCSVValidationHeadersModal = ({
  validationStatus,
  isModalOpen,
  onClose,
  onContinue,
}: ImportTranscationsFromCSVValidationHeadersModalProps) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      sx={{
        ">div>div": { maxWidth: "xl" },
      }}
    >
      <DialogTitle textAlign={"center"}>Invalid header selection!</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Box textAlign={"center"}>
          {validationStatus === "NO_AMOUNT_OR_DATE"
            ? `To import transactions, you must provide at least "Date" and "Amount".`
            : null}
          {validationStatus === "NO_BANK_SEARCH"
            ? `Search Text for bank rules is required.`
            : null}
          {validationStatus === "NO_ACC_OR_TAX" ? (
            <>
              <Box>
                You have selected Account or Tax Code but not the other.
              </Box>
              <Box>Manual processing will be required</Box>
            </>
          ) : null}
        </Box>
        <DialogActions sx={{ pt: 0, mt: 5 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyItems={"center"}
            alignItems={"center"}
            gap={2}
            width={"100%"}
          >
            {validationStatus === "NO_ACC_OR_TAX" ? (
              <Button
                onClick={onContinue}
                // @ts-ignore
                variant="secondary"
              >
                Continue
              </Button>
            ) : null}
            <Button
              onClick={onClose}
              // @ts-ignore
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
