import PageTitle from "../PageTitle/PageTitle";
import { useNavigate } from "react-router-dom";
import { Box, Button, Menu, Typography, useMediaQuery } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid-pro";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import OverflowTip from "../OverflowTip/OverflowTip";
import DataGrid, {
  Column,
  Grouping,
  Scrolling,
  Sorting,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import { useState } from "react";

const ThreeDots = ({ data, handleActivate, handleDeactivate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <GridMoreVertIcon
        sx={{ cursor: "pointer" }}
        color="primary"
        onClick={handleClick}
      />
      <Menu
        sx={{ ".MuiPaper-root": { px: "10px !important" } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <RenderButton
          params={data}
          handleActivate={handleActivate}
          handleDeactivate={handleDeactivate}
          accountId={data.bfaID || data.data?.bfaID}
          connectionId={
            data.ConnectionId ||
            data.data?.ConnectionId ||
            data.bfaConnectionId ||
            data.data?.bfaConnectionId
          }
          active={data.data?.bfaLinkStatus === "Linked"}
          isInstitutionName={data.data?.isInstitutionName}
        />
      </Menu>
    </div>
  );
};

const getColumnsForMain = ({ isMobile, handleActivate, handleDeactivate }) =>
  isMobile
    ? [
        {
          dataField: "empty",
          caption: "",
          width: 50,
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => {
            if (typeof data.data?.id !== "string") {
              return (
                <ThreeDots
                  data={data}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                />
              );
            }
          },
        },
        {
          dataField: "bfaCustomName",
          caption: "",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaMobileColumn",
          caption: "",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
      ]
    : [
        {
          dataField: "empty",
          caption: "",
          width: 50,
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => {
            if (typeof data.data?.id !== "string") {
              return (
                <ThreeDots
                  data={data}
                  handleActivate={handleActivate}
                  handleDeactivate={handleDeactivate}
                />
              );
            }
          },
        },
        {
          dataField: "bfaCustomName",
          caption: "",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaAccountName",
          caption: "Name",
          minWidth: 250,
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaAccountDisplay",
          caption: "BSB and Number",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaLastUpdatedDate",
          caption: "Last update",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => {
            const dateTimeString = data.value;
            const [date, time] = dateTimeString.split("T");

            return <OverflowTip value={date} content={date} />;
          },
        },
        {
          dataField: "bfaAccNo",
          caption: "Account number",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
        {
          dataField: "bfaAccountOwner",
          caption: "Linked by",
          allowSorting: false,
          allowResizing: false,
          cellRender: (data) => (
            <OverflowTip value={data.value} content={data.value} />
          ),
        },
      ];

const RenderButton = ({
  params,
  handleActivate,
  handleDeactivate,
  accountId,
  connectionId,
  active,
  isInstitutionName,
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  if (typeof params?.id === "string") return;
  if (isInstitutionName) return;
  if (active) {
    return (
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          cursor: "pointer",
        }}
        onClick={() =>
          handleDeactivate({
            accountId: params.data.bfaID || params.data.AccountId,
            connectionId,
          })
        }
      >
        Deactivate
      </Typography>
    );
  }
  return (
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: 700,
        cursor: "pointer",
      }}
      onClick={() => {
        handleActivate({
          accountId: params.data.bfaID || params.data.AccountId,
          connectionId,
        });
      }}
    >
      Connect
    </Typography>
  );
};

export const BankFeedsMainView = ({
  handleActivate,
  handleDeactivate,
  data,
  onNewConnection,
  prefix,
}) => {
  const navigate = useNavigate();
  const businessName =
    localStorage.getItem("businessCode") || "the current business file";
  return (
    <Box>
      <PageTitle title="Bank Feeds - connected accounts" />
      <Typography
        sx={{
          mb: 3,
          fontWeight: 700,
        }}
      >
        This list shows all bank accounts connected to
        {` ${businessName}. `}
        <span
          onClick={() => navigate(prefix + "my-bank-feeds")}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Click here to see all your accounts
        </span>
      </Typography>
      <Box sx={{ marginTop: 2, textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            if (data?.length > 0) {
              navigate(prefix + "my-bank-feeds");
              return;
            } else {
              onNewConnection();
            }
          }}
          sx={{ maxWidth: "200px" }}
        >
          {data?.length > 0 ? "+ Connect New Account" : "+ Connect a Bank"}
        </Button>
      </Box>

      <BankFeedsMainTable
        handleDeactivate={handleDeactivate}
        handleActivate={handleActivate}
        data={data?.map((row, index) => ({ ...row, id: index }))}
      />
    </Box>
  );
};

const BankFeedsMainTable = ({
  handleDeactivate,
  handleActivate,
  data = [],
}) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery("(max-width: 620px)");
  const theme = isDarkMode ? darkTheme : lightTheme;

  const cols = getColumnsForMain({
    isMobile: isMobile,
    handleDeactivate: handleDeactivate,
    handleActivate: handleActivate,
  });

  return (
    <Box
      sx={{
        height: "400px",
        width: "100%",
        ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
          backgroundColor: isDarkMode ? "#232939" : "#FAFAFA",
        },
      }}
    >
      <DataGrid
        rowAlternationEnabled={true}
        dataSource={data}
        showBorders={false}
        // style={{
        //   boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        //   border: "none",
        //   borderRadius: "8px",
        //   marginTop: "10px",
        // }}
      >
        <Grouping autoExpandAll={true} />
        <Scrolling mode="virtual" />
        <Sorting mode="none" />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5]}
          showInfo={true}
        />
        {cols?.map((column) => (
          <Column
            groupCellRender={(params) => {
              return (
                <Typography
                  sx={{
                    marginTop: "-3px",
                    marginBottom: "-3px",
                    fontWeight: 600,
                    color: theme.typography.linkColor,
                  }}
                >
                  {params.value}
                </Typography>
              );
            }}
            groupIndex={
              column.dataField === "bfaCustomName" ||
              column.dataField === "bfbCustomName"
                ? 0
                : -1
            }
            key={column.dataField}
            {...column}
          />
        ))}
      </DataGrid>
    </Box>
  );
};
