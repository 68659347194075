import React, { useState, useEffect } from "react";
import { getCookie } from "../../utils/api";
import Editor from "../../components/Editor/Editor";
import { Box, Button } from "@mui/material";
import envConfig from "../../config";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const NewCodes = () => {
  const [pageInfoList, setPageInfoList] = useState([]);
  const { dbId } = useParams();
  const [newPageInfo, setNewPageInfo] = useState({
    XrpCode: "",
    XrpTitle: "",
    XrpURL: "",
    XrpNotes: "",
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://${envConfig.apiDev2}/api/en-au/devhelper/get-pages-list?BaseHostURL=${envConfig.mainServiceUrl}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "api-key":
              getCookie("apiKey") + "-" + (dbId || getCookie("databaseId")),
          },
        },
      );
      const data = await response.json();
      setPageInfoList(data.PageInfoList || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async (pageInfo) => {
    try {
      const response = await fetch(
        `https://${envConfig.apiDev2}/api/en-au/devhelper/update-page-info?BaseHostURL=${envConfig.mainServiceUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "api-key":
              getCookie("apiKey") + "-" + (dbId || getCookie("databaseId")),
          },
          body: JSON.stringify(pageInfo),
        },
      );
      const data = await response.json();
      console.log("PageInfo updated:", data);
      await fetchData();
    } catch (error) {
      console.error("Error updating PageInfo:", error);
    }
  };

  const handleInsert = async () => {
    try {
      const response = await fetch(
        `https://${envConfig.apiDev2}/api/en-au/devhelper/insert-page-info?BaseHostURL=${envConfig.mainServiceUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "api-key":
              getCookie("apiKey") + "-" + (dbId || getCookie("databaseId")),
          },
          body: JSON.stringify({
            XrpCode: newPageInfo.XrpCode,
            XrpTitle: newPageInfo.XrpTitle,
            XrpURL: newPageInfo.XrpURL,
            XrpNotes: newPageInfo.XrpNotes,
          }),
        },
      );
      const data = await response.json();
      console.log("New PageInfo inserted:", data);
      // Refresh the page info list after insertion
      await fetchData();
      setNewPageInfo({ XrpCode: "", XrpTitle: "", XrpURL: "", XrpNotes: "" });
      setPageInfoList((prevPageInfoList) => [...prevPageInfoList, data]);
    } catch (error) {
      console.error("Error inserting new PageInfo:", error);
    }
  };

  const handleInputChange = (index, field, value) => {
    setPageInfoList((prevPageInfoList) => {
      const updatedPageInfoList = [...prevPageInfoList];
      updatedPageInfoList[index][field] = value;
      return updatedPageInfoList;
    });
  };

  const handleResetBeforeRegistering = async () => {
    try {
      const response = await fetch(
        `https://dev2.olivs.com/api/en-au/security/set-test-environment-for-new-user-registration?Token=ExaccDevs&BaseHostURL=s1.olivs.app`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": "123456789",
            "browser-agent": window.navigator.userAgent,
            "api-key": "0123456789",
          },
        },
      );
      const data = await response.json();
      enqueueSnackbar(data);

      console.log("Reset before registering response:", data);
    } catch (error) {
      enqueueSnackbar(error);
    }
  };

  const handleAssignTwoFACode = async () => {
    try {
      const response = await fetch(
        `https://dev2.olivs.com/api/en-au/security/set-test-environment-for-new-user-verification?Token=ExaccDevs&BaseHostURL=s1.olivs.app`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": "123456789",
            "browser-agent": window.navigator.userAgent,
            "api-key": "0123456789",
          },
        },
      );
      const data = await response.json();
      enqueueSnackbar(data);
      console.log("Assign 123123 as two fa code response:", data);
    } catch (error) {
      enqueueSnackbar(error);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: "20px",
          marginBottom: "50px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetBeforeRegistering}
        >
          Reset Before Registering
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAssignTwoFACode}
        >
          Assign 123123 as Two FA Code for 60s
        </Button>
      </div>
      {pageInfoList?.map((pageInfo, index) => (
        <div key={index} style={{ display: "flex", marginBottom: "10px" }}>
          <input
            type="text"
            value={pageInfo.XrpCode}
            onChange={(e) =>
              handleInputChange(index, "XrpCode", e.target.value)
            }
          />
          <input
            type="text"
            value={pageInfo.XrpTitle}
            onChange={(e) =>
              handleInputChange(index, "XrpTitle", e.target.value)
            }
          />
          <input
            type="text"
            value={pageInfo.XrpURL}
            onChange={(e) => handleInputChange(index, "XrpURL", e.target.value)}
          />
          <input
            type="text"
            value={pageInfo.XrpNotes}
            onChange={(e) =>
              handleInputChange(index, "XrpNotes", e.target.value)
            }
          />
          <button onClick={() => handleSave(pageInfo)}>SAVE</button>
        </div>
      ))}

      <div style={{ display: "flex", marginBottom: "10px" }}>
        <input
          type="text"
          value={newPageInfo.XrpCode}
          onChange={(e) =>
            setNewPageInfo({ ...newPageInfo, XrpCode: e.target.value })
          }
        />
        <input
          type="text"
          value={newPageInfo.XrpTitle}
          onChange={(e) =>
            setNewPageInfo({ ...newPageInfo, XrpTitle: e.target.value })
          }
        />
        <input
          type="text"
          value={newPageInfo.XrpURL}
          onChange={(e) =>
            setNewPageInfo({ ...newPageInfo, XrpURL: e.target.value })
          }
        />
        <input
          type="text"
          value={newPageInfo.XrpNotes}
          onChange={(e) =>
            setNewPageInfo({ ...newPageInfo, XrpNotes: e.target.value })
          }
        />
        <button onClick={handleInsert}>INSERT</button>
      </div>

      <Box sx={{ mt: 5, mb: 5 }}>
        <Editor />
      </Box>
    </div>
  );
};

export default NewCodes;
