export function checkGeneralLedgerQueryParams(
  searchParams: URLSearchParams,
): boolean {
  return (
    // searchParams.has("accNo") &&
    searchParams.has("startDate") && searchParams.has("endDate")
    // searchParams.has("cashMethodYN")
  );
}

export function validateGeneralLedgerQueryParams(
  searchParams: URLSearchParams,
): boolean {
  return (
    !searchParams.get("startDate")?.startsWith("NaN") &&
    !searchParams.get("endDate")?.startsWith("NaN")
  );
}
