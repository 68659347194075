import React, { createContext, useState, useEffect } from "react";
import { useLoginPopup } from "./LoginPopupContext";
import { getCookie } from "../utils/api";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import envConfig from "../config";
import Cookies from "js-cookie";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { handleOpen } = useLoginPopup();
  const { enqueueSnackbar } = useSnackbar();
  const { navigate } = useNavigate();
  const { dbId } = useParams();

  const checkAuth = () => {
    // TODO: IMPORTANT: Remove this line in final version
    if (process.env.NODE_ENV === "development") {
      return;
    }

    const storedTime = getCookie("apiKeyExpirationUTCDate");

    if (!storedTime) {
      window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}`;
      return;
    }

    const currentTimeUTC = new Date().toUTCString();
    const storedTimeUTC = new Date(storedTime + "Z").toUTCString();
    const difference =
      new Date(storedTimeUTC).getTime() - new Date(currentTimeUTC).getTime();

    // console.log(
    //   "Time until session expires (in minutes): ",
    //   difference / 1000 / 60,
    // );

    const maxDiff = envConfig.loginUrl.includes(".app") ? 1 : 5;
    if (difference > maxDiff * 60 * 1000) return;
    if (
      difference <= maxDiff * 60 * 1000 &&
      getCookie("apiKey") &&
      getCookie("apiKey") !== "undefined" &&
      dbId &&
      getCookie("databaseId")
    ) {
      handleOpen();
    } else {
      window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}`;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("check");
      checkAuth();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return <AuthContext.Provider>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
