import React, { useRef } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  TextField,
} from "@mui/material";
import { DataGridWrapper } from "../../../../components/ui/table/data-grid-wrapper";
import DataGrid, {
  Column,
  Editing,
  LoadPanel,
} from "devextreme-react/cjs/data-grid";
import { formatFinancialNumber } from "../../../../utils/format-number";
import { TaxCodesTableToolbar } from "./tax-codes-table-toolbar";
import { format } from "path";
import { useGetAccounts } from "../../../accounting/api/get-accounts";
import { commonThemes } from "../../../../utils/themes/themes";
import { useUpdateTaxCode } from "../api/update-tax-code";
import { useTheme } from "../../../../context/ThemeContext";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import { useLoginPopup } from "../../../../context/LoginPopupContext";

interface TaxCodesTableProps {
  title: string;
  data: any[];
  onRefresh: () => void;
}

export const TaxCodesTable = ({
  title,
  data,
  onRefresh,
}: TaxCodesTableProps) => {
  const dataGridAPI = useRef<any>(null);

  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const accountsData = useGetAccounts();
  const updatedTaxCodeData = useUpdateTaxCode();

  const listOfAccounts =
    accountsData?.data?.ListOfAccounts ||
    // @ts-ignore
    accountsData?.data?.listOfAccounts ||
    [];

  const filteredListOfAccounts = listOfAccounts;

  // .filter(
  //   (acc) => acc.accType_actID < 9 && acc.accActiveYN === "Y",
  // );


  console.log("DEBUGGING: filteredListOfAccounts", filteredListOfAccounts);

  console.log(
    "DEBUGGING: TaxCodesTable -> updatedTaxCodeData",
    updatedTaxCodeData,
  );

  const handleOnSearchChange = (e) => {
    dataGridAPI.current?.instance.searchByText(e.target.value);
  };

  const handleOnSaving = async (e) => {
    const changes = e.changes;

    for (let change of changes) {
      if (change.type === "update") {
        const updatedData = change.data;

        e.cancel = true;

        const result = await updatedTaxCodeData.mutateAsync({
          ...change.key,
          ...updatedData,
        });

        console.log("DEBUGGING: RESULT: ", result);

        // @ts-ignore
        if (result?.SuccessYN === "Y" || result?.successYN === "Y" || result?.data?.SuccessYN === "Y") {
          e.cancel = false;
        }
      }
    }
  };

  const handleOnExcelExporting = async () => {
    try {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");

      await exportDataGrid({
        component: dataGridAPI?.current?.instance,
        worksheet,
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${title}.xlsx`,
      );
    } catch (e) {
      console.error("Error exporting General Ledger Reports to Excel: ", e);
    }
  };

  return (
    <DataGridWrapper isFullScreen={isFullScreen}>
      <TaxCodesTableToolbar
        onExcel={handleOnExcelExporting}
        onRefresh={onRefresh}
        onFullScreen={() => setIsFullScreen(!isFullScreen)}
        onSearchChange={handleOnSearchChange}
      />

      <DataGrid
        dataSource={data}
        ref={dataGridAPI}
        showRowLines={true}
        showColumnLines={true}
        columnAutoWidth={true}
        scrolling={{
          mode: "infinite",
        }}
        onRowClick={(e) => e.component.editRow(e.rowIndex)}
        onSaving={handleOnSaving}
      >
        <Editing
          mode="form"
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={true}
        />
        <Column
          dataField="txcRate"
          caption="Rate"
          cellRender={(cellData) => `${cellData.value * 100}%`}
          editCellRender={RateEditCell}
          formItem={{ label: { visible: false } }}
        />
        <Column
          dataField="txcCode"
          caption="Tax Code"
          editCellRender={TaxCodeEditCell}
          formItem={{ label: { visible: false } }}
        />
        <Column
          dataField="txcName"
          caption="Tax Name"
          editCellRender={TaxNameEditCell}
          formItem={{ label: { visible: false }, visible: false }}
        />
        <Column
          dataField="txcDocName"
          caption="Name on Docs"
          editCellRender={TaxNameOnDocsEditCell}
          formItem={{ label: { visible: false }, visible: false }}
        />
        <Column
          dataField="txcAccount_accNo"
          caption="Account"
          editCellRender={(cellInfo) =>
            AccountEditCell(cellInfo, filteredListOfAccounts)
          }
          formItem={{ label: { visible: false } }}
        />
        <Column type="buttons" visible={false} />
      </DataGrid>
    </DataGridWrapper>
  );
};

const RateEditCell = (cellInfo: any) => {
  const [value, setValue] = React.useState<number | undefined>(
    cellInfo.data.txcRate * 100,
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={2}>
      <TextField
        label="Rate"
        variant="outlined"
        type="number"
        value={value}
        onChange={(e) => {
          const newValue = parseFloat(e.target.value);
          if (newValue < 0 || newValue > 100) return;
          setValue(isNaN(newValue) ? undefined : newValue);
          cellInfo.setValue(e.target.value ? newValue / 100 : 0);
        }}
        sx={{ width: "100%" }}
      />
      <Box fontSize={18} fontWeight={"bold"}>
        %
      </Box>
    </Box>
  );
};

const TaxCodeEditCell = (cellInfo: any) => {
  return (
    <TextField
      label="Tax Code"
      variant="outlined"
      type="text"
      defaultValue={cellInfo.data.txcCode}
      onChange={(e) => {
        cellInfo.setValue(e.target.value);
      }}
      sx={{ width: "100%" }}
    />
  );
};

const TaxNameEditCell = (cellInfo: any) => {
  return (
    <TextField
      label="Tax Name"
      variant="outlined"
      type="text"
      defaultValue={cellInfo.data.txcName}
      onChange={(e) => {
        cellInfo.setValue(e.target.value);
      }}
      sx={{ width: "100%" }}
    />
  );
};

const TaxNameOnDocsEditCell = (cellInfo: any) => {
  return (
    <TextField
      label="Name on Docs"
      variant="outlined"
      type="text"
      defaultValue={cellInfo.data.txcDocName}
      onChange={(e) => {
        cellInfo.setValue(e.target.value);
      }}
      sx={{ width: "100%" }}
    />
  );
};

const AccountEditCell = (cellInfo: any, accounts) => {
  const { isDarkMode } = useTheme();
  const [currAccount, setCurrAccount] = React.useState<string | undefined>(
    cellInfo.data.txcAccount_accNo,
  );
  const value = accounts.find((account) => account.accNo === currAccount) || {};

  return (
    <FormControl size="small" variant="outlined" sx={{ width: "100%" }}>
      <Autocomplete
        fullWidth
        id="accounts-autocomplete"
        options={accounts || []}
        getOptionLabel={(option) => `${option.accNo} `}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props}>
            <Box>
              <Box fontWeight={"bold"}>{option.accNo}</Box>
              <div>{option.accName}</div>
            </Box>
          </ListItem>
        )}
        value={value}
        onChange={(_, newValue) => {
          if (newValue) {
            setCurrAccount(newValue.accNo);
            cellInfo.setValue(newValue.accNo);
          }
        }}
        clearIcon={null}
        isOptionEqualToValue={(option, value) => option.accNo === value.accNo}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Accounts"
            sx={{
              label: {
                fontSize: 12,
                // @ts-ignore
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};
