import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from "@mui/material";
import { useTheme } from "../../../context/ThemeContext";
import { EndDatePeriodType } from "../../../utils/format-date";

interface DatePredefinedEndDatePickerProps {
  value: any;
  onChange: (peroidType: string) => void;
  style?: React.CSSProperties | undefined;
  sx?: SxProps<Theme>;
}

export const DatePredefinedEndDatePicker = ({
  value,
  onChange,
  style,
  sx,
}: DatePredefinedEndDatePickerProps) => {
  const { isDarkMode } = useTheme();

  return (
    <FormControl
      variant="outlined"
      // size="small"
      style={{ ...style }}
      sx={{ ...sx }}
      // sx={{ minWidth: isScreenBelow1050px ? "260px" : "240px" }}
    >
      <InputLabel
        sx={{
          // fontSize: 12,
          // @ts-ignore
          // ...commonThemes.inputLabel,
          color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
        }}
      >
        As of
      </InputLabel>
      <Select
        label="as-of"
        labelId="as-of"
        value={value}
        onChange={(e) => onChange(e.target.value as EndDatePeriodType)}
      >
        <MenuItem sx={{ fontSize: "12px" }} value="today">
          Today
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="end_of_previous_month">
          End of previous Month
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="end_of_previous_quarter">
          End of previous Quarter
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="end_of_previous_year">
          End of previous Year
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value="custom">
          Custom
        </MenuItem>
      </Select>
    </FormControl>
  );
};
