import React from "react";
import dayjs from "dayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers-pro";
import { useTheme } from "../../../context/ThemeContext";
import { DatePickerShortcuts } from "./date-picker-shortcut";
import { commonThemes } from "../../../utils/themes/themes";

interface OlivsDataPickerProps extends DatePickerProps<any> {}

export const OlivsDataPicker = ({
  label,
  value,
  minDate,
  onChange,
  ...restProps
}: OlivsDataPickerProps) => {
  const { isDarkMode } = useTheme();

  return (
    <DatePicker
      label={label}
      value={value}
      minDate={minDate}
      onChange={onChange}
      sx={{
        width: "100%",
        ml: 0.5,
        div: { borderRadius: "8px" },
        input: {
          fontSize: 12,
        },
        label: {
          fontSize: 12,
          // @ts-ignore
          ...commonThemes.inputLabel,
          color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
        },
        legend: {
          span: {
            width: "59px",
          },
        },
        // TODO: add props for overriding styles
        "&.MuiFormControl-root": {
          marginLeft: 0,
        },
        ...restProps.sx,
      }}
      // textField={(params) => <TextField {...params} />}
      slots={{
        shortcuts: DatePickerShortcuts,
      }}
      slotProps={{
        shortcuts: {
          changeImportance: "set",
          // @ts-ignore TODO: fix
          onShortcutClick: (newValue) => onChange(newValue),
          items: [
            {
              label: "Previous year",
              getValue: () => dayjs(value).subtract(1, "year"),
            },
            {
              label: "Next year",
              getValue: () => dayjs(value).add(1, "year"),
            },
            {
              label: "Today",
              getValue: () => dayjs(),
            },
          ],
        },
      }}
      {...restProps}
    />
  );
};
