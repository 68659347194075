import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
// import PageTitle from "../../components/PageTitle/PageTitle";
import { useGetGeneralLedgerReports } from "../../features/reports/general-ledger/api/get-reports";
import { GeneralLedgerFilterModal } from "../../features/reports/general-ledger/components/general-ledger-filter-modal";
import { GeneralLedgerFiltersPanel } from "../../features/reports/general-ledger/components/general-ledger-filters-panel";
import { GeneralLedgerTable } from "../../features/reports/general-ledger/components/general-ledger-table";
import {
  checkGeneralLedgerQueryParams,
  validateGeneralLedgerQueryParams,
} from "../../features/reports/general-ledger/helpers/check-query-params";
import { RequestBoolean } from "../../types/api/common";
import { GeneralLedgerGridRS1RequestBody } from "../../types/api/general-ledger";
import { boolToYN } from "../../utils/format-bool";
import { formatDateToISO } from "../../utils/format-date";
import {
  useGeneralLedgerStoreBase,
  useLedgedStore,
} from "../../features/reports/general-ledger/store";
import { enqueueSnackbar } from "notistack";
import { useReportsDates } from "../../hooks/use-reports-dates";
import { useGetAccounts } from "../../features/accounting/api/get-accounts";
import { PageTitle } from "../../components/page-title";
import { Box, Typography } from "@mui/material";
import { EditButton } from "../../components/ui/buttons/edit-button";
import { useLoginPopup } from "../../context/LoginPopupContext";

export function GeneralLedger() {
  const { handleOpen } = useLoginPopup();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { startDate, endDate } = useReportsDates();
  const accountsData = useGetAccounts();

  const state = useGeneralLedgerStoreBase((state) => state);
  const setStartDate = useLedgedStore.use.setStartDate();
  const setEndDate = useLedgedStore.use.setEndDate();
  const setShowFilterByType = useLedgedStore.use.setShowFilterByType();
  const setSelectedAccounts = useLedgedStore.use.setSelectedAccounts();
  const setSelectedSites = useLedgedStore.use.setSelectedSites();
  const setSelectedContacts = useLedgedStore.use.setSelectedContacts();

  const {
    data,
    isPending,
    mutate: getGeneralLedgerReports,
  } = useGetGeneralLedgerReports({
    mutationConfig: {
      onError: (error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
      },
    }
  });

  const handleOnClose = () => {
    setIsModalOpen(false);
  };

  // const handleOnOpen = () => {
  //   setIsModalOpen(true);
  // };

  const handleGeneralLedgerRefetch = async () => {
    const requestBody: GeneralLedgerGridRS1RequestBody = {
      StartDate: formatDateToISO(state.startDate?.toDate()),
      EndDate: formatDateToISO(state.endDate?.toDate()),
      CashMethodYN: boolToYN(state.showFilters.cashMethod),
      ShowDeptSiteYN: boolToYN(state.showFilters.showDeptSiteYN),
      ShowUserYN: boolToYN(state.showFilters.showUserYN),
      ShowRecDateYN: boolToYN(state.showFilters.showRecDateYN),
      ShowCntCodeYN: boolToYN(state.showFilters.showCntCodeYN),
      ShowIfNoTransactionsYN: boolToYN(
        state.showFilters.showIfNoTransactionsYN,
      ),
      // ReconciliationStatus_GE9: state.reconciliationStatus,
      ReconciliationStatus_GE9: "3",
      RecordStatus_GE11: state.recordStatus,
      ShowPrintDetailsYN: boolToYN(state.showFilters.showPrintDetailsYN),
      BuildHeadersAndDividersYN: "Y",

      // TODO: fix these ???
      // AccList: state.selectedAccounts.map((acc) => acc.accNo),
      AccList: state.selectedAccounts,
      DpsList: state.selectedSites,
      CntList: state.selectedContacts,
    };

    await getGeneralLedgerReports(requestBody, handleOpen);
  };

  useEffect(() => {
    // TURN Reports date into the useReportDates() and there
    // format them and return them as dayjs
    if (searchParams.size === 0) {
      if (startDate) {
        setStartDate(dayjs(startDate));
      }
      if (endDate) {
        setEndDate(dayjs(endDate));
      }
    }
  }, [startDate, endDate, setStartDate, setEndDate, searchParams.size]);

  useEffect(() => {
    const getGeneralLedgerReportsCall = async () => {
      const requestBody: GeneralLedgerGridRS1RequestBody = {
        StartDate: formatDateToISO(searchParams.get("startDate")),
        EndDate: formatDateToISO(searchParams.get("endDate")),
        CashMethodYN: boolToYN(searchParams.get("cashMethodYN") === "Y"),
        ShowDeptSiteYN: boolToYN(searchParams.get("showDeptSite") === "Y"),
        ShowUserYN: boolToYN(searchParams.get("showUser") === "Y"),
        ShowRecDateYN: boolToYN(searchParams.get("showRecDate") === "Y"),
        ShowCntCodeYN: boolToYN(searchParams.get("showCntCode") === "Y"),
        ShowIfNoTransactionsYN: boolToYN(
          searchParams.get("showIfNoTransactions") === "Y",
        ),
        ReconciliationStatus_GE9:
          (searchParams.get("reconciliationStatus") as "1" | "2" | "3") || "3",
        RecordStatus_GE11:
          (searchParams.get("recordStatus") as "1" | "2" | "3" | "4") || "1",
        ShowPrintDetailsYN: searchParams.get(
          "showPrintDetailsYN",
        ) as RequestBoolean,
        AccList: searchParams.getAll("accNo"),
        DpsList: searchParams.getAll("dpsCode"),
        CntList: searchParams.getAll("cntCode"),
        BuildHeadersAndDividersYN: "Y",
      };

      await getGeneralLedgerReports(requestBody, handleOpen);
    };

    if (
      checkGeneralLedgerQueryParams(searchParams) &&
      validateGeneralLedgerQueryParams(searchParams)
    ) {
      getGeneralLedgerReportsCall();

      setStartDate(dayjs(formatDateToISO(searchParams.get("startDate"))));
      setEndDate(dayjs(formatDateToISO(searchParams.get("endDate"))));
      setShowFilterByType(
        "cashMethod",
        searchParams.get("cashMethodYN") === "Y",
      );
      setShowFilterByType(
        "showDeptSiteYN",
        searchParams.get("showDeptSite") === "Y",
      );
      setShowFilterByType("showUserYN", searchParams.get("showUserYN") === "Y");
      setShowFilterByType(
        "showRecDateYN",
        searchParams.get("showRecDateYN") === "Y",
      );
      setShowFilterByType(
        "showCntCodeYN",
        searchParams.get("showCntCodeYN") === "Y",
      );
      setShowFilterByType(
        "showIfNoTransactionsYN",
        searchParams.get("showIfNoTransactionsYN") === "Y",
      );
      setShowFilterByType(
        "showPrintDetailsYN",
        searchParams.get("showPrintDetailsYN") === "Y",
      );
      setSelectedAccounts(searchParams.getAll("accNo").map(Number));
      setSelectedSites(searchParams.getAll("dpsCode").map(String));
      setSelectedContacts(searchParams.getAll("cntCode").map(String));
    } else {
      setIsModalOpen(true);
    }
  }, [
    searchParams,
    getGeneralLedgerReports,
    setStartDate,
    setEndDate,
    setShowFilterByType,
  ]);

  const listOfAccounts =
    accountsData?.data?.ListOfAccounts ||
    // @ts-ignore
    accountsData?.data?.listOfAccounts ||
    [];
  const listOfAccountsNameByAccNo = listOfAccounts.reduce(
    (prev, curr, ind) => ({
      ...prev,
      [curr.accNo]: curr.accName,
    }),
    {},
  );

  const isLoading = isPending || accountsData.isLoading;

  return (
    <>
      <BackdropLoading open={isLoading} />

      <GeneralLedgerFilterModal
        isModalOpen={isModalOpen}
        onClose={handleOnClose}
        onApplyFilters={handleGeneralLedgerRefetch}
      />

      <Box display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} alignItems={"center"} gap={2} marginTop={2}>
          <Box sx={{mr: 3}}>
            <PageTitle title="General Ledger Report" />
          </Box>
          {state.startDate && state.endDate && (
            <Typography component={"p"} fontSize={14} fontWeight={700}>
              {`From ${state.startDate.format("DD/MM/YYYY")} to ${state.endDate.format("DD/MM/YYYY")} ${state.showFilters.cashMethod ? "Cash Method" : ""}`}
            </Typography>
          )}
          <EditButton title="Update" onClick={() => setIsModalOpen(true)} />
        </Box>

        <GeneralLedgerTable
          data={data?.DataDT}
          columns={data?.ColumnsList}
          startDate={state.startDate?.format("DD MM YY") || ""}
          endDate={state.endDate?.format("DD MM YY") || ""}
          onRefresh={handleGeneralLedgerRefetch}
          listOfAccountsNameByAccNo={listOfAccountsNameByAccNo}
        />
      </Box>
    </>
  );
}
