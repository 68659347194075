import { ITooltip } from "../types/utils";

// TODO: Add to utils
export function getSummaryType(
  RpcAvgOrSumOrCount: string | undefined,
): "max" | "min" | "avg" | "count" | "custom" | "sum" | undefined {
  if (!RpcAvgOrSumOrCount) return undefined;

  if (RpcAvgOrSumOrCount === "C") {
    return "count";
  }

  if (RpcAvgOrSumOrCount === "S") {
    return "sum";
  }

  if (RpcAvgOrSumOrCount === "A") {
    return "avg";
  }
}

export const hasColumnNameTooltip = (
  tooltips: ITooltip[],
  columnName: string,
): boolean => {
  return tooltips.some((tooltip) => tooltip.ColumnToDisplay === columnName);
};

export const getColumnNameTooltip = (
  tooltips: ITooltip[],
  columnName: string,
): string | undefined => {
  const tooltip = tooltips.find(
    (tooltip) => tooltip.ColumnToDisplay === columnName,
  );

  return tooltip ? tooltip.ColumnWithText : undefined;
};

const columnDateTypes = {
  1: "ShortString",
  2: "Money",
  3: "Decimal",
  4: "Integer",
  5: "DateShort",
  6: "DateLong",
  7: "Time",
  8: "DateTime",
  9: "Link",
}

export const getColumnDataType = (RpcDataType_rpdID: number) => {
  return columnDateTypes[RpcDataType_rpdID];
};

export const isColumnMoneyType = (RpcDataType_rpdID: number) => {
  return RpcDataType_rpdID === 2;
}

export const isColumnDateType = (RpcDataType_rpdID: number) => {
  return RpcDataType_rpdID === 5 || RpcDataType_rpdID === 6 || RpcDataType_rpdID === 8;
}

export const isColumnDateShortType = (RpcDataType_rpdID: number) => {
  return RpcDataType_rpdID === 5;
}

export const findColumnDataType = (columns: any[], columnName: string | undefined) => {
  if (!columnName) return undefined;

  const column = columns.find((column) => column.RpcDataSource === columnName);

  return column ? column.RpcDataType_rpdID : undefined;
}
