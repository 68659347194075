import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  TextField,
  useMediaQuery,
  Checkbox,
  Box,
  InputLabel,
  Typography,
  Button,
  Alert,
  Autocomplete,
  Toolbar,
  ListItem,
  Divider,
  List,
} from "@mui/material";
import {
  addMonths,
  startOfMonth,
  endOfMonth,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  addYears,
  startOfYear,
  endOfYear,
  addWeeks,
  endOfWeek,
  endOfDay,
} from "date-fns";
import { commonThemes, darkTheme, lightTheme } from "../../utils/themes/themes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import ToggleSection from "../ToggleSection/ToggleSections";
import { AntSwitch } from "../AntSwitch/AntSwitch";
import { useTheme } from "../../context/ThemeContext";
import dayjs from "dayjs";
import useApiCall from "../../hooks/ApiCall";
import { getCookie, getHeaders } from "../../utils/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLoginPopup } from "../../context/LoginPopupContext";
import { DatePicker } from "@mui/x-date-pickers-pro";
import Cookies from "js-cookie";
import ReportDatesContext from "../../context/ReportDatesContext";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import envConfig from "../../config";
import { enqueueSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";

export const CustomShortcuts = (props) => {
  const { items, onChange, isValid, onShortcutClick } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onShortcutClick(newValue);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2,
      }}
    >
      <List
        dense
        sx={(theme) => ({
          display: "flex",
          "& .MuiListItem-root": {
            pt: 0,
            pl: 1,
          },
        })}
      >
        {resolvedItems.map((item) => {
          return (
            <ListItem sx={{ px: 0, justifyContent: "center" }} key={item.label}>
              <Button
                sx={{
                  fontSize: "12px",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  item.onClick();
                }}
                disabled={item.disabled}
              >
                {item.label}
              </Button>
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
};

const BalanceSheetSettings = ({
  open,
  onClose,
  periodType,
  setPeriodType = () => {},
  dateRange,
  setDateRange = () => {},
  showAccountsNumbers,
  setShowAccountsNumbers = () => {},
  showZeroBalanceAccounts,
  setShowZeroBalanceAccounts = () => {},
  compareValue,
  setCompareValue = () => {},
  compareYearsValue,
  setCompareYearsValue = () => {},
  setGridData = () => {},
  compareChoice,
  setCompareChoice = () => {},
  groupsOrAccounts,
  setGroupsOrAccounts = () => {},
  selectedSites = [],
  setSelectedSites = () => {},
  selectedContacts = [],
  setSelectedContacts = () => {},
  selectedUsers = [],
  setSelectedUsers = () => {},
  sortByAccountName,
  setSortByAccountName = () => {},
  setLastUsedParams = () => {},
  predefinedPeriod,
  setPredefinedPeriod,
}) => {
  console.log(selectedContacts);
  const { apiCall } = useApi();
  const ip = useGetIP();

  const navigate = useNavigate();
  const location = useLocation();

  const { handleOpen } = useLoginPopup();
  const { dbId, lang } = useParams();
  const { isDarkMode } = useTheme();

  const { reportDates } = useContext(ReportDatesContext);

  const theme = isDarkMode ? darkTheme : lightTheme;
  const breakDropdowns = useMediaQuery("(max-width: 940px)");
  const breakAutocomplete = useMediaQuery("(max-width: 1100px)");
  const breakAdvanced = useMediaQuery("(max-width: 847px)");
  const isMobile = useMediaQuery("(max-width: 620px)");

  const prefix = `/${dbId}/${lang}/`;

  const [snapshotData, setSnapshotData] = useState({});

  const [advancedOptionsExpanded, setAdvancedOptionsExpanded] = useState(true);

  // row 4
  const [loading, setLoading] = useState(false);
  const [saveFilters, setSaveFilters] = useState(true);
  const controllerName = "accounting";
  const actionName = "get-balance-sheet-grid-rs1";
  const apiUrl = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

  const sitesUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-selection-list?activeYN=Y&BaseHostURL=${envConfig.mainServiceUrl}`;
  const usersUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/users/get-selection-list?activeYN=Y&BaseHostURL=${envConfig.mainServiceUrl}`;
  const clientsUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/contacts/get-selection-list?activeYN=Y&typeCS=C&BaseHostURL=${envConfig.mainServiceUrl}`;

  const [sitesData, setSitesData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [clientsData, setClientsData] = useState(null);

  const fetchSitesData = async () => {
    await apiCall({
      url: sitesUrl,
      method: "GET",
      onSuccess: (data) => {
        setSitesData(data);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  const fetchUsersData = async () => {
    await apiCall({
      url: usersUrl,
      method: "GET",
      onSuccess: (data) => {
        setUsersData(data);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  const fetchClientsData = async () => {
    await apiCall({
      url: clientsUrl,
      method: "GET",
      onSuccess: (data) => {
        setClientsData(data);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  useEffect(() => {
    fetchSitesData();
    fetchUsersData();
    fetchClientsData();
  }, []);

  useEffect(() => {
    if (reportDates?.reportsEndDate) {
      const formattedEndDate = new Date(reportDates?.reportsEndDate + "Z")
        .toISOString()
        .split("T")[0];

      const dayjsEndDate = dayjs(formattedEndDate);
      setDateRange([null, dayjsEndDate]);
    }
  }, [reportDates]);

  const handleEndDateChange = (newEndDate) => {
    setDateRange([null, newEndDate]);
    setPeriodType("custom");
  };

  const handleCompareValueChange = (event) => {
    setCompareValue(event.target.value);
    if (event.target.value) {
      setCompareChoice("earlierPeriods");
    }
  };

  const handleCompareYearsValueChange = (event) => {
    setCompareYearsValue(event.target.value);
  };

  const handlePredefinedPeriodChange = (event) => {
    setPredefinedPeriod(event.target.value);
  };

  const handleComparePeriodChange = (event) => {
    if (event.target.value === "none") {
      setCompareValue(0);
      setCompareYearsValue(0);
    } else {
      if (compareValue === 0) {
        setCompareValue(1);
      }
      if (compareYearsValue === 0) {
        setCompareYearsValue(1);
      }
    }
    setCompareChoice(event.target.value);
  };

  const handlePeriodTypeChange = (event) => {
    const newPeriodType = event.target.value;

    if (newPeriodType === "custom") {
      // setDateRange([null, null]);
      return;
    } else {
      let endDate;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      if (newPeriodType === "today") {
        endDate = endOfDay(new Date());
        setPredefinedPeriod("week");
      } else if (newPeriodType === "end_of_previous_month") {
        endDate = endOfMonth(addMonths(currentDate, -1));
        setPredefinedPeriod("month");
      } else if (newPeriodType === "end_of_previous_quarter") {
        endDate = endOfQuarter(addQuarters(currentDate, -1));
        setPredefinedPeriod("quarter");
      } else if (newPeriodType === "end_of_previous_year") {
        if (currentDate >= new Date(currentYear, 6, 1)) {
          endDate = new Date(currentYear, 5, 30);
        } else {
          endDate = new Date(currentYear - 1, 5, 30);
        }
        setPredefinedPeriod("year");
      }

      const formattedEndDate = new Date(endDate).toISOString().split("T")[0];
      const dayjsEndDate = dayjs(formattedEndDate);
      setDateRange([null, dayjsEndDate]);
    }

    setPeriodType(newPeriodType);
  };

  const handleCancel = () => {
    setPeriodType(snapshotData.periodType);
    setDateRange(snapshotData.dateRange);
    setShowAccountsNumbers(snapshotData.showAccountsNumbers);
    setShowZeroBalanceAccounts(snapshotData.showZeroBalanceAccounts);
    setCompareValue(snapshotData.compareValue);
    setCompareYearsValue(snapshotData.compareYearsValue);
    setCompareChoice(snapshotData.compareChoice);
    setGroupsOrAccounts(snapshotData.groupsOrAccounts);
    setSelectedSites(snapshotData.selectedSites);
    setSelectedContacts(snapshotData.selectedContacts);
    setSelectedUsers(snapshotData.selectedUsers);
    setSaveFilters(true);
  };

  const handleSnapshot = () => {
    setSnapshotData({
      periodType,
      dateRange,
      showAccountsNumbers,
      showZeroBalanceAccounts,
      compareValue,
      compareYearsValue,
      compareChoice,
      groupsOrAccounts,
      selectedSites,
      selectedContacts,
      selectedUsers,
      saveFilters,
    });
  };

  useEffect(() => {
    const filtersFromMemory = localStorage.getItem("balanceSheetFilters");
    const parsedFilters = JSON.parse(filtersFromMemory);
    const parsedDateRange = parsedFilters?.dateRange
      ? parsedFilters.dateRange.map((dateString) =>
          dateString ? dayjs(dateString) : null,
        )
      : [null, null];
    setDateRange(parsedDateRange);
    setCompareValue(parsedFilters?.compareValue || compareValue || 0);
    setCompareYearsValue(
      parsedFilters?.compareYearsValue || compareYearsValue || 0,
    );

    setPeriodType(parsedFilters?.periodType || "custom");

    setGroupsOrAccounts(parsedFilters?.groupsOrAccounts || "accountsOnly");
    setShowAccountsNumbers(parsedFilters?.showAccountsNumbers || true);
    setShowZeroBalanceAccounts(parsedFilters?.showZeroBalanceAccounts || false);

    setCompareChoice(parsedFilters?.compareChoice || "earlierPeriods");
    setSelectedSites(parsedFilters?.selectedSites || []);
    setSelectedContacts(parsedFilters?.selectedContacts || []);
    setSelectedUsers(parsedFilters?.selectedUsers || []);
    setAdvancedOptionsExpanded(parsedFilters?.advancedOptionsExpanded || true);
    setSaveFilters(parsedFilters?.saveFilters || true);
  }, []);

  useEffect(() => {
    if (!open) {
      setSnapshotData({});
    } else {
      handleSnapshot();
    }
  }, [open]);

  const handleSaveFilters = () => {
    // if (saveFilters) {
    console.log(1);
    const filtersToSave = {
      dateRange: dateRange.map((date) => (date ? date.toJSON() : null)),

      compareValue,
      compareYearsValue,
      groupsOrAccounts,
      showAccountsNumbers,

      showZeroBalanceAccounts,
      periodType,
      compareChoice,
      selectedSites,
      selectedContacts,
      selectedUsers,
      advancedOptionsExpanded,
      saveFilters,
    };
    localStorage.setItem("balanceSheetFilters", JSON.stringify(filtersToSave));
    // }
    console.log(2);
  };

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);

  //   if (params.get("endDate")) {
  //     handleApplyFilters(); // Fetch data if URL parameters change
  //   }
  // }, [location.search]);

  const handleApplyFilters = () => {
    let endDate;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (periodType === "custom") {
      endDate = dateRange[1];
      if (!endDate) return;
    } else {
      if (periodType === "today") {
        endDate = endOfDay(currentDate);
      } else if (periodType === "end_of_previous_month") {
        endDate = endOfMonth(addMonths(currentDate, -1));
      } else if (periodType === "end_of_previous_quarter") {
        endDate = endOfQuarter(addQuarters(currentDate, -1));
      } else if (periodType === "end_of_previous_year") {
        endDate =
          currentDate >= new Date(currentYear, 6, 1)
            ? new Date(currentYear, 5, 30)
            : new Date(currentYear - 1, 5, 30);
      }
    }

    let eYear, eMonth, eDay;
    if (!endDate) return;

    if (endDate.$d) {
      eYear = endDate.year();
      eMonth = endDate.month() + 1;
      eDay = endDate.date();
    } else {
      eYear = endDate.getFullYear();
      eMonth = endDate.getMonth() + 1;
      eDay = endDate.getDate();
    }

    eMonth = eMonth < 10 ? "0" + eMonth : eMonth;
    eDay = eDay < 10 ? "0" + eDay : eDay;

    const formattedEndDate = `${eYear}-${eMonth}-${eDay}`;

    const params = new URLSearchParams({
      endDate: formattedEndDate,
      compareChoice,
      compareValue,
      compareYearsValue,
      predefinedPeriod,
      groupsOrAccounts,
      showAccountsNumbers: showAccountsNumbers ? "Y" : "N",
      showZeroBalanceAccounts: showZeroBalanceAccounts ? "Y" : "N",
      selectedSites: selectedSites.map((site) => site.dpsID).join(","),
      selectedContacts: selectedContacts
        .map((contact) => contact.cntId)
        .join(","),
      selectedUsers: selectedUsers.map((user) => user.usrID).join(","),
    });

    navigate(`?${params.toString()}`);
    onClose();
  };

  const handleSelectChange = (category, selectedValues) => {
    switch (category) {
      case "sites":
        setSelectedSites(selectedValues);
        break;
      case "contacts":
        setSelectedContacts(selectedValues);
        break;
      case "users":
        setSelectedUsers(selectedValues);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <BackdropLoading open={loading} />
      <Dialog
        open={open}
        onClose={() => {
          handleCancel();
          onClose();
        }}
        sx={{
          ">div>div": { maxWidth: "xl", padding: isMobile ? 0 : 2 },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontSize: isMobile ? 18 : theme.h1.fontSize,
            fontWeight: theme.typography.fontWeightThin,
          }}
        >
          Balance Sheet Settings
        </DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <div
            style={{
              display: "flex",
              alignItems: breakDropdowns && !isMobile ? "flex-start" : "center",
              flexDirection: isMobile ? "column" : "row",
              marginBottom: "19px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: breakDropdowns ? "column" : "row",
              }}
            >
              <FormControl
                variant="outlined"
                size="small"
                sx={{ minWidth: "260px" }}
                style={{
                  marginRight: "16px",
                  marginTop: "15px",
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: 12,
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  As of
                </InputLabel>
                <Select
                  label="As of"
                  labelId="Predefined_Period"
                  defaultValue={"custom"}
                  value={periodType}
                  onChange={handlePeriodTypeChange}
                >
                  <MenuItem sx={{ fontSize: "12px" }} value="today">
                    Today
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    value="end_of_previous_month"
                  >
                    End of previous Month
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    value="end_of_previous_quarter"
                  >
                    End of previous Quarter
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    value="end_of_previous_year"
                  >
                    End of previous Year
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "12px" }} value="custom">
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>

              <Box
                sx={{
                  pt: 2,
                  textAlign: "center",
                  position: "relative",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    ...commonThemes.normalText,
                    marginRight: "32px",
                    marginLeft: "14px",
                    marginTop: isMobile ? "15px" : "20px",
                    fontSize: theme.typography.fontSize,
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                >
                  Date
                </span>
                {isMobile && (
                  <Button
                    sx={{
                      mt: "7px",
                      p: 1,
                      position: "absolute",
                      top: "50%",
                      right: 0,
                      transform: "translateY(-50%)",
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: theme.palette.primary.main,
                      },
                    }}
                    disabled={loading}
                    onClick={() => {
                      handleSaveFilters();
                      handleApplyFilters();
                    }}
                    variant="primary"
                  >
                    Show Report
                  </Button>
                )}
              </Box>
              <FormControl
                size="small"
                component="fieldset"
                sx={{
                  minWidth: "fit-content",
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    label="Date"
                    value={dateRange[1]}
                    onChange={handleEndDateChange}
                    sx={{
                      ml: 0.5,
                      minWidth: "145px",
                      maxWidth: "145px",
                      div: { borderRadius: "8px" },
                      input: {
                        fontSize: 12,
                      },
                      label: {
                        fontSize: 12,
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                      legend: {
                        span: {
                          width: "59px",
                        },
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    slots={{
                      shortcuts: CustomShortcuts,
                    }}
                    slotProps={{
                      shortcuts: {
                        changeImportance: "set",
                        onShortcutClick: (newValue) => {
                          handleEndDateChange(newValue);
                        },
                        items: [
                          {
                            label: "Previous year",
                            getValue: () =>
                              dayjs(dateRange[1]).subtract(1, "year"),
                          },
                          {
                            label: "Next year",
                            getValue: () => dayjs(dateRange[1]).add(1, "year"),
                          },
                          {
                            label: "Today",
                            getValue: () => dayjs(),
                          },
                        ],
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <FormLabel
              component="legend"
              sx={{
                textAlign: isMobile ? "left" : "unset",
                width: isMobile ? "100%" : "unset",
                marginLeft: isMobile ? 5 : "unset",
                marginRight: 5,
                pt: 1,
                ...theme.blueLabel,
              }}
            >
              Compare this period
            </FormLabel>
            <RadioGroup
              sx={{
                flexDirection: breakAutocomplete ? "column" : "row",
                alignItems:
                  breakAutocomplete && !isMobile ? "flex-start" : "center",
                fontSize: theme.typography.fontSize,
              }}
              value={compareChoice}
              onChange={handleComparePeriodChange}
              row
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  sx={{
                    span: { fontSize: theme.typography.fontSize },
                    marginRight: 1,
                    ...theme.normalText,
                  }}
                  value="earlierPeriods"
                  checked={compareChoice === "earlierPeriods"}
                  control={<Radio />}
                  label={`With `}
                />
                <TextField
                  type="tel"
                  sx={{
                    maxWidth: "58px",
                    width: "100%",
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },

                    "input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    input: { maxHeight: "12px", padding: "10px 6px 10px 14px" },
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  inputProps={{
                    min: 1,
                    max: 23,
                    step: 1,
                  }}
                  label={``}
                  value={compareValue}
                  onFocus={(event) => event.target.select()}
                  onChange={handleCompareValueChange}
                />

                <Typography sx={{ ml: "8px", ...commonThemes.normalText }}>
                  previous
                </Typography>

                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1, minWidth: "260px" }}
                  style={{
                    marginRight: "16px",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: 12,
                      ...commonThemes.inputLabel,
                      color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    }}
                  >
                    Comparison span
                  </InputLabel>
                  <Select
                    label="Comparison span"
                    labelId="Predefined_Period"
                    value={predefinedPeriod}
                    onChange={handlePredefinedPeriodChange}
                  >
                    <MenuItem sx={{ fontSize: "12px" }} value="week">
                      Week(s)
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "12px" }} value="fortnight">
                      Fortnight(s)
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "12px" }} value="month">
                      Month(s)
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "12px" }} value="quarter">
                      Quarter(s)
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "12px" }} value="year">
                      Year(s)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <FormControlLabel
                sx={{
                  width: isMobile ? "100%" : "unset",
                  span: {
                    fontSize: theme.typography.fontSize,
                    ...commonThemes.normalText,
                  },
                  marginLeft: isMobile
                    ? "-5px"
                    : breakAutocomplete && !isMobile
                      ? "-11px"
                      : 5,
                }}
                value="none"
                control={<Radio />}
                label="Don't compare"
                checked={compareChoice === "none"}
              />
            </RadioGroup>
          </div>
        </DialogContent>
        <Box
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
            marginTop: "20px",
          }}
        >
          <ToggleSection
            expanded={advancedOptionsExpanded}
            onClick={() => setAdvancedOptionsExpanded(!advancedOptionsExpanded)}
            title="Advanced Options"
          >
            <div>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <FormLabel
                  sx={{
                    minWidth: "181px",
                    mt: 1,
                    ...theme.blueLabel,
                  }}
                >
                  Show
                </FormLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection:
                      breakAdvanced && !isMobile ? "row-reverse" : "column",
                    ml: isMobile ? -14 : 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: breakAdvanced ? "column" : "row",
                    }}
                  >
                    <FormControlLabel
                      value={!!showAccountsNumbers}
                      control={
                        <Checkbox
                          checked={!!showAccountsNumbers}
                          onChange={() =>
                            setShowAccountsNumbers(!showAccountsNumbers)
                          }
                          id="showAccountsNumbers"
                        />
                      }
                      label="Accounts numbers"
                      sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                    />
                    <FormControlLabel
                      value={!!showZeroBalanceAccounts}
                      control={
                        <Checkbox
                          checked={!!showZeroBalanceAccounts}
                          onChange={() => {
                            setShowZeroBalanceAccounts(
                              !showZeroBalanceAccounts,
                            );
                          }}
                          id="showZeroBalance"
                        />
                      }
                      label="Zero balance accounts"
                      sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                    />
                  </Box>

                  <RadioGroup
                    sx={{
                      display: "flex",
                      flexDirection: breakAdvanced ? "column" : "row",
                    }}
                  >
                    <FormControlLabel
                      value="groupsOnly"
                      onChange={() => setGroupsOrAccounts("groupsOnly")}
                      control={
                        <Radio checked={groupsOrAccounts === "groupsOnly"} />
                      }
                      label="Groups only"
                      sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                    />
                    <FormControlLabel
                      value="groupsAndAccounts"
                      onChange={() => setGroupsOrAccounts("groupsAndAccounts")}
                      control={
                        <Radio
                          checked={groupsOrAccounts === "groupsAndAccounts"}
                        />
                      }
                      label="Groups and accounts"
                      sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                    />
                    <FormControlLabel
                      value="accountsOnly"
                      onChange={() => setGroupsOrAccounts("accountsOnly")}
                      control={
                        <Radio checked={groupsOrAccounts === "accountsOnly"} />
                      }
                      label="Accounts Only"
                      sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                    />
                  </RadioGroup>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mb: "36px" }}>
                <FormLabel
                  sx={{
                    minWidth: "181px",
                    mt: 1,
                    ...theme.blueLabel,
                  }}
                >
                  Sort by
                </FormLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    ml: isMobile ? -14 : 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: breakAdvanced ? "column" : "row",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={sortByAccountName}
                          onChange={() => setSortByAccountName(true)}
                          value="true"
                          name="sortByAccount"
                        />
                      }
                      label="Account Name"
                      sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={!sortByAccountName}
                          onChange={() => setSortByAccountName(false)}
                          value="false"
                          name="sortByAccount"
                        />
                      }
                      label="Account Number"
                      sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                    />
                  </Box>
                </Box>
              </Box>

              <div style={{ marginBottom: "12px" }}>
                <FormLabel
                  sx={{
                    display: breakAutocomplete ? "flex" : "inline-block",
                    minWidth: "181px",
                    marginTop: "16px",
                    ...theme.blueLabel,
                  }}
                >
                  Restrict transactions to
                </FormLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: breakDropdowns ? "column" : "row",
                    textAlign: isMobile ? "center" : "unset",
                    paddingLeft: breakDropdowns && !isMobile ? "180px" : "auto",
                    marginTop: breakDropdowns && !isMobile ? "-38px" : "0",
                  }}
                >
                  <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
                    <Autocomplete
                      multiple
                      id="sites-autocomplete"
                      options={sitesData?.ListOfSites || []}
                      getOptionLabel={(option) => option.dpsName}
                      value={selectedSites}
                      onChange={(_, newValue) => {
                        handleSelectChange("sites", newValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.dpsID === value.dpsID
                      }
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sites"
                          sx={{
                            label: {
                              fontSize: 12,
                              ...commonThemes.inputLabel,
                              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                            },
                            minWidth: "240px",
                            maxWidth: "240px",
                            marginRight: 2,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
                    <Autocomplete
                      multiple
                      id="contacts-autocomplete"
                      options={clientsData?.ListOfContacts || []}
                      getOptionLabel={(option) => option.cntName}
                      value={selectedContacts}
                      onChange={(_, newValue) => {
                        handleSelectChange("contacts", newValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.cntId === value.cntId
                      }
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contacts"
                          sx={{
                            label: {
                              fontSize: 12,
                              ...commonThemes.inputLabel,
                              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                            },
                            minWidth: "240px",
                            maxWidth: "240px",
                            marginRight: 2,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
                    <Autocomplete
                      multiple
                      id="users-autocomplete"
                      options={usersData?.ListOfUsers || []}
                      getOptionLabel={(user) => `${user.usrName || ""}`}
                      value={selectedUsers}
                      onChange={(_, newValue) => {
                        handleSelectChange("users", newValue);
                      }}
                      isOptionEqualToValue={(user, value) =>
                        user.usrID === value.usrID
                      }
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Users"
                          sx={{
                            label: {
                              fontSize: 12,
                              ...commonThemes.inputLabel,
                              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                            },
                            minWidth: "240px",
                            maxWidth: "240px",
                            marginRight: 2,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
          </ToggleSection>
        </Box>
        {/* <FormControl>
          <FormControlLabel
            value="saveFilters"
            control={
              <AntSwitch
                value={!!saveFilters}
                checked={!!saveFilters}
                onChange={(e) => {
                  setSaveFilters(e.target.value === "true" ? false : true);
                }}
              />
            }
            sx={{
              width: "fit-content",
              pl: 4,
              mt: "24px",
              ">span:last-of-type": {
                paddingLeft: "8px",
                ...commonThemes.normalText,
              },
            }}
            label="save filters for the next time"
          />
        </FormControl> */}

        <DialogActions sx={{ pt: 0, mt: 5 }}>
          <Button
            sx={{
              "&:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={() => {
              handleCancel();
              onClose();
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            disabled={loading}
            onClick={() => {
              handleSaveFilters();
              handleApplyFilters();
            }}
            variant="primary"
          >
            Show Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BalanceSheetSettings;
