import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  TextField,
} from "@mui/material";

import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";
import { useGetContacts } from "../../../accounting/api/get-contacts";
import { useLedgedStore } from "../store";

export const GeneralLedgerContacts = () => {
  const { isDarkMode } = useTheme();
  const { data, loading, error } = useGetContacts();

  const selectedContacts = useLedgedStore.use.selectedContacts();
  const setSelectedContacts = useLedgedStore.use.setSelectedContacts();

  // @ts-ignore
  const contacts = data?.ListOfContacts || [];
  const values = contacts.filter((contact) =>
    selectedContacts.includes(contact.cntCode),
  );

  return (
    <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
      <Autocomplete
        loading={loading}
        multiple
        id="contacts-autocomplete"
        // @ts-ignore
        options={data?.ListOfContacts || []}
        getOptionLabel={(option) => `${option.cntCode}`}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props}>
            <Box>
              <Box fontWeight={"bold"}>{option.cntCode}</Box>
              <div>{option.cntName}</div>
            </Box>
          </ListItem>
        )}
        value={values}
        onChange={(_, newValue) =>
          setSelectedContacts(newValue.map((v) => v.cntCode))
        }
        isOptionEqualToValue={(option, value) => option.cntId === value.cntId}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label="Contacts"
            sx={{
              label: {
                fontSize: 12,
                // @ts-ignore
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
              minWidth: "240px",
              maxWidth: "240px",
            }}
          />
        )}
      />
    </FormControl>
  );
};
