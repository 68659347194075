import React from "react";
import { Box } from "@mui/material";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import {
  Account,
  useAccountsInformation,
} from "../../features/books/import-transactions-from-csv/api/accounts-list";
import { ImportTransactionsFromCSVPanel } from "../../features/books/import-transactions-from-csv/components/import-transcations-from-csv-panel";
import { enqueueSnackbar } from "notistack";
import Papa from "papaparse";
import {
  ACCOUNT_COLUMN,
  AMOUNT1_COLUMN,
  AMOUNT2_COLUMN,
  AMOUNT3_COLUMN,
  AMOUNT4_COLUMN,
  BANK_R1_COLUMN,
  BANK_R2_COLUMN,
  BANK_R3_COLUMN,
  BANK_R4_COLUMN,
  cleanArrays,
  CONTACT_COLUMN,
  DATE_COLUMN,
  getHeadersFromCSV,
  isAmountValidRow,
  isDate,
  isPrivPortValidRow,
  parseTranscationsCSV,
  PRIV_PORT_COLUMN,
  SITE_COLUMN,
  TAX_CODE_COLUMN,
} from "../../features/books/import-transactions-from-csv/helpers/get-headers-from-csv";
import { useColumnsHeaderList } from "../../features/books/import-transactions-from-csv/api/columns-header-list";
import { ImportTransactionFromCSVTable } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-table";
import dxDataGrid from "devextreme/ui/data_grid";
import DataGrid from "devextreme-react/cjs/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { ImportTransactionsFromCSVPanelImporting } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-panel-importing";
import {
  findMinDate,
  parseDateString,
} from "../../features/books/import-transactions-from-csv/helpers/dates";
import { ImportTranscationsFromCSVValidationConsistencyModal } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-validation-consistency-modal";
import { isValid } from "date-fns";
import { ImportTranscationsFromCSVValidationHeadersModal } from "../../features/books/import-transactions-from-csv/components/import-transactions-from-csv-validation-headers-modal";
import useApiCall from "../../hooks/ApiCall";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";

interface Rows {
  [key: string]: string;
}

type VALIDATION_STATUS =
  | "VALIDATE_HEADERS"
  | "VALIDATE_CONSISTENCY_WITH_PREVIOUS"
  | "NO_AMOUNT_OR_DATE"
  | "NO_ACC_OR_TAX"
  | "NO_BANK_SEARCH";

export function ImportTransactionsFromCSVPage() {
  const dtGridRef = React.useRef<DataGrid>(null);

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = React.useState<
    Account | undefined
  >(undefined);
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [rows, setRows] = React.useState<Rows[]>([]);
  const [columns, setColumns] = React.useState<string[]>([]);
  const [headers, setHeaders] = React.useState<string[]>([]);

  const [isImporting, setIsImporting] = React.useState<boolean>(false);

  const [invalidRows, setInvalidRows] = React.useState<string[][]>([]);
  const [validationStatus, setValidationStatus] =
    React.useState<VALIDATION_STATUS | null>(null);

  const [minDate, setMinDate] = React.useState<Date | null>(null);
  const [lockDate, setLockDate] = React.useState<Date | null>(null);
  const [lastTransDate, setLastTransDate] = React.useState<Date | null>(null);

  const { apiCall } = useApi();
  const accountsInfomration = useAccountsInformation({});
  const columnHeadersList = useColumnsHeaderList({});

  const handleFileUpload = (file: File) => {
    setFile(file);
  };

  const handShowRecords = () => {
    if (!isAccountSelected) {
      enqueueSnackbar("Please select an account first!", {
        variant: "warning",
      });
      return;
    }

    if (!file) {
      enqueueSnackbar("Please upload a file first!", {
        variant: "warning",
      });
      return;
    }

    Papa.parse(file, {
      worker: true,
      complete: (completedResult) => {
        const parsedData = completedResult.data as string[][];

        // TODO: change the name parseTranscationsCSV to validateTranscationsCSV
        const validatedCSV = parseTranscationsCSV({
          dataGridAPI: dtGridRef?.current,
          data: parsedData,
          isFirstRowHeader: false,
        });

        console.log("[VALIDATED CSV]: ", validatedCSV);

        const firstRow = validatedCSV.allRows[0];
        const columns = firstRow.map((_, index) => `${index}`);

        console.log(columns);

        setColumns(columns);
        setRows(validatedCSV.allRows);
        setHeaders(validatedCSV.headers);
        setIsImporting(true);
        setInvalidRows(validatedCSV.invalidCSVRows);
      },
      header: false,
      skipEmptyLines: true,
    });
  };

  const isAccountSelected = selectedAccount !== undefined;
  const listOfHeaders = columnHeadersList.data?.ListOfColumns || [];

  const handleHeaderChange = React.useCallback(
    (newValue: string, index: string) => {
      setHeaders((prev) => {
        const newHeaders = [...prev];
        newHeaders[parseInt(index)] = newValue;
        return newHeaders;
      });
    },
    [],
  );

  const validateHeadersSelection = () => {
    const wtd1Index = headers.findIndex((header) => header === AMOUNT1_COLUMN);
    const wtd2Index = headers.findIndex((header) => header === AMOUNT2_COLUMN);
    const debitIndex = headers.findIndex((header) => header === AMOUNT3_COLUMN);
    const crdtIndex = headers.findIndex((header) => header === AMOUNT4_COLUMN);

    const isDateColSelected = isColSelected(DATE_COLUMN);
    const isAccColSelected = isColSelected(ACCOUNT_COLUMN);
    const isTaxColSelected = isColSelected(TAX_CODE_COLUMN);

    const isAmtSelected =
      wtd1Index >= 0 || wtd2Index >= 0 || debitIndex >= 0 || crdtIndex >= 0;

    if (!isAmtSelected || !isDateColSelected) {
      setValidationStatus("NO_AMOUNT_OR_DATE");
      setIsModalOpen(true);
      return false;
    }

    const isBankSearchColSelected = isColSelected(BANK_R1_COLUMN);
    const isAnyBankColSelected =
      isColSelected(BANK_R2_COLUMN) ||
      isColSelected(BANK_R3_COLUMN) ||
      isColSelected(BANK_R4_COLUMN);

    console.log(
      headers,
      "isBankSearchColSelected: ",
      isBankSearchColSelected,
      "isAnyBankColSelected: ",
      isAnyBankColSelected,
    );

    if (!isBankSearchColSelected && isAnyBankColSelected) {
      setValidationStatus("NO_BANK_SEARCH");
      setIsModalOpen(true);
      return false;
    }

    if (
      (!isAccColSelected && isTaxColSelected) ||
      (isAccColSelected && !isTaxColSelected)
    ) {
      setValidationStatus("NO_ACC_OR_TAX");
      setIsModalOpen(true);
      return false;
    }

    return true;
  };

  const validateWithPreviousImports = () => {
    if (!selectedAccount) {
      enqueueSnackbar("Please select an account first!", {
        variant: "warning",
      });

      return false;
    }

    const unfinishedImports =
      selectedAccount?.UnfinImport && selectedAccount?.UnfinImport > 0;

    if (unfinishedImports) {
      enqueueSnackbar("You have unfinished imports for this account!", {
        variant: "error",
      });
      return false;
    }

    const dateIndex = headers.findIndex((header) => header === DATE_COLUMN);

    if (dateIndex < 0) {
      enqueueSnackbar("Please select a date column first!", {
        variant: "warning",
      });
      return false;
    }

    const selectedRows = dtGridRef.current?.instance.getSelectedRowsData();

    const minDate = findMinDate(selectedRows, dateIndex);
    const lockDate = parseDateString(selectedAccount?.LockDate);
    const lastTransDate = parseDateString(selectedAccount?.LastTransDate);

    if (minDate <= lockDate) {
      enqueueSnackbar(
        `Your import has transactions starting ${minDate}. This import is aborted.`,
        {
          variant: "error",
        },
      );
      return false;
    }

    if (minDate <= lastTransDate) {
      setValidationStatus("VALIDATE_CONSISTENCY_WITH_PREVIOUS");
      setMinDate(minDate);
      setLastTransDate(lastTransDate);
      setIsModalOpen(true);
      return false;
    }

    return true;
  };

  const isColSelected = (columnName: string) => {
    console.log("[isColSelected]: ", columnName, headers.includes(columnName));
    return headers.includes(columnName);
  };

  const validationOnEachRecord = (
    selectedRows: string[][],
  ): string[][] | undefined => {
    setIsModalOpen(false);

    let totalRecordsNotValid = 0;
    let invalidRows: any[] = [];
    let validRows: any[] = [];
    let recordReports: string[] = [];

    const isAccColSelected = isColSelected(ACCOUNT_COLUMN);
    const isTaxColSelected = isColSelected(TAX_CODE_COLUMN);
    const isContactColSelected = isColSelected(CONTACT_COLUMN);
    const isSiteColSelected = isColSelected(SITE_COLUMN);

    console.log("[OVERALL]: isAccColSelected: ", isAccColSelected);
    console.log("[OVERALL]: isTaxColSelected: ", isTaxColSelected);
    console.log("[OVERALL]: isContactColSelected: ", isContactColSelected);
    console.log("[OVERALL]: isSiteColSelected: ", isSiteColSelected);

    const dateIndex = headers.findIndex((header) => header === DATE_COLUMN);
    const wtd1Index = headers.findIndex((header) => header === AMOUNT1_COLUMN);
    const wtd2Index = headers.findIndex((header) => header === AMOUNT2_COLUMN);
    const debitIndex = headers.findIndex((header) => header === AMOUNT3_COLUMN);
    const crdtIndex = headers.findIndex((header) => header === AMOUNT4_COLUMN);
    const privIndex = headers.findIndex(
      (header) => header === PRIV_PORT_COLUMN,
    );

    const isWtd1Selected = wtd1Index >= 0;
    const isWtd2Selected = wtd2Index >= 0;
    const isDebitSelected = debitIndex >= 0;
    const isCreditSelected = crdtIndex >= 0;
    const isPrivSelected = privIndex >= 0;

    if (
      !isWtd1Selected &&
      !isWtd2Selected &&
      !isDebitSelected &&
      !isCreditSelected
    ) {
      enqueueSnackbar("Please select at least one amount column!", {
        variant: "warning",
      });
      return;
    }

    for (let row of selectedRows) {
      let currRow = row;

      const date = row[dateIndex];
      const isDateValid = isDate(date);
      const isAmountValid = isAmountValidRow(
        row,
        wtd1Index,
        wtd2Index,
        debitIndex,
        crdtIndex,
      );

      const isPrivValid = isPrivSelected
        ? isPrivPortValidRow(row, privIndex)
        : true;

      if (isPrivSelected && !isPrivValid) {
        currRow[privIndex] = "0";
        recordReports.push(
          `Row ${selectedRows.indexOf(row) + 1}: Private portion out of range: ${row[privIndex]}`,
        );
      }

      console.log("[OVERALL]: isDateValid: ", isDateValid);
      console.log("[OVERALL]: isAmountValid: ", isAmountValid);
      console.log("[OVERALL]: isPrivValid: ", isPrivValid, row[privIndex]);
      console.log("[OVERALL]: recordReports: ", recordReports);

      if (!isDateValid || !isAmountValid) {
        totalRecordsNotValid++;
        invalidRows.push(currRow);
      } else {
        validRows.push(currRow);
      }
    }

    if (totalRecordsNotValid > 0) {
      alert(
        `To be turned into modal. There are ${totalRecordsNotValid} records that are not valid. Please check the following: \n\n${recordReports.join(
          "\n",
        )}`,
      );
      return;
    }
    console.log("[OVERALL]: INVALID ROWS: ", invalidRows);
    console.log("[OVERALL]: TOTAL RECORDS NOT VALID: ", totalRecordsNotValid);
    console.log("[OVERALL]: VALID ROWS: ", validRows);

    return validRows;
  };

  const handleOnImport = () => {
    const selectedRows =
      dtGridRef.current?.instance.getSelectedRowsData() || ([] as any[]);

    const areHeadersValid = validateHeadersSelection();
    if (!areHeadersValid) return;
    const arePreviousImportValid = validateWithPreviousImports();
    if (!arePreviousImportValid) return;

    const validRows = validationOnEachRecord(selectedRows);
    if (!validRows) return;

    importRowsToBankEntriesAPI(validRows, headers);
  };

  const importRowsToBankEntriesAPI = async (validRows, headers) => {
    const [cleanRows, cleanHeaders] = cleanArrays(validRows, headers);

    const csvData = cleanRows.map((row) => row.slice(1));
    const headersColumn = cleanHeaders.slice(1);

    console.log("DEBUG: FINAL INPUT: ", csvData, headersColumn);

    setIsLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-entries-import/import-csv-data-to-transactions-async `,
      method: "POST",
      // @ts-ignore
      body: {
        CSVData: JSON.stringify(csvData),
        headersColumns: JSON.stringify(headersColumn),
        accountNo: selectedAccount?.accNo,
      },
      // @ts-ignore
      onSuccess: (data) => {
        console.log("[OVERALL]: IMPORT SUCCESSFUL: ", data);
        enqueueSnackbar(
          `Import successful! Saved records: ${data?.SavedRecords} and posted records records: ${data?.PostedRecords}`,
          {
            variant: "success",
          },
        );
        setIsLoading(false);
        handleCancelImport();
      },
      // @ts-ignore
      onError: (errorMessage) => {
        enqueueSnackbar(`Import failed: ${JSON.stringify(errorMessage)}`, {
          variant: "error",
        });
        setIsLoading(false);
      },
    });

    //
  };

  const handleContinueAfterHeadersValidation = async () => {
    setIsModalOpen(false);
    setValidationStatus("VALIDATE_CONSISTENCY_WITH_PREVIOUS");

    const arePreviousImportValid = validateWithPreviousImports();

    if (!arePreviousImportValid) return;

    const selectedRows =
      dtGridRef.current?.instance.getSelectedRowsData() || ([] as any[]);

    const validRows = validationOnEachRecord(selectedRows);
    if (!validRows) return;

    await importRowsToBankEntriesAPI(validRows, headers);
  };

  const handleExcludeTransactions = async () => {
    const dateIndex = headers.findIndex((header) => header === DATE_COLUMN);
    const lockDate = parseDateString(selectedAccount?.LockDate);
    const lastTransDate = parseDateString(selectedAccount?.LastTransDate);

    const selectedRows =
      dtGridRef.current?.instance.getSelectedRowsData() || ([] as any[]);

    const excludedRows = selectedRows.filter((row) => {
      const date = parseDateString(row[dateIndex]);
      return date < lockDate || date > lastTransDate;
    });

    await importRowsToBankEntriesAPI(excludedRows, headers);
  };
  const handleImportDuplicates = async () => {
    const selectedRows =
      dtGridRef.current?.instance.getSelectedRowsData() || ([] as any[]);

    await importRowsToBankEntriesAPI(selectedRows, headers);
  };

  const handleCancelImport = () => {
    setFile(undefined);
    setSelectedAccount(undefined);
    setHeaders([]);
    setRows([]);
    setColumns([]);
    setIsImporting(false);
    setMinDate(null);
    setLockDate(null);
    setLastTransDate(null);
    setIsImporting(false);
    setValidationStatus(null);
    dtGridRef.current?.instance.clearSelection();
    dtGridRef.current?.instance.deselectAll();
    dtGridRef.current?.instance.columnOption("selection", "visible", false);
  };

  React.useEffect(() => {
    if (isImporting) {
      dtGridRef.current?.instance.selectAll();
    }
  }, [isImporting]);

  console.log("[OVERALL]: SELECTED HEADERS: ", headers);

  return (
    <>
      <BackdropLoading
        open={
          accountsInfomration.isLoading ||
          columnHeadersList.isLoading ||
          isLoading
        }
      />
      <PageTitle title={"Import Transactions from CSV"} />
      {isImporting ? (
        <ImportTransactionsFromCSVPanelImporting
          accountSelected={`${selectedAccount?.accNo}`}
          // accountSelected={`${selectedAccount?.accNo} - ${selectedAccount?.accName}`}
          numberOfInvalidRecords={invalidRows.length}
          selectedRows={0}
          totalRows={rows.length}
          onImport={handleOnImport}
          onCancelImport={handleCancelImport}
        />
      ) : (
        <ImportTransactionsFromCSVPanel
          accounts={accountsInfomration.data?.ListOfAccounts || []}
          selectedAccount={selectedAccount}
          onAccountChange={(account) => setSelectedAccount(account)}
          onFileUpload={handleFileUpload}
          onImport={() => {}}
          onShowRecords={handShowRecords}
        />
      )}
      <ImportTranscationsFromCSVValidationHeadersModal
        isModalOpen={
          isModalOpen &&
          (validationStatus === "NO_AMOUNT_OR_DATE" ||
            validationStatus === "NO_ACC_OR_TAX" ||
            validationStatus === "NO_BANK_SEARCH")
        }
        onClose={() => {
          setIsModalOpen(false);
          setValidationStatus(null);
        }}
        onContinue={handleContinueAfterHeadersValidation}
        validationStatus={validationStatus}
      />
      <ImportTranscationsFromCSVValidationConsistencyModal
        isModalOpen={
          isModalOpen &&
          validationStatus === "VALIDATE_CONSISTENCY_WITH_PREVIOUS"
        }
        onClose={() => {
          setIsModalOpen(false);
          setValidationStatus(null);
        }}
        minDate={minDate?.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}
        lastTransDate={lastTransDate?.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}
        onExcludeTransactions={handleExcludeTransactions}
        onImportDuplicates={handleImportDuplicates}
      />
      <ImportTransactionFromCSVTable
        ref={dtGridRef}
        columns={columns}
        dataSource={rows}
        handleHeaderChange={handleHeaderChange}
        headers={headers}
        listOfHeaders={listOfHeaders}
      />
    </>
  );
}
