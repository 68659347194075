import React from "react";
import { Button, SxProps, Theme } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

interface EditButtonProps {
  title?: string;
  sx?: SxProps<Theme> | undefined;
  onClick: () => void;
}

export const EditButton: React.FC<EditButtonProps> = ({
  title,
  sx,
  onClick,
}) => {

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{ fontWeight: 700, ...sx }}
      startIcon={<FilterAltIcon   />}
    >
      {title ? title : "Edit"}
    </Button>
  );
};
