import React from "react";

import { BASColumn } from "../../../../../types/api/business-activity";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { Box } from "@mui/material";
import { formatFinancialNumber } from "../../../../../utils/format-number";

const getCommonStyles = ({
  displayTopBorder = false,
  displayBottomBorder = false,
}) => ({
  height: "45px",
  borderTop: displayTopBorder ? "1px solid #e8e8e8" : "none",
  borderBottom: displayBottomBorder ? "1px solid #e8e8e8" : "none",
  padding: "12px",
});

export const basTableRenderCell = (
  cellInfo: DataGridTypes.ColumnCellTemplateData,
  currentColumn?: BASColumn,
) => {
  const rowType = cellInfo.data?.RowType;
  const hideZero = currentColumn?.HideZeroOnAmountColumnYN === "Y";
  const isRowDetail = rowType === "D";
  const isRowHeader = rowType === "H";
  const isRowFooter = rowType === "F";
  const isRowSpace = rowType === "S" || rowType === "V";

  if (isRowHeader || isRowFooter) {
    return (
      <Box
        sx={{
          ...getCommonStyles({ displayTopBorder: isRowFooter }),
          fontWeight: "bold",
        }}
      >
        {cellInfo.column.dataField === "Amount"
          ? formatFinancialNumber(cellInfo.value, {
              // defaultValue: hideZero ? "" : 0,
              defaultValue: "",
              rounding: currentColumn?.RpcRounding,
            })
          : cellInfo.value}
      </Box>
    );
  }

  if (isRowSpace) {
    return (
      <Box
        sx={{
          ...getCommonStyles({ displayTopBorder: false }),
          height: "21px", // space row height like in Profit and Loss
          "&::before": {
            borderBottom: "none",
          },
        }}
      />
    );
  }

  if (isRowDetail && cellInfo.column.dataField === "Amount") {
    return (
      <Box sx={{ ...getCommonStyles({ displayTopBorder: true }) }}>
        {formatFinancialNumber(cellInfo.value, {
          defaultValue: hideZero ? "" : 0,
          rounding: currentColumn?.RpcRounding,
        })}
      </Box>
    );
  }

  return (
    <Box sx={{ ...getCommonStyles({ displayTopBorder: true }) }}>
      {typeof cellInfo.value === "number"
        ? formatFinancialNumber(cellInfo.value, {
            defaultValue: hideZero ? " " : 0,
            rounding: currentColumn?.RpcRounding,
          })
        : cellInfo.value
          ? cellInfo.value
          : " "}
    </Box>
  );
};
