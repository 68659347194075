import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { CustomReportsParameters } from "../../../../types/api/custom-reports";
import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";
import { YNToBool } from "../../../../utils/format-bool";

export const CustomReportsUsers = ({
  parameter,
  value = [],
  handleOnChange,
}: {
  parameter: CustomReportsParameters;
  value: any[];
  handleOnChange: any;
}) => {
  const { isDarkMode } = useTheme();

  const options = parameter.SelectionOptions || [];

  return (
    <FormControl size="small" variant="outlined">
      <Autocomplete
        loading={false}
        multiple
        id={parameter.Label}
        options={options}
        getOptionLabel={(option) => `${option.Label} `}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props}>
            <Box>
              {/* <Box fontWeight={"bold"}>{option.accNo}</Box> */}
              <div>{option.Label}</div>
            </Box>
          </ListItem>
        )}
        value={value || []}
        onChange={(_, newValue) => handleOnChange(parameter.Label, newValue)}
        // isOptionEqualToValue={(option, value) => option.accNo === value.accNo}
        // isOptionEqualToValue={(option, value) => option.accNo === value}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label={parameter.Label}
            required={YNToBool(parameter.RequiredYN)}
            sx={{
              label: {
                fontSize: 12,
                // @ts-ignore
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
              minWidth: "240px",
              maxWidth: "240px",
            }}
          />
        )}
      />
    </FormControl>
  );
};
