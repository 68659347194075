export const parseDateString = (dateString) => {
  const supportedDateFormats = [
    "dd/MM/yyyy",
    "d/M/yyyy",
    "d/MM/yyyy",
    "dd/M/yyyy",
    "dd-MM-yyyy",
    "d-M-yyyy",
    "dd-MM-yy",
    "dd-MM-yyyy",
    "d-MMM-yyyy",
    "dd.MM.yyyy",
    "dd.MM.yy",
    "d.M.yy",
    "dd-MMM-yy",
    "d-MMM-yy",
    "d/MM/yy",
    "d/MM/yy",
    "yyyy-MM-ddTHH:mm:ss",
    "yyyy-MM-ddTHH:mm:ss±hh:mm", // New format with timezone offset
  ];

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const isoRegex =
    /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(Z|[+-]\d{2}:\d{2})?$/;
  const isoMatch = dateString.match(isoRegex);
  if (isoMatch) {
    const dateWithTZ = isoMatch[2] ? dateString : dateString + "Z";
    return new Date(dateWithTZ);
  }

  for (const format of supportedDateFormats) {
    let regex;
    let dateParts;

    switch (format) {
      case "dd/MM/yyyy":
      case "d/M/yyyy":
      case "d/MM/yyyy":
      case "dd/M/yyyy":
        regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
        dateParts = dateString.match(regex);
        if (dateParts) {
          return new Date(dateParts[3], dateParts[2] - 1, dateParts[1]);
        }
        break;

      case "dd-MM-yyyy":
      case "d-M-yyyy":
      case "dd-MM-yy":
        regex = /^(\d{1,2})-(\d{1,2})-(\d{2,4})$/;
        dateParts = dateString.match(regex);
        if (dateParts) {
          const year =
            dateParts[3].length === 2 ? "20" + dateParts[3] : dateParts[3];
          return new Date(year, dateParts[2] - 1, dateParts[1]);
        }
        break;

      case "d-MMM-yyyy":
      case "dd-MMM-yy":
      case "d-MMM-yy":
        regex = /^(\d{1,2})-(\w{3})-(\d{2,4})$/;
        dateParts = dateString.match(regex);
        if (dateParts) {
          const month = monthNames.indexOf(dateParts[2]);
          const year =
            dateParts[3].length === 2 ? "20" + dateParts[3] : dateParts[3];
          if (month !== -1) {
            return new Date(year, month, dateParts[1]);
          }
        }
        break;

      case "dd.MM.yyyy":
      case "dd.MM.yy":
      case "d.M.yy":
        regex = /^(\d{1,2})\.(\d{1,2})\.(\d{2,4})$/;
        dateParts = dateString.match(regex);
        if (dateParts) {
          const year =
            dateParts[3].length === 2 ? "20" + dateParts[3] : dateParts[3];
          return new Date(year, dateParts[2] - 1, dateParts[1]);
        }
        break;
    }
  }

  throw new Error("Invalid date format");
};

export const findMinDate = (
  selectedRows: string[] = [],
  index: string | number,
) => {
  let minDate = new Date();

  for (const row of selectedRows) {
    if (!row[index]) {
      continue;
    }

    const date = parseDateString(row[index]);

    if (date < minDate) {
      minDate = date;
    }
  }

  return minDate;
};
