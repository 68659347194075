import numeral from "numeral";

export const formatFinancialNumber = (
  value: number | string | null | undefined,
  options: {
    defaultValue?: string | number | undefined;
    rounding?: number;
    hideZero?: boolean; // generally you want to use defaultValue but sometimes you have to force hiding on specific cell
  } = { defaultValue: undefined, rounding: 2, hideZero: false },
): string | number | undefined => {
  if ((value === 0 || value === "0") && options.hideZero) {
    return "";
  }

  if (!value && value !== 0) {
    return options.defaultValue;
  }

  if (typeof value === "number") {
    if (value < 0) {
      return `(${numeral(Math.abs(value)).format("0,0." + "0".repeat(options.rounding || 2))})`;
    } else {
      return numeral(value).format("0,0." + "0".repeat(options.rounding || 2));
    }
  }

  return value.toString();
};
