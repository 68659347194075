import { useContext } from "react";
import ReportDatesContext from "../context/ReportDatesContext";

interface ReportDates {
  startDate: Date | string | undefined;
  endDate: Date | string | undefined;
}

export const useReportsDates = (): ReportDates => {
  const { reportDates } = useContext(ReportDatesContext);

  const startDate = reportDates?.reportsStartDate
    ? new Date(reportDates?.reportsStartDate + "Z").toISOString().split("T")[0]
    : undefined;

  const endDate = reportDates?.reportsEndDate
    ? new Date(reportDates?.reportsEndDate + "Z").toISOString().split("T")[0]
    : undefined;

  return { startDate, endDate };
};
