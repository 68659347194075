import React, { createContext, useState, useContext } from "react";
import LoginPopup from "../components/LoginPopup/LoginPopup";
import { getCookie } from "../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import envConfig from "../config";

const PopupContext = createContext();

export function PopupProvider({ children }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const { dbId } = useParams();

  const handleOpen = (isForced) => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    if (isCooldown) return;

    const storedTime = getCookie("apiKeyExpirationUTCDate");
    if (!storedTime || `${storedTime}` === "null") {
      console.log("api key expiration date not present");
      window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}`;
      return;
    }

    if (
      getCookie("apiKey") &&
      getCookie("apiKey") !== "undefined" &&
      dbId &&
      getCookie("databaseId")
    ) {
      setOpen(true);
      return true;
    } else if (isForced) {
      setOpen(true);
      return false;
    } else {
      window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}`;
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsCooldown(true);

    // Set a timeout to end the cooldown period after 5 seconds
    setTimeout(() => {
      setIsCooldown(false);
    }, 5000);
  };

  return (
    <PopupContext.Provider value={{ open, handleOpen, handleClose }}>
      {children}
      <LoginPopup open={open} handleClose={handleClose} />
    </PopupContext.Provider>
  );
}

export function useLoginPopup() {
  return useContext(PopupContext);
}
