import envConfig from "../../../config";
import { getHeaders } from "../../../utils/api";
import { EXAMPLE_GET_ACCOUNTS_RESPONSE } from "../../../data/example-get-accounts-response";
import { AccountResponse } from "../../../types/api/accounts";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { useParams } from "react-router-dom";

export const GET_ACCOUNTS_KEY = "GET_ACCOUNTS_KEY";

export const getAccounts = async (handleOpen, dbId): Promise<AccountResponse> => {
  // TODO: IMPORTANT: Remove this line in final version
  if (process.env.NODE_ENV === "development") {
    // @ts-ignore
    return EXAMPLE_GET_ACCOUNTS_RESPONSE;
  }

  const headers = getHeaders(dbId);
  const api = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/accounts/get-selection-list?activeYN=Y&postableYN=N&BaseHostURL=${envConfig.mainServiceUrl}`;

  const response = await fetch(api, {
    method: "GET",
    // @ts-ignore
    headers: {
      // @ts-ignore
      "Content-Type": "application/json",
      ...headers,
    },
  });

  if (!response.ok) {
    // Throw an error to be caught by React Query error handling
    throw new Error(`Failed to get Accounts", ${JSON.stringify(response)}`);
  }

  const result = await response.json();

  if (result?.SuccessYN === "Y" || result?.successYN === "Y") {
    return result;
  } else {
    throw new Error(`Failed to return Accounts", ${JSON.stringify(result)}`);
  }
};

export const useGetAccounts = () => {
  const {handleOpen} = useLoginPopup();
  const { dbId } = useParams();

  return useQuery({
    queryKey: [GET_ACCOUNTS_KEY],
    queryFn: () => getAccounts(handleOpen, dbId),
  });
};
