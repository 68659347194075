import { useQuery } from "@tanstack/react-query";
import envConfig from "../../../../config";
import { getHeaders } from "../../../../utils/api";
import { checkAndUpdateApiKeyExpiration } from "../../../../hooks/useApi";
import { EXAMPLE_GET_LIST_OF_TAX_CODES_RESPONSE } from "../../../../data/example-get-list-of-tax-codes";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { useParams } from "react-router-dom";

const LIST_OF_TAX_CODES_QUERY_KEY = "tax-codes";

export const getListOfTaxCodes = async ({handleOpen, dbId}) => {
  if (process.env.NODE_ENV === "development") {
    return EXAMPLE_GET_LIST_OF_TAX_CODES_RESPONSE;
  }


  const headers = getHeaders(dbId);
  const api = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/tax-codes/get-list-of-tax-codes?BaseHostURL=${envConfig.mainServiceUrl}`;

  const response = await fetch(api, {
    method: "GET",
    // @ts-ignore
    headers: {
      ...headers,
    },
  });

  console.log("DEBUGGING: getListOfTaxCodes -> response", response);

  if (response.status === 403 || response.status === 401) {
    setTimeout(async () => {
      handleOpen();
      // window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}` as unknown as Location;
    }, 1000);
    throw new Error("Your session has expired, please log back in.");
  }

  if (api.includes(envConfig.apiDev1) || api.includes(envConfig.apiDev2)) {
    checkAndUpdateApiKeyExpiration();
  }

  const result = await response.json();

  if (response.ok && (result?.SuccessYN === "Y" || result?.successYN === "Y")) {
    return result;
  } else {
    console.log(
      "Error: ",
      result?.ErrorMessage || result?.errorMessage,
      ". Response: ",
      result,
    );
    throw new Error(
      `An error occurred: ${result?.ErrorMessage || result?.errorMessage || "Unknown error"}`,
    );
  }
};

export const useListOfTaxCodes = ({handleOpen}) => {
  const { dbId } = useParams();

  return useQuery({
    queryKey: [LIST_OF_TAX_CODES_QUERY_KEY],
    queryFn: () => getListOfTaxCodes({handleOpen, dbId}),
  });
};
