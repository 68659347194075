import React, { useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import envConfig from "../../config";

const Callback = () => {
  const { dbId, lang } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const prefix = dbId && lang ? `/${dbId}/${lang}/` : "/";

  useEffect(() => {
    const hash = window.location.hash.substring(1);

    const urlParams = new URLSearchParams(hash);

    const code = urlParams.get("code");
    const client_info = urlParams.get("client_info");
    const state = urlParams.get("state");
    const session_state = urlParams.get("session_state");

    if (code && client_info && state && session_state) {
      Cookies.set("MScode", code, { expires: 7 });
      Cookies.set("MSclient_info", client_info, { expires: 7 });
      Cookies.set("MSstate", state, { expires: 7 });
      Cookies.set("MSsession_state", session_state, { expires: 7 });

      console.log("code", code);
      console.log("client_info", client_info);
      console.log("state", state);
      console.log("session_state", session_state);

      // Make the API call
      axios
        .post(
          `https://dev3c.olivs.com/api/en-au/authenticate/login?BaseHostURL=${envConfig.mainServiceUrl}`,
          {
            Code: code,
            RedirectURI: `${window.location.origin}/callback-ms365`,
          },
          {
            headers: {
              accept: "*",
              "olivs-api-real-ip": "127.0.0.1",
              "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
              "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          console.log("API Response:", response.data);

          // navigate(prefix + "dashboard");
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    } else {
      navigate(-1);
    }
  }, [location, navigate, prefix]);

  return <Box></Box>;
};

export default Callback;
