import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useTheme } from "../../../../context/ThemeContext";
import {
  darkTheme,
  lightTheme,
  commonThemes,
} from "../../../../utils/themes/themes";
import { OlivsDataPicker } from "../../../../components/ui/date-picker";
import { GeneralLedgerAccounts } from "./general-ledger-accounts";
import { GeneralLedgerSites } from "./general-ledger-sites";
import { GeneralLedgerContacts } from "./general-ledger-contacts";
import { GeneralLedgerReconStatus } from "./general-ledger-recon-status";
import { GeneralLedgerRecordStatus } from "./general-ledger-record-status";
import { GeneralLedgerShowFilters } from "./general-ledger-show-filters";
import {
  DatePredefinedPeriodPicker,
  PeriodType,
} from "../../../../components/ui/date-predefined-period-picker";
import { getPredefinedDates } from "../../../../utils/format-date";
import { useLedgedStore } from "../store";
import { Account } from "../../../../types/api/accounts";

interface GeneralLedgerFilterModalProps {
  // accounts: Account[];
  // accountsIsLoading: boolean;
  isModalOpen: boolean;
  onClose: () => void;
  onApplyFilters: () => Promise<void>;
}

// TODO: Change to GeneralLedgerFiltersModal
export const GeneralLedgerFilterModal = ({
  isModalOpen,
  // accounts,
  // accountsIsLoading,
  onClose,
  onApplyFilters,
}: GeneralLedgerFilterModalProps) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const isMobile = useMediaQuery("(max-width: 580px)");
  const breakDropdowns = useMediaQuery("(max-width: 880px)");
  const isScreenBelow1050px = useMediaQuery("(max-width: 1150px)");

  const startDate = useLedgedStore.use.startDate();
  const endDate = useLedgedStore.use.endDate();
  const periodType = useLedgedStore.use.periodType();
  const setStartDate = useLedgedStore.use.setStartDate();
  const setEndDate = useLedgedStore.use.setEndDate();
  const setPeriodType = useLedgedStore.use.setPeriodType();

  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
    setPeriodType("custom");
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate);
    setPeriodType("custom");
  };

  const handlePredefinedPeriodChange = (periodType: PeriodType) => {
    setPeriodType(periodType);
    const predefinedDates = getPredefinedDates(periodType);
    if (predefinedDates) {
      setStartDate(predefinedDates[0]);
      setEndDate(predefinedDates[1]);
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      sx={{
        ">div>div": { maxWidth: "xl", padding: isMobile ? 0 : 2 },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: theme.typography.fontFamily,
          // @ts-ignore TODO: remove it later
          fontSize: isMobile ? 18 : theme.h1.fontSize,
          // @ts-ignore TODO: remove it later
          fontWeight: theme.typography.fontWeightThin,
        }}
      >
        General Ledger Report Filter
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "19px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: breakDropdowns ? "column" : "row",
            }}
          >
            <DatePredefinedPeriodPicker
              value={periodType}
              onChange={handlePredefinedPeriodChange}
              style={{
                marginTop: "15px",
                width: 200,
              }}
            />

            {breakDropdowns && !isMobile ? (
              <Box
                sx={{
                  display: "flex",
                  justifyItems: "cemter",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    // @ts-ignore
                    ...commonThemes.normalText,
                    marginTop: isScreenBelow1050px ? "15px" : "20px",
                    fontSize: theme.typography.fontSize,
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                >
                  OR
                </span>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <OlivsDataPicker
                    label="Start Date"
                    maxDate={endDate}
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <OlivsDataPicker
                    label="End Date"
                    minDate={startDate}
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </LocalizationProvider>
              </Box>
            ) : (
              <span
                style={{
                  // @ts-ignore
                  ...commonThemes.normalText,
                  marginRight: "14px",
                  marginLeft: "14px",
                  marginTop: isScreenBelow1050px ? "15px" : "20px",
                  fontSize: theme.typography.fontSize,
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                OR
              </span>
            )}
            {/*  */}
            {(!breakDropdowns || isMobile) && (
              <FormControl
                size="small"
                component="fieldset"
                sx={{
                  width: "100%",
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <OlivsDataPicker
                    label="Start Date"
                    maxDate={endDate}
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <OlivsDataPicker
                    label="End Date"
                    minDate={startDate}
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </LocalizationProvider>
              </FormControl>
            )}
          </Box>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 20,
              marginBottom: "15px",
            }}
          >
            <GeneralLedgerAccounts
            // accounts={accounts}
            // accountsIsLoading={accountsIsLoading}
            />
            <GeneralLedgerSites />
            <GeneralLedgerContacts />
          </div>
          {/* <div>
            <FormLabel
              sx={{
                // @ts-ignore
                ...theme.blueLabel,
              }}
            >
              Reconciliation Status
            </FormLabel>
            <GeneralLedgerReconStatus />
          </div> */}
          <div>
            <FormLabel
              sx={{
                // @ts-ignore
                ...theme.blueLabel,
              }}
            >
              Record Status
            </FormLabel>
            <GeneralLedgerRecordStatus />
            <GeneralLedgerShowFilters />
          </div>

          <DialogActions sx={{ pt: 0, mt: 5 }}>
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "rgba(132, 159, 35, 0.04)",
                },
              }}
              onClick={onClose}
              // @ts-ignore
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: theme.palette.primary.main,
                },
              }}
              // disabled={loading}
              onClick={async () => {
                await onApplyFilters();
                onClose();
              }}
              // @ts-ignore
              variant="primary"
            >
              Show General Ledger
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
};
