import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ReactComponent as SunIcon } from "../../assets/sun-icon.svg";
import { ReactComponent as MoonIcon } from "../../assets/moon-icon.svg";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import themes from "devextreme/ui/themes";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 40,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#EDEDED",
        borderRadius: "20px",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: "20px",
  },
}));

const ThemeToggle = ({ titlefontSize, title = "Dark Mode" }) => {
  const { isDarkMode, toggleDarkMode } = useTheme();

  useEffect(() => {
    const themeName = isDarkMode
      ? "material.custom-scheme-dark"
      : "material.custom-scheme-light";
    try {
      themes.current(themeName);
      themes.ready(() => {
        console.log("Switched to theme:", themeName);
      });
    } catch (error) {
      console.error("Error switching theme:", themeName, error);
    }
  }, [isDarkMode]);

  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <FormControlLabel
      sx={{
        marginTop: "-12px",
        marginBottom: "-13px",
        "&:hover": {
          span: {
            color: isDarkMode ? "#AAD269" : "#849F23",
          },
        },
        fontWeight: 500,
        span: {
          fontWeight: 500,
          fontFamily: "Inter",
          fontSize: titlefontSize || "14px",
          letterSpacing: "0.15px",
          color: theme.typography.linkColor,
        },
      }}
      label={title}
      control={
        <MaterialUISwitch
          checked={!isDarkMode}
          onChange={toggleDarkMode}
          sx={{ m: 1 }}
          icon={
            <MoonIcon
              style={{
                width: "16px",
                height: "16px",
                background: "#FFF",
                borderRadius: "50%",
                marginTop: "11px",
                marginLeft: "7px",
              }}
            />
          }
          checkedIcon={
            <SunIcon
              style={{
                width: "16px",
                height: "16px",
                background: "#FFF",
                borderRadius: "50%",
                marginTop: "11px",
                marginLeft: "11px",
              }}
            />
          }
        />
      }
    />
  );
};

export default ThemeToggle;
